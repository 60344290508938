import React, { useEffect, useState } from "react";
// @ts-ignore
import Calendar from 'react-calendar';
import "./index.less"
import {
    Button,
    Card,
    Divider,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination"
import { getHistoryList, submitDuration, submitExpense, submitInvoice } from "./api";
import { useStartStore } from "../../store";
import { observer } from "mobx-react"
import { ESbmTimeSheetStatus, ETaskType, Role } from "../../enum/userType";
import { getProject } from "../setup/editProject/api";
import TimeTable from "./TimeTableFullInput";
import IProps from "../../interface/iprops";
import ExpenseTable from "./ExpenseTablFullInput";
import { getExpense } from "../setup/editExpense/api";
import Invoice from "./Invoice";
import { getMoment } from '../../utils/tool';

const Submission = ({history}: IProps) => {

    const role = JSON.parse(String(localStorage.getItem('userInfo')));

    const {success, error, warning, showLoad, hideLoad} = useStartStore();

    const [value, setValue] = useState(new Date());
    const [times, setTimes] = useState([]);
    const [expense, setExpense]: any = useState({});
    const [invoice, setInvoice] = useState(null);
    const [timeDay, setTimeDay]: any = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [historys, setHistorys] = useState([]);
    const [valueTable, setValueTable] = useState(ETaskType.TIME_SHEET);
    const [projects, setProjects] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditExpense, setIsEditExpense] = useState(false);
    const [isEditInvoice, setIsEditInvoice] = useState(false);
    const [submitId, setSubmitId] = useState<number | any>(null);
    const [expenseId, setExpenseId] = useState<number | any>(null);
    const [invoiceId, setInvoiceId] = useState<number | any>(null);
    const [total, setTotal] = useState<number | any>(0);
    const [editClose, setEditClose] = useState<number>(0);
    const [editExpensClosee, setEditExpensClosee] = useState<number>(0);
    const [editInvoiceClose, setEditInvoiceClose] = useState<number>(0);

    useEffect(() => {
        getFiveDays(new Date());
        loadData();


        // @ts-ignore

        switch (role.role) {
            case Role.SUPER_ADMIN:
                history.goBack()
                break;
            case Role.AIC:
                history.goBack()
                break;
        }
    }, [])

    const loadData = () => {
        getTeam();
        historyList();
        getExpenselist();
    }

    const getExpenselist = async () => {
        let result: any = await getExpense();
        if (result.code === 0) {
            setExpenses(result.data)
        } else {

        }
    }

    const historyList = async (type?: string | number, index?: any) => {
        showLoad();
        let data = {
            user_id: role.id,
            team_id: '1',
            page_index: index ? index : String(pageIndex),
            type: type ? String(type) : '1'
        };
        let result: any = await getHistoryList(data);

        if (result.code === 0) {
            setHistorys(result.data.data);
            setTotal(Math.ceil(result.data.total / 10));
            // setHistoryTotal(result.data.total / 10);
        } else {
            error(result.message);
        }

        hideLoad();
    }

    const getTeam = async () => {
        let result: any = await getProject();

        if (result.code === 0) {
            setProjects(result.data);
        } else {
            error(result.message);
        }

    }

    const setPage = (e: any, page: any) => {
        setPageIndex(page);
        historyList(valueTable, page);
    }

    const getNextDate = (date: string | number | Date, day: number) => {//data基准时间的时间戳，day:第几天
        var dd = new Date(date);
        dd.setDate(dd.getDate() + day);
        var y = dd.getFullYear();
        var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
        var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
        return y + "-" + m + "-" + d;
    }

    const getFiveDays = (e: string | number | Date) => {
        let days: string[] = [];
        var startDay = getMoment(e).startOf('isoWeek');//计算指定日期是这周第几天

        days[0] = getMoment(startDay).format('YYYY-MM-DD')

        //截取前后5天的时间
        new Array(6).fill('').map((item, index) => {
            days[index + 1] = getMoment(startDay).add(index + 1, 'day').format('YYYY-MM-DD');
        })

        switch (valueTable) {
            case 1:
                setTimeDay(days);
                break;
            case 2:
                setTimeDay(days);
                break;
        }

        //设置列表的时间段
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setHistorys([]);
        // setSubmitId('');
        setValueTable(newValue);
        historyList(newValue, 1);
        setPageIndex(1);
    };

    const sumbitTime = async (e: Array<any> | any, clearCb?: Function) => {
        showLoad();
        let data = null;

        if (isEdit) {
            data = {
                // @ts-ignore
                id: submitId,
                isSaveHistory: 0,
                record: e
            }
        } else {
            data = {
                isSaveHistory: 0,
                record: e
            }
        }

        let result: any = await submitDuration(data);

        if (result.code === 0) {
            success(result.message);
            setIsEdit(false);
            historyList();
            setTimes([]);
            clearCb && clearCb();
        } else {
            error(result.message);
        }

        hideLoad();
    }

    const sumbitExoense = async (e: Array<any> | any, imgs: Array<any>, clearCb?: Function) => {
        showLoad();
        let data = null;


        if (isEditExpense) {
            data = {
                // @ts-ignore
                id: expenseId,
                isSaveHistory: 0,
                record: e,
                attach_invoice_files: imgs
            }
        } else {
            data = {
                isSaveHistory: 0,
                record: e,
                attach_invoice_files: imgs
            }

        }

        let result: any = await submitExpense(data);

        if (result.code === 0) {
            success(result.message);
            setIsEditExpense(false);
            setInvoice(null);
            historyList(valueTable);
            setTimes([])
            clearCb && clearCb()
        } else {
            error(result.message);
        }

        hideLoad();
    }

    const sumbitInvoice = async (e: any, cb?: Function) => {
        showLoad();
        let data = {...e};
        data.userId = role.id;
        // @ts-ignore
        data.id = isEditInvoice ? invoice.id : null;

        // data.attach_your_detail_files = [{name: 'aaaa.png', path: 'xxxx'}, {name: 'aaaa22.png', path: 'xxxx'}];

        const result: any = await submitInvoice(data);
        if (result.code === 0) {
            historyList(valueTable);
            cb && cb();
            success(result.message);
        } else {
            error(result.message)
        }
        hideLoad();
    }

    const editTime = (data: any, type?: string) => {
        let date = '';

        if (valueTable === ETaskType.INVOICE) {
            date = data.month;
        } else {
            date = data.record[0].data[0].date;
        }
        setIsEdit(false);
        setIsEditExpense(false);
        setIsEditInvoice(false);
        switch (valueTable) {
            case 1:
                setSubmitId(data.id);
                setTimes(data);
                setIsEdit(true);
                getFiveDays(date);
                setValue(new Date(date));
                switch (type) {
                    case 'refected':
                        setEditClose(1)
                        break;
                    case "approved":
                        setEditClose(2)
                        break;
                    default:
                        setEditClose(0)
                        break;
                }
                break;
            case 2:
                setExpenseId(data.id);
                setExpense(data);
                setIsEditExpense(true);
                getFiveDays(date);
                switch (type) {
                    case 'refected':
                        setEditExpensClosee(1)
                        break;
                    case "approved":
                        setEditExpensClosee(2)
                        break;
                    default:
                        setEditExpensClosee(0)
                        break;
                }
                break;
            case 3:
                setInvoiceId(data.id);
                setInvoice(data);
                setIsEditInvoice(true);
                switch (type) {
                    case 'refected':
                        setEditInvoiceClose(1)
                        break;
                    case "approved":
                        setEditInvoiceClose(2)
                        break;
                    default:
                        setEditInvoiceClose(0)
                        break;
                }
                break;
        }


    }

    const clearTime = () => {
        setTimes([]);
        setIsEdit(false);
        setSubmitId('')
        setEditClose(0)
    }

    const clearExpense = () => {
        setExpense([]);
        setEditExpensClosee(0)
        setIsEditExpense(false);
        setExpenseId('')
    }

    const cancelEdit = () => {
        setIsEditInvoice(false);
        setInvoiceId('');
        setSubmitId('');
        setTimes([]);
        setIsEditExpense(false);
        setIsEditInvoice(false);
        setIsEdit(false);
        setExpenseId('');
        setExpense([]);
    }


    return (
        <div className='overrider'>
            <div className='overrider-title'>{Role[role.role]}</div>
            <Divider/>
            <div className='overrider-view'>
                <div className='left'>
                    <Card className='calendar'>
                        <div className='left-title'>Select Time</div>
                        <Divider/>
                        <Calendar
                            locale={'en'}
                            onChange={(e: any) => {
                                if (isEdit || isEditExpense) {
                                    cancelEdit();
                                    warning('Currently in editing state, has been automatically cancelled');
                                }
                                getFiveDays(e);
                                setValue(e)
                            }}
                            value={value}
                        />
                    </Card>

                    <Card className='left-table'>
                        <div className='left-title'>Submission</div>
                        <Tabs
                            value={valueTable}
                            indicatorColor="primary"
                            textColor="primary"
                            style={{background: '#FFF'}}
                            onChange={handleChange}
                            aria-label="disabled tabs example"
                        >
                            {role.role === Role.TEAM_LEADER && (
                                <Tab key={ETaskType.TIME_SHEET} value={ETaskType.TIME_SHEET} label="Time sheet"/>)}
                            {role.role === Role.STAFF && (
                                <Tab key={ETaskType.TIME_SHEET} value={ETaskType.TIME_SHEET} label="Time sheet"/>)}
                            {role.role === Role.CONTRACTOR && (
                                <Tab key={ETaskType.TIME_SHEET} value={ETaskType.TIME_SHEET} label="Time sheet"/>)}
                            {role.role === Role.TEAM_LEADER && (
                                <Tab key={ETaskType.EXPENSE} value={ETaskType.EXPENSE} label="Expense"/>)}
                            {role.role === Role.STAFF && (
                                <Tab key={ETaskType.EXPENSE} value={ETaskType.EXPENSE} label="Expense"/>)}
                            {role.role === Role.CONTRACTOR && (
                                <Tab key={ETaskType.INVOICE} value={ETaskType.INVOICE} label="Invoice"/>)}

                        </Tabs>
                        {/*<Divider/>*/}
                        <TableContainer component={Paper}>
                            <Table size="medium" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{opacity: 0.5}}>Start Week
                                            / <span style={{color: '#029589'}}>Name</span></TableCell>
                                        <TableCell style={{opacity: 0.5, paddingRight: '25px'}}
                                                   align="right">Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {historys.map((row: any, index) => {
                                        let mmt, currentId;

                                        switch (valueTable) {
                                            case ETaskType.INVOICE:
                                                mmt = getMoment(row.month).format('MMMM YYYY')
                                                currentId = invoiceId;
                                                break;
                                            case ETaskType.TIME_SHEET:
                                                mmt = getMoment(row.date || row.record[0].date || row.record[0].data[0].date).startOf('isoWeek').format('MMMM DD, YYYY');
                                                currentId = submitId;
                                                break;
                                            case ETaskType.EXPENSE:
                                                mmt = getMoment(row.date || row.record[0].date || row.record[0].data[0].date).startOf('isoWeek').format('MM/DD/YYYY');
                                                currentId = expenseId;
                                                break;
                                        }

                                        return (
                                            <TableRow key={index} selected={row.id === currentId}>
                                                <TableCell align={"left"}>
                                                <span
                                                    style={{opacity: 0.6}}>{mmt}</span>
                                                    <span style={{opacity: 0.5}}>/</span> <span
                                                    style={{color: '#029589'}}>{row.user_name}</span>
                                                </TableCell>

                                                <TableCell align="right">
                                                    {row.status === 3 ? //Subitted
                                                        <Button onClick={() => editTime(row)}
                                                                color="primary">{ESbmTimeSheetStatus[row.status]}</Button> :
                                                        row.status === 4 ? // REJECTED  // Approved
                                                            <Button style={{color: '#ec4b57'}}
                                                                    onClick={() => editTime(row, 'refected')}>{ESbmTimeSheetStatus[row.status]}</Button> :
                                                            <Button onClick={() => editTime(row, 'approved')}
                                                                    style={{color: '#1ba95b'}}>{ESbmTimeSheetStatus[row.status]}</Button>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className='left-page'>
                            <Pagination page={pageIndex} onChange={setPage} count={total} size="small"/>
                        </div>
                    </Card>
                </div>

                <div className='right'>

                    {role.role === Role.TEAM_LEADER && <TimeTable date={timeDay}
                                                                  projects={projects}
                                                                  data={times}
                                                                  editClose={editClose}
                                                                  isAdd={isEdit}
                                                                  onSumbit={sumbitTime}
                                                                  clearTime={clearTime}/>}
                    {role.role === Role.STAFF && <TimeTable date={timeDay}
                                                            projects={projects}
                                                            data={times}
                                                            editClose={editClose}
                                                            isAdd={isEdit}
                                                            onSumbit={sumbitTime}
                                                            clearTime={clearTime}/>}
                    {role.role === Role.CONTRACTOR && <TimeTable date={timeDay}
                                                                 projects={projects}
                                                                 data={times}
                                                                 editClose={editClose}
                                                                 isAdd={isEdit}
                                                                 onSumbit={sumbitTime}
                                                                 clearTime={clearTime}/>}

                    {/*setExpenseId(data.id);*/}
                    {/*setExpenses(data.record);*/}
                    {/*setIsEditExpense(true);*/}

                    {role.role === Role.TEAM_LEADER && <ExpenseTable date={timeDay}
                                                                     projects={projects}
                                                                     data={expense.record}
                                                                     isAdd={isEditExpense}
                                                                     expenses={expenses || []}
                                                                     message={expense.approve_message}
                                                                     editClose={editExpensClosee}
                                                                     onSumbit={sumbitExoense}
                                                                     clearExpense={clearExpense}/>}
                    {role.role === Role.STAFF && <ExpenseTable date={timeDay}
                                                               projects={projects}
                                                               data={expense.record || []}
                                                               message={expense.approve_message}
                                                               isAdd={isEditExpense}
                                                               editClose={editExpensClosee}
                                                               expenses={expenses}
                                                               onSumbit={sumbitExoense}
                                                               clearExpense={clearExpense}/>}

                    {role.role === Role.CONTRACTOR &&
                    <Invoice isEdit={isEditInvoice}
                             data={invoice}
                             projects={projects}
                             editClose={editInvoiceClose}
                             cancelEdit={cancelEdit}
                             onSubmit={sumbitInvoice}/>}
                </div>
            </div>
        </div>
    )
}

export default observer(Submission)
