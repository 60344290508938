import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routers from './router/index';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "./utils/thmem"
import {Provider} from "mobx-react";
import {stores,StoresContext} from "./store/index"

ReactDOM.render(
    <Provider {...stores}>
        <StoresContext.Provider value={stores}>
           <ThemeProvider theme={theme}>
                <Routers />
           </ThemeProvider>
        </StoresContext.Provider>
    </Provider>
  ,
  document.getElementById('root')
);
