import Http from "../../utils/axios"

interface IProps {
    gmt?:string;
    tokenkey?:string;
    account?:string;
    token?:string;
    password?:string;
    retype_password?:string;
}

export const getToken = (data: IProps)=>{
    return Http.post('/api/v1/account/reset/passwrod/pre/verify',data)
}

export const resetPassword = (parameter:IProps)=>{

    let data = {
        token:parameter.token,
        account:parameter.account,
        password:parameter.password,
        retype_password:parameter.password
    }

    return Http.post('/api/v1/account/reset/passwrod',data);
}
