import React, { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { activtionEmail, getUserInfo } from "./api";
import IProps from "../../interface/iprops";
import "./index.less"
import { observer } from "mobx-react";
import { useStartStore } from "../../store";

const Activtion = ({history}: IProps) => {

    const {success, error, warning, showLoad, hideLoad} = useStartStore();

    useEffect(() => {
        getUrl();
    })

    const getUrl = () => {
        let str = window.location.href;
        if (str.indexOf('?') >= 0) {
            let arr = str.split("?")[1].split("&");
            let obj = {};
            for (let i of arr) {
                // @ts-ignore
                obj[i.split("=")[0]] = i.split("=")[1];
            }
            inquireUser(obj);
        } else {
            console.log('error url');
            history.push('/');
        }
    }

    const inquireUser = async (e: any) => {
        const data: any = await activtionEmail(e);

        if (data.code === 0) {
            let result: any = await getUserInfo();

            if (result.code === 0) {
                localStorage.setItem('userInfo', JSON.stringify(result.data));
                history.push('/home/overview')
            } else {
                error(result.message);
                history.push('/');
            }
        } else {
            error(data.message);
            history.push('/');
        }
    }


    return (
        <div className='activtion-view'>
            <CircularProgress/>
        </div>
    )
}

export default observer(Activtion);
