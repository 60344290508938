import React, { useEffect, useState } from "react";
import {
    Card,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Button
} from "@material-ui/core";
import "./index.less"

const ProjectReport = ({list, downLoad}: any) => {

    const [total, setTotal]: any = useState(0)

    useEffect(() => {
        let sum = 0;
        list.forEach((item: any) => {
            sum += item.total_Labor || 0
            sum += item.total_expenses || 0
            sum += item.total_invoice || 0
        })
        if (isNaN(sum)) {
            setTotal('Sorry, one of your employees has not set the hour rate');
        } else {
            setTotal(sum);
        }
    }, [list])

    return (
        <Card className='employee-expense-report-view' style={{minWidth:830}}>
            <div className='title'>Report by Project</div>
            <div className='worked'>
                <div>Total Expense By Project :</div>
                <div className='worked-box'>{typeof total === 'string' ?
                    <span style={{color: 'red'}}>{total}</span> : total}</div>
            </div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Project</TableCell>
                            {/*<TableCell align="left">Days Since Started</TableCell>*/}
                            <TableCell align="left">Total Hours</TableCell>
                            <TableCell align="left">Total Labor</TableCell>
                            <TableCell align="left">Total Expenses</TableCell>
                            <TableCell align="left">Total Invoice</TableCell>
                            <TableCell align="left">Total Budget</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            list.map((item: any, index: string | number | undefined) => (
                                <TableRow key={index}>
                                    <TableCell align='left'>{item.project_name}</TableCell>
                                    <TableCell align="left">{item.total_hours}</TableCell>
                                    <TableCell align="left">{item.total_Labor}</TableCell>
                                    <TableCell align="left">{item.total_expenses}</TableCell>
                                    <TableCell align="left">{item.total_invoice}</TableCell>
                                    <TableCell align="left">{item.total_budget}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                list.length >= 1 &&
                <Button onClick={downLoad} variant="contained" color="primary" className='submit'>Export to
                    Excel</Button>
            }
        </Card>
    )
}

export default ProjectReport
