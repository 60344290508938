import React from 'react'
import {Slide, Snackbar} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {useStartStore} from "../../store";
import {observer} from "mobx-react"

const Message = ()=>{
    const {messages,open,type,close} = useStartStore();
    return (
        <Snackbar
            anchorOrigin={{ vertical:'top',horizontal:'center' }}
            open={open}
            autoHideDuration={5000}
            onClose={close}
            TransitionComponent={(props)=><Slide {...props} direction="down" />}
            key={'topCenter'}
        >
            <MuiAlert onClose={close} elevation={4} variant="filled" severity={type}>
                {messages}
            </MuiAlert>
        </Snackbar>
    );
}


export default observer(Message);
