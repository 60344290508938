import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    Button,
    Card,
    TextField,
    TableContainer,
    TableHead,
    TableRow,
    Divider
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { getProject } from "../setup/editProject/api";
import { getMoment } from '../../utils/tool';
import moment from 'moment'

function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
    return {name, calories, fat, carbs, protein};
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const TimeApprovalTable = ({date, data, msg, onSubmit, isEdit}: any) => {

    const [projects, setProjects] = useState([]);
    const [sum, setSum] = useState([0, 0, 0, 0, 0, 0, 0])
    const [message, setMessage] = useState('');
    const [datas, setDatas] = useState([])

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        deconstructionData();
        setMessage(msg || '')
    }, [data])

    const loadData = async () => {
        let result: any = await getProject();

        if (result.code === 0) {
            setProjects(result.data);
        } else {
            //TODO 错误提示
        }
    }

    const deconstructionData = () => {
        let list: any = [];
        const d = Array.isArray(data) ? data : data.record;

        d.forEach((item: any, index: number) => {
            list.push({
                project_name: item.project_name,
                desc: item.desc
            })
            item.data.forEach((_d: any, _i: any) => {
                for (let i = 0; i < date.length; i++) {
                    if (_d.date === date[i]) {
                        list[index][i] = _d.duration;
                        break
                    }
                }
            })

            calculate(list);
            setDatas(list);
        })
    }

    const calculate = (d: any) => {
        let arr = [0, 0, 0, 0, 0, 0, 0];
        d.forEach((item: any, index: number) => {
            new Array(7).fill('').forEach((_d: any, _i: any) => {
                arr[_i] += item[_i]
            })
        })

        setSum(arr);
    }

    const submit = (type: boolean) => {
        onSubmit(type, message)
    }

    return (
        <Card className='approval-view-box'>
            <div className='table-title'>Time Sheet Approval
                <span style={{paddingLeft: 20, color: '#d315b3'}}>[ {getMoment(date[0]).format('MMMM YYYY')} ]</span>
            </div>
            <Divider/>
            <TableContainer style={{width: '100%'}} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead style={{width: '100%'}}>
                        <TableRow>
                            <TableCell align="left">input Your Project</TableCell>
                            <TableCell align="left">Desc</TableCell>
                            {
                                date.map((item: string, index: string | number | undefined) => {
                                    return <TableCell key={index} align="left">{moment(item).format('MM/DD')}</TableCell>
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datas.map((row: any, index: number) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell align="left">{row.project_name}</TableCell>
                                    <TableCell align="left">{row.desc}</TableCell>
                                    <TableCell align="left">{row['0'] || ''}</TableCell>
                                    <TableCell align="left">{row['1'] || ''}</TableCell>
                                    <TableCell align="left">{row['2'] || ''}</TableCell>
                                    <TableCell align="left">{row['3'] || ''}</TableCell>
                                    <TableCell align="left">{row['4'] || ''}</TableCell>
                                    <TableCell align="left">{row['5'] || ''}</TableCell>
                                    <TableCell align="left">{row['6'] || ''}</TableCell>
                                    <TableCell align="left">{row['7'] || ''}</TableCell>
                                </TableRow>
                            )
                        })}
                        <TableRow>
                            <TableCell style={{width: '220px'}} size={"small"} rowSpan={1}/>
                            <TableCell style={{width: '200px'}} align="left">Total Hours</TableCell>
                            {sum.map((item, index) => {
                                return <TableCell key={index} align="left">{item ? item : ''}</TableCell>
                            })}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {
                <div className='return-message'>
                    <div>Return Message:</div>
                    {!isEdit ? (
                        <TextField value={message} onChange={(e: any) => setMessage(e.target.value)}/>
                    ) : (
                        <span style={{paddingLeft: 20}}>{message}</span>
                    )}

                </div>
            }

            {
                !isEdit && (
                    <div className='submit'>
                        <Button variant="contained" onClick={() => submit(true)} color="primary">Approve</Button>
                        <Button variant="contained" onClick={() => submit(false)}>Return</Button>
                    </div>
                )
            }

        </Card>
    )
}

export default TimeApprovalTable
