import React, { useEffect, useState } from "react";
import "./index.less"
import { Button, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { getToken, resetPassword } from "./api";
import { useStartStore } from "../../store";
import { observer } from "mobx-react";
import IProps from "../../interface/iprops";


const Reset = ({history}: IProps) => {

    const {success, error, showLoad, hideLoad} = useStartStore();

    const {register, handleSubmit, errors} = useForm();
    const [password, setPassword] = useState('');
    const [token, setToken] = useState('');
    const [inquireUser, setInquireUser]: any = useState({});

    useEffect(() => {
        let str = window.location.href;
        if (str.indexOf('?') >= 0) {
            let arr = str.split("?")[1].split("&");   //先通过？分解得到？后面的所需字符串，再将其通过&分解开存放在数组里
            let obj = {};
            for (let i of arr) {
                // @ts-ignore
                obj[i.split("=")[0]] = i.split("=")[1];  //对数组每项用=分解开，=前为对象属性名，=后为属性值
            }
            setInquireUser(obj);
            getTokenStr(obj)
        }
    }, [])

    const getTokenStr = async (obj: {}) => {
        showLoad()
        let data: any = await getToken(obj);

        if (data.code === 0) {
            setToken(data.data);
        }
        hideLoad();
    }

    const onSubmit = async (e: any) => {
        showLoad();
        e.token = token;
        e.account = inquireUser.account;
        let data: any = await resetPassword(e);
        if (data.code === 0) {
            success(data.message);
            history.replace('/')
        } else {
            error(data.message)
        }
        hideLoad()
    }


    return (
        <div className='reset-view'>
            <div className='reset-box'>
                <div className='reset-logo'><img style={{width: 100, height: 100}}
                                                 src={require('../../assets/images/logo.png')} alt='logo'/></div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField inputRef={register({
                        required: {value: true, message: 'please input your password'},
                    })}
                               error={Boolean(errors.password)}
                               variant="outlined"
                               margin="normal"
                               fullWidth
                               size={"small"}
                               value={password}
                               onChange={(e) => setPassword(e.target.value)}
                               id="email"
                               type={'password'}
                               label="new Password"
                               name="password"
                               helperText={
                                   errors.password && errors.password.message
                               }
                    />

                    <TextField inputRef={register({
                        required: {value: true, message: 'please input your email'},
                        validate: value => password === value ? true : 'two inconsistencies'
                    })}
                               error={Boolean(errors.retype_password)}
                               variant="outlined"
                               margin="normal"
                               fullWidth
                               size={"small"}
                               id="email"
                               type={'password'}
                               label="RetypePassword"
                               name="retype_password"
                               helperText={
                                   errors.retype_password && errors.retype_password.message
                               }
                    />
                    <Button style={{marginTop: '20px'}} fullWidth={true} size={"large"} type={"submit"}
                            variant="contained" color="primary">Reset</Button>
                </form>
            </div>
        </div>
    )
}


export default observer(Reset);
