import React from "react";
import {
    Button,
    ButtonProps,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";


interface IProps {
    open: boolean;
    title: string;
    center?: string | null;
    onClose?: () => void,
    determineConfig?: ButtonProps;
    cancelConfig?: ButtonProps;
    determinetitle?: string;
    cancelTitle?: string;
    children?: any;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = ({open = false, onClose, title, center, determineConfig, determinetitle, cancelConfig, cancelTitle}: IProps) => {

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {center}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {
                    cancelTitle && (
                        <Button {...cancelConfig}>
                            {cancelTitle}
                        </Button>
                    )
                }
                {
                    determinetitle && (
                        <Button {...determineConfig}>
                            {determinetitle}
                        </Button>
                    )
                }
            </DialogActions>
        </Dialog>
    )
}

export const AlertForm = ({open = false, children, onClose, title, center, determineConfig, determinetitle, cancelConfig, cancelTitle}: IProps) => {

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
            <DialogContent>
                {
                    center && (
                        <DialogContentText id="alert-dialog-slide-description">
                            {center}
                        </DialogContentText>
                    )
                }
                <div>
                    {children}
                </div>
            </DialogContent>
            <DialogActions>
                {
                    cancelTitle && (
                        <Button {...cancelConfig}>
                            {cancelTitle}
                        </Button>
                    )
                }
                {
                    determinetitle && (
                        <Button {...determineConfig}>
                            {determinetitle}
                        </Button>
                    )
                }
            </DialogActions>
        </Dialog>
    )
}

export default Alert;
