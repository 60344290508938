import React, { useEffect, useState } from "react";
import {
    Button, Card, Divider,
    Paper,
    Table,
    TableBody,
    TableCell, TableContainer, Select, MenuItem,
    TextField,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { useStyles } from "../../utils/thmem";
import "./index.less"
import { useStartStore } from "../../store";
import { observer } from "mobx-react";
import Updata from "../../componetns/update";
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { getMoment } from '../../utils/tool';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { AlertForm } from '../../componetns/alert';


function NumberFormatCustom(props: any) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values: any) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
}

const ExpenseTable = ({date, projects, data, onSumbit, isAdd, expenses, message, editClose, clearExpense}: any) => {
    const classes = useStyles();

    const {success, error, warning, showLoad, hideLoad} = useStartStore();
    const [row, setRow]: any = useState([]);
    const [desc, setDesc] = useState('');
    const [isEdit, setEdit] = useState(false);
    const [sum, setSum]: any = useState([]);
    const [imgs, setImgs]: any = useState([]);
    const [isFull, setIsFull] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (data && data.length > 0) {
            initTimeSheet(data);
            calcSum(data);
        }

        setEdit(isAdd);
        setImgs([])
    }, [isAdd, data])

    useEffect(() => {
        if (!isAdd) {
            /*let list = row.map((_item:any) => {
                _item.state = false;
                _item.desc = '';
                _item.timeSheetArr = [];
                _item.data = [];

                return _item
            })
            setRow(list);*/
            setRow([]);
            initTimeSheet([]);
            setSum([]);
            setDesc('');
            addTime([]);
        }
    }, [isAdd, date])

    const initTimeSheet = (record: any[] | any, isUpdate = false) => {
        const getSheetArr = (cur: any) => {
            const timeSheetArr: any[] = [];
            date.forEach((d: any, index: number) => {

                timeSheetArr[index] = {date: d};

                for (const item of cur.data) {
                    if (d === item.date) {
                        timeSheetArr[index].fee = item.fee || 0;
                        break;
                    }
                }
            });

            return timeSheetArr;
        }

        if (isUpdate) {
            return getSheetArr(record);
        }


        for (let i = 0; i < record.length; i++) {
            const cur = record[i];

            record[i] && (record[i].timeSheetArr = getSheetArr(cur));
        }

        setRow(record);
    };

    const calcSum = (rows: any[]) => {
        let list: any[] = [];
        for (const rowItem of rows) {
            rowItem.timeSheetArr.forEach((item: any, index: number) => {
                if (list[index]) {
                    list[index].fee += Number(item.fee) || 0;
                } else {
                    list[index] = {
                        date: item.date,
                        fee: item.fee ? Number(item.fee) : 0
                    };
                }
            })
        }

        setSum(list);
    }

    const addTime = (row: any[]) => {
        let list = [...row];

        list.forEach((item) => {
            item.state = false;
        });


        const record: any = {project_id: 0, desc: "", data: [], state: true};

        const sheetArr = initTimeSheet(record, true);

        record['timeSheetArr'] = sheetArr

        list.push(record);

        setDesc('');
        setRow(list);
    }

    const editRow = (index: number) => {
        let list = [...row]
        list.forEach((_item, _index) => {
            if (_index === index) {
                _item.state = true;
                //设置反填信息
                setDesc(_item.desc);

            } else {
                _item.state = false;
            }
        })

        setRow(list);
    }

    const delList = (index: number) => {
        let list = [...row];
        list.splice(index, 1);
        setRow(list);
        calcSum(list);
    }

    const offRow = () => {
        let list = [...row]
        list.forEach((_item, _index) => {
            _item.state = false;
        })

        setRow(list);
    }

    const saveRow = (index: number) => {
        let list = [...row];
        list.forEach((_item, _index) => {
            _item.state = false;

            if (_index === index) {
                list[index].desc = desc;
            }
        })
        setDesc('');
        setRow(list);
        calcSum(list);
    }

    const handleChange = (value: any, index: number) => {
        let list = [...row];
        list[index].project_id = value;

        setRow(list);
    }
    const handleChangeExpense = (value: unknown, index: number) => {
        let list = [...row];
        list[index].expense_id = value;

        setRow(list);
    }

    const submit = () => {

        if (row.length === 0) {
            error('Submission cannot be empty')
            return;
        }

        for (const item of row) {
            if (item.state) {
                return error('Please save');
            }
            if (item.project_id === 0) {
                return error('Please choose project')
            }

            if (!item.expense_id) {
                return error('Please choose expense')
            }

            let isEmptyDuration = true;
            for (const time of item.timeSheetArr) {
                if (time.fee && time.fee > 0) {
                    isEmptyDuration = false;
                }
            }

            if (isEmptyDuration) {
                return error('Please enter expense fee')
            }
        }


        let data: any = [...row];


        row.forEach((item: any, index: string | number) => {
            const record = data[index].timeSheetArr.filter((tiem: any) => {
                return !(!tiem.fee || tiem.fee <= 0);
            });


            for (const key in record) {
                if (record.hasOwnProperty(key)) {

                }
            }

            // data[index].date = item.date;
            data[index].desc = item.desc;
            // data[index].duration = parseInt(item.duration);
            data[index].id = item.id;
            data[index].project_id = item.project_id
            data[index].expense_id = item.expense_id
            data[index].data = record;

            delete data[index].gmt_create
            delete data[index].gmt_modified
            delete data[index].state
            delete data[index].timeSheetArr
            delete data[index].user_id
            delete data[index].record
            delete data[index].status
        })

        onSumbit(data, imgs, cancelEdit);
    }

    const cancelEdit = () => {
        setRow([]);
        setSum([]);
        setEdit(false);
        clearExpense();
    }

    const setDateValue = (val: string, date: string) => {
        const rows = [...row];

        for (let i = 0; i < rows.length; i++) {
            const cur = rows[i];
            if (cur.state) {
                for (let j = 0; j < cur.timeSheetArr.length; j++) {
                    if (cur.timeSheetArr[j].date === date) {
                        rows[i].timeSheetArr[j].fee = val ? Number(val) : '';

                        return setRow(rows);
                    }
                }
            }
        }
    }

    return (
        <div>
            <Card className={`table ${isFull ? 'm-full-screen' : ''}`}  style={{marginTop: '20px', width: '100%'}}>
                <div className='table-title'>
                    <div style={{display: "flex"}}>Expense Input
                        <div style={{width: '10px'}}/>
                        <span style={{color: '#3383d5'}}>
                        {editClose === 0 && isEdit && '(Edit...)'}
                        </span>
                        <span style={{color: '#ec5560'}}>
                            {editClose === 1 && isEdit && '(Rejectted...)'}
                        </span>
                        <span style={{color: '#25ad64'}}>
                            {editClose === 2 && isEdit && '(Approved...)'}
                        </span>

                        <span style={{
                            paddingLeft: 20,
                            color: '#d315b3'
                        }}>[ {getMoment(date[0]).format('MMMM YYYY')} ]</span>
                    </div>

                    <div>
                        {!isEdit && <Button variant="contained"
                                            color="primary"
                                            onClick={() => addTime(row)}
                                            size={"small"}>add</Button>}
                        <Button
                            size={"small"}
                            style={{marginLeft: '10px'}}
                            variant="contained"
                            onClick={() => setIsFull(!isFull)}
                            startIcon={isFull ? <FullscreenExitIcon/> : <FullscreenIcon/>}
                        >
                            {isFull ? 'Full Exit' : 'Full Screen'}
                        </Button>
                    </div>

                </div>
                {
                    editClose === 1 && (
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: '10px',
                            marginTop: '-10px',
                            padding: '0 10px',
                            boxSizing: "border-box",
                            color: '#ec5560'
                        }}>
                            Returned Message: {message}
                        </div>
                    )
                }
                <Divider/>

                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: '170px'}} align="left">input Your Project</TableCell>
                                <TableCell style={{width: '270px'}} align="left">input Your Expense</TableCell>
                                <TableCell style={{width: '200px'}}>Desc</TableCell>

                                {
                                    date.map((item: string,index:any) => {
                                        return <TableCell key={index} style={{
                                            width: '65px',
                                            padding: '8px 5px'
                                        }}>{moment(item).format('MM/DD')}</TableCell>
                                    })
                                }
                                <TableCell style={{width: '200px'}}>Operation</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {
                                row.map((item: any, index: number) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell style={{width: '170px'}} align="left">
                                                {
                                                    item.state ? (
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={item.project_id}
                                                            onChange={(e) => handleChange(e.target.value, index)}
                                                        >
                                                            <MenuItem value={0} disabled>Select project</MenuItem>
                                                            {projects.map((item: any) => {
                                                                return <MenuItem key={item.id}
                                                                                 value={item.id}>{item.name}</MenuItem>
                                                            })}
                                                        </Select>
                                                    ) : item.project_id === 0 ? (
                                                        <div>please choose</div>
                                                    ) : projects.map((_item: any,index:any) => {
                                                        if (_item.id === item.project_id) {
                                                            return <div key={index}>{_item.name}</div>
                                                        }
                                                    })

                                                }
                                            </TableCell>
                                            <TableCell style={{width: '270px'}} align="left">
                                                {
                                                    item.state ? (
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={item.expense_id}
                                                            style={{width: '100%'}}
                                                            onChange={(e) => handleChangeExpense(e.target.value, index)}
                                                        >
                                                            <MenuItem value={0} disabled>Select expense</MenuItem>
                                                            {expenses.map((item: any) => {
                                                                return <MenuItem key={item.id}
                                                                                 value={item.id}>{item.name}</MenuItem>
                                                            })}
                                                        </Select>
                                                    ) : item.expense_id === 0 ? (
                                                        <div>please choose</div>
                                                    ) : expenses.map((_item: any,index:any) => {
                                                        if (_item.id === item.expense_id) {
                                                            return <div key={index}>{_item.name}</div>
                                                        }
                                                    })

                                                }
                                            </TableCell>
                                            <TableCell style={{width: '200px'}}>
                                                {
                                                    item.state ? (
                                                        <TextField defaultValue={item.desc}
                                                                   value={desc}
                                                                   onClick={()=>{
                                                                       setOpen(true)
                                                                   }}
                                                                   onChange={(e) => setDesc(e.target.value)}
                                                                   style={{width: '100%'}}/>
                                                    ) : (
                                                        <div>{item.desc}</div>
                                                    )
                                                }

                                            </TableCell>
                                            {item.timeSheetArr && item.timeSheetArr.map((timeItem: any,index:any) => {
                                                return (
                                                    <TableCell key={index} style={{width: '65px', padding: '8px 5px'}}>
                                                        {
                                                            item.state ? (
                                                                <TextField
                                                                    value={timeItem.fee ? timeItem.fee : ''}
                                                                    onChange={(e) => {
                                                                        setDateValue(e.target.value, timeItem.date)
                                                                    }}
                                                                    style={{width: '100%'}}
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatCustom,
                                                                    }}
                                                                />
                                                            ) : (
                                                                <div>{timeItem.fee}</div>
                                                            )
                                                        }
                                                    </TableCell>
                                                )
                                            })}
                                            {/*
                                            <TableCell style={{width: '100px'}}>
                                                {
                                                    item.state ? (
                                                        <TextField defaultValue={item.date === date[6] && item.fee} value={seven} onChange={(e)=> {
                                                            emptyValue("seven")
                                                            setSeven(e.target.value)
                                                        }} style={{width: '100%'}}/>
                                                    ):(
                                                        <div>{item.date === date[6] && item.fee}</div>
                                                    )
                                                }
                                            </TableCell> */}

                                            <TableCell style={{width: '200px', padding: 0}}>
                                                <div>
                                                    {/*编辑*/}
                                                    {!item.state && <Button color="primary"
                                                                            onClick={() => editRow(index)}>Edit</Button>}
                                                    {!item.state && !isEdit && <Button color="secondary"
                                                                                       onClick={() => delList(index)}>Delete</Button>}
                                                    {/*编辑状态*/}
                                                    {item.state && <Button color="primary"
                                                                           onClick={() => saveRow(index)}>Save</Button>}
                                                    {item.state && <Button onClick={offRow}>cancel</Button>}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                            {sum && sum.length > 0 ? (<TableRow>
                                    <TableCell style={{width: '220px'}} size={"small"} rowSpan={3}/>
                                    <TableCell style={{width: '220px'}} size={"small"} rowSpan={3}/>
                                    <TableCell style={{width: '200px'}} align="left">Total Amount</TableCell>

                                    {sum.map((item: any) => {
                                        return <TableCell key={item.date}
                                                          style={{width: '65px', padding: '8px 5px'}}
                                                          align="left">{item.fee ? item.fee : ''}</TableCell>
                                    })}

                                    <TableCell size={"small"} rowSpan={1}/>
                                </TableRow>
                            ) : null}
                        </TableBody>
                    </Table>
                </TableContainer>

                <div className="m-select-file">
                    {editClose !== 2 || imgs.length > 0 ? (<span className="label">Attach Your Invoice：</span>) : null}
                    <Updata lists={imgs} isShow={editClose === 2} onChange={(e: any) => setImgs(e)}/>
                </div>

                <div className='table-sumbit'>
                    {isEdit && <Button variant="contained"
                                       color="secondary"
                                       onClick={cancelEdit}
                                       style={{color: '#fff', marginRight: 20}}
                                       size={"small"}>cancel</Button>}

                    {
                        editClose !== 2 && <Button variant="contained" onClick={submit} color="primary">sumbit22</Button>
                    }

                </div>
            </Card>

            <AlertForm
                open={open}
                title={'Please enter description'}
                center={null}
                cancelTitle={'cancel'}
                cancelConfig={{
                    onClick: () => setOpen(false)
                }}
                determinetitle={'submit'}
                determineConfig={{
                    color: "primary",
                    onClick: () => {
                        setOpen(false)
                    }
                }}
            >
                <div style={{width:600,paddingRight:50}}>
                    <TextField
                        label="Desc"
                        multiline
                        rows={5}
                        defaultValue="Default Value"
                        variant="outlined"
                        value={desc}
                        fullWidth={true}
                        autoFocus={true}
                        onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setDesc(e.target.value)}
                    />
                </div>
            </AlertForm>
        </div>
    )
}

export default observer(ExpenseTable);
