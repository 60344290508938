import React, { useEffect, useState } from 'react';
import { Card, Divider, TextField, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'
import { useForm } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useStartStore } from '../../store';
import Http from '../../utils/axios';

interface IProps {
    onSubmit?: any
}

const AicEmployer = ({ onSubmit }: IProps) => {
    const { register, handleSubmit, errors, reset } = useForm();
    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyCode, setCompanyCode] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [userInfo, setUserInfo]: any = useState({});
    const [versionType, setVersionType]: any = useState('');
    const [open, setDialogOpen]: any = useState(false);
    const versionMap: any = {
        1: 'Free',
        2: 'Paid'
    };
    
    useEffect(() => {
        let user: any = localStorage.getItem('userInfo');
        let info = JSON.parse(user);
        setUserInfo(info);
        setName(info.username);
        setPhone(info.detail_info.phone);
        setCompanyName(info.company_info.name);
        setEmail(info.email);
        setVersionType(info.company_info.version_type)
    }, []);
    
    const sendSubmit = () => {
        let data = {
            name: name,
            email: userInfo.email,
            phone: phone,
            company_name: companyName,
            company_code: companyCode
        };
        
        onSubmit(data, async function () {
            if (email !== userInfo.email) {
                await Http.post('/api/v1/account/logout');
                localStorage.clear();
                window.location.replace('/');
            }
        });
        
        setDialogOpen(false);
        reset();
    };
    
    const submit = (e: any) => {
        if (email !== userInfo.email) {
            return setDialogOpen(true);
        }
        
        sendSubmit();
    };
    
    return (
        <Card className="passwrod-view">
            <div className="passwrod-title">Employer Input</div>
            <Alert icon={ false } severity="warning" style={ { marginBottom: 10 } }>
                AIC Version Type：<span style={ { fontWeight: 'bold' } }>{ versionMap[ versionType ] } Version</span>
            </Alert>s
            <Divider />
            <form onSubmit={ handleSubmit(submit) }>
                <TextField inputRef={ register() }
                           error={ Boolean(errors.company_name) }
                           variant="outlined"
                           size={ 'small' }
                           margin="normal"
                           value={ companyName }
                           fullWidth
                           id="email"
                           onChange={ (e: any) => setCompanyName(e.target.value) }
                           label="Company Name"
                           name="company_name"
                           placeholder="Please enter the Company name"
                           helperText={
                               errors.company_name && errors.company_name.message
                           }
                />
                <TextField inputRef={ register() }
                           error={ Boolean(errors.company_code) }
                           variant="outlined"
                           size={ 'small' }
                           margin="normal"
                           value={ companyCode }
                           fullWidth
                           id="email"
                           onChange={ (e: any) => setCompanyCode(e.target.value) }
                           label="Company Code"
                           name="company_code"
                           placeholder="Please enter the company code"
                           helperText={
                               errors.company_code && errors.company_code.message
                           }
                />
                <TextField inputRef={ register() }
                           error={ Boolean(errors.email) }
                           variant="outlined"
                           margin="normal"
                           size={ 'small' }
                           fullWidth
                           id="email"
                           value={ email }
                           label="Work Email"
                           name="work_email"
                           onChange={ (e: any) => setEmail(e.target.value) }
                           placeholder="Please enter the Work Email"
                           helperText={
                               errors.work_email && errors.work_email.message
                           }
                />
                <TextField inputRef={ register() }
                           error={ Boolean(errors.phone) }
                           variant="outlined"
                           margin="normal"
                           size={ 'small' }
                           fullWidth
                           id="email"
                           value={ phone }
                           onChange={ (e: any) => setPhone(e.target.value) }
                           label="Phone"
                           name="phone"
                           placeholder="Please enter the Retype New Password"
                           helperText={
                               errors.phone && errors.phone.message
                           }
                />
                <div className="submit">
                    <Button variant="contained" type={ 'submit' } color="primary">Enter</Button>
                </div>
            </form>
            
            <Dialog
                open={ open }
                onClose={ () => setDialogOpen(false) }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Email account modification confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={ { color: 'red' } }>
                        It is detected that you have modified the email, which will cause the current account
                        permissions to be transferred to the new email. If the new account exists.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ () => setDialogOpen(false) } color="primary">
                        Cancel
                    </Button>
                    <Button onClick={ sendSubmit } color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export default AicEmployer;
