import React, {useEffect, useState} from "react";
import {Button, Card, Divider,TextField} from "@material-ui/core";
import moment from "moment"
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import { getMoment } from '../../utils/tool';

interface IProps {
    onSubmit?:any
}

const Contractor = ({onSubmit}:IProps)=>{

    let userInfo = localStorage.getItem('userInfo')

    let [expire,setExpire]:any = useState(null);
    let [info, setInfo] = useState<any>({
        detail_info: {
            w9_on_file:[],
            contract_on_file:[]
        },
        team_info:{}
    });

    useEffect(() => {
        if (typeof userInfo === "string") {
            setInfo(JSON.parse(userInfo))
            let date:any = JSON.parse(userInfo).detail_info.contract_expire_date
            setExpire(date ? getMoment(date).format('YYYY-MM-DD'):null);
        }
    }, [])

    return (
        <Card className='contractor-view'>
            <div className='contractor-title'>Contractor Profile</div>
            <Divider/>
            <div className='contractor-content'>
                <div className='contractor-content-box'>
                    <div className='title'>Name:</div>
                    <div>{info.name}</div>
                </div>
                <div className='contractor-content-box'>
                    <div className='title'>Team</div>
                    <div>{info.team_info.name}</div>
                </div>
                <div className='contractor-content-box'>
                    <div className='title'>Job Title:</div>
                    <div>{info.detail_info.job_title}</div>
                </div>
                <div className='contractor-content-box'>
                    <div className='title'>Supervisor:</div>
                    <div>{info.detail_info.supervisor}</div>
                </div>
                <div className='contractor-content-box'>
                    <div className='title'>Start Date:</div>
                    <div>{info.detail_info.start_date ? moment(info.detail_info.start_date).format('MMMM DD, YYYY'):''}</div>
                </div>
                <div className='contractor-content-box'>
                    <div className='title'>Work Email:</div>
                    <div>{info.email}</div>
                </div>
                <div className='contractor-content-box'>
                    <div className='title'>Phone:</div>
                    <div>{info.detail_info.phone}</div>
                </div>
                <div className='contractor-content-box'>
                    <div className='title'>W9 on File:</div>
                    <div>
                        {
                            info.detail_info.w9_on_file && info.detail_info.w9_on_file.map((item:any,index:number)=>{
                                return <a target="_blank" href={item.path} key={index}>{item.name}</a>
                            })
                        }
                    </div>
                </div>
                <div className='contractor-content-box'>
                    <div className='title'>Contract Expire on:</div>
                    <div className='enter'>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy-MM-dd"
                                    margin="normal"
                                    id="dContract Expire"
                                    label="Contract Expire"
                                    value={expire}
                                    onChange={setExpire}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                        <Button variant="contained" onClick={()=> {
                            onSubmit(expire)
                        }} color="primary">UpDate</Button>
                    </div>
                </div>
                <div className='contractor-content-box'>
                    <div className='title'>Contract on File:</div>
                    <div>{info.detail_info.contract_on_file && info.detail_info.contract_on_file.map((item: { path: string | undefined; name: React.ReactNode; }, index: string | number | undefined)=>{
                        return <a target="_blank" href={item.path} key={index}>{item.name}</a>
                    })}</div>
                </div>
            </div>
        </Card>
    )
}

export default Contractor
