import Http from "../../utils/axios"

interface IProps {
    email:string;
    password:string;
    is_remember:boolean
}

export const login = (parameter:IProps)=>{
    let data = {
        email:parameter.email,
        password:parameter.password,
        is_remember:parameter.is_remember ? '1' : null
    }
    return Http.post('/api/v1/account/login',data);
}


