import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import { Menu, Avatar, MenuItem } from "@material-ui/core"
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { Switch, Route } from "react-router-dom";
import IProps from "../../interface/iprops";
import "./index.less"
import { Role } from "../../enum/userType";
import Http from "../../utils/axios";
import { useStartStore } from '../../store';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
        },
    }),
);

const Layout = ({history, routes}: IProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const {success, error, warning, showLoad, hideLoad} = useStartStore();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [info, setInfo] = useState<any>({username: '', company_info: {name: ''}});
    const [hasApproval, setHasApproval] = React.useState(false);

    const openMore = Boolean(anchorEl);

    useEffect(() => {
        let user: any = localStorage.getItem('userInfo');

        if (typeof user === "string") {
            user = JSON.parse(user);

            // @ts-ignore
            // user['role'] = 4;
            if (user.role === Role.TEAM_LEADER) {
                let list = user.detail_info.ability_to_approve ? user.detail_info.ability_to_approve.split(','):[];

                list.forEach((item: string) => {
                    switch (item) {
                        case '1':
                            setHasApproval(true);
                            break;
                        case '2':
                            setHasApproval(true);
                            break;
                        case '3':
                            setHasApproval(true);
                            break;
                    }
                })
            }


            setInfo(user);
        }
    }, [])

    const scroll: any = useRef();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const userMore = async (e: any) => {
        setAnchorEl(null);
        switch (e) {
            case 'Profile':

                break;
            case 'Logout':
                showLoad();
                await Http.post('/api/v1/account/logout');
                localStorage.clear();
                window.location.replace('/')
                break;
            default:
                console.log('err')
        }
    }

    const getTitle = () => {
        switch (Number(info.role)) {
            case Role.AIC:
                return 'Account in Charge';
            case Role.TEAM_LEADER:
                return 'Team Leader';
            case Role.CONTRACTOR:
                return 'Contractor';
            case Role.STAFF:
                return 'Staff';
            default:
                return 'Records Management System'
        }
    }

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6" noWrap>
                            {getTitle()}
                        </Typography>
                    </div>

                    <div className='avatar-view'>
                        <Avatar aria-label="recipe">
                            {info.avatar ? <img width='100%' src={info.avatar}/> : info.username}
                        </Avatar>
                        <div className='avatar-content'>
                            <div className='avatar-title'>
                                <div>{info.name}</div>
                                {/*<div className='avatar-title-date'>Wednesdat <span>12:21</span></div>*/}
                            </div>
                            <div className='avatar-title' style={{opacity: 0.5}}>
                                <div>{info.company_info.name}</div>
                            </div>
                        </div>
                        <IconButton aria-label="settings" onClick={handleClick}>
                            <MoreVertIcon style={{color: '#FFF'}}/>
                        </IconButton>
                        <Menu
                            id="long-menu"
                            keepMounted
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            open={openMore}
                            PaperProps={{
                                style: {
                                    width: '20ch',
                                    marginTop: '40px'
                                },
                            }}
                        >
                            {['Profile', 'Logout'].map((option) => (
                                <MenuItem key={option} value={20} onClick={(e) => userMore(option)}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <img style={{width: '30px'}} src={require('../../assets/images/logo.png')}/>
                    <div>Records Management</div>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </div>
                <Divider/>
                {/*<List>
                    <ListItem selected={window.location.pathname === '/home/overview'} button onClick={() => {
                        scroll.current.scrollTop = 0;
                        history.push('/home/overview')
                    }}>
                        <ListItemIcon><img width={24}
                                           height={24}
                                           src={require('../../assets/images/home.png')}/></ListItemIcon>
                        <ListItemText primary={'Overview'}/>
                    </ListItem>
                </List>*/}
                <List>
                    <ListItem button selected={window.location.pathname === '/home/timeAndExpense'} onClick={() => {
                        scroll.current.scrollTop = 0;
                        history.push('/home/timeAndExpense')
                    }}>
                        <ListItemIcon><img width={24} height={24}
                                           src={require('../../assets/images/date.png')}/></ListItemIcon>
                        <ListItemText primary={'Report Dashboard'}/>
                    </ListItem>
                </List>
                <Divider/>

                {
                    info.role === Role.AIC && (
                        <React.Fragment>
                            <List>
                                <ListItem button selected={window.location.pathname === '/home/setup'} onClick={() => {
                                    scroll.current.scrollTop = 0;
                                    history.push('/home/setup')
                                }}>
                                    <ListItemIcon><img width={24} height={24}
                                                       src={require('../../assets/images/list.png')}/></ListItemIcon>
                                    <ListItemText primary={'Setup'}/>
                                </ListItem>
                            </List>
                            <Divider/>
                            <List>
                                <ListItem button
                                          selected={window.location.pathname === '/home/profile'}
                                          onClick={() => {
                                              scroll.current.scrollTop = 0;
                                              history.push('/home/profile')
                                          }}>
                                    <ListItemIcon><img width={24} height={24}
                                                       src={require('../../assets/images/personnel.png')}/></ListItemIcon>
                                    <ListItemText primary={'Profile'}/>
                                </ListItem>
                            </List>
                        </React.Fragment>
                    )
                }
                {
                    info.role === Role.TEAM_LEADER && (
                        <List>
                            <ListItem button selected={window.location.pathname === '/home/profile'} onClick={() => {
                                scroll.current.scrollTop = 0;
                                history.push('/home/profile')
                            }}>
                                <ListItemIcon><img width={24} height={24}
                                                   src={require('../../assets/images/personnel.png')}/></ListItemIcon>
                                <ListItemText primary={'Profile'}/>
                            </ListItem>
                        </List>
                    )
                }

                {
                    info.role === Role.STAFF && (
                        <List>
                            <ListItem button selected={window.location.pathname === '/home/profile'} onClick={() => {
                                scroll.current.scrollTop = 0;
                                history.push('/home/profile')
                            }}>
                                <ListItemIcon><img width={24} height={24}
                                                   src={require('../../assets/images/personnel.png')}/></ListItemIcon>
                                <ListItemText primary={'Profile'}/>
                            </ListItem>
                        </List>
                    )
                }

                {
                    info.role === Role.CONTRACTOR && (
                        <List>
                            <ListItem button selected={window.location.pathname === '/home/profile'} onClick={() => {
                                scroll.current.scrollTop = 0;
                                history.push('/home/profile')
                            }}>
                                <ListItemIcon><img width={24} height={24}
                                                   src={require('../../assets/images/personnel.png')}/></ListItemIcon>
                                <ListItemText primary={'Profile'}/>
                            </ListItem>
                        </List>
                    )
                }

                <Divider/>

                {
                    info.role === Role.TEAM_LEADER && (
                        <React.Fragment>
                            <List>
                                <ListItem button
                                          selected={window.location.pathname === '/home/submission'}
                                          onClick={() => {
                                              scroll.current.scrollTop = 0;
                                              history.push('/home/submission')
                                          }}>
                                    <ListItemIcon><img width={24} height={24}
                                                       src={require('../../assets/images/book.png')}/></ListItemIcon>
                                    <ListItemText primary={'Submission'}/>
                                </ListItem>
                            </List>
                            <Divider/>
                        </React.Fragment>
                    )
                }

                {
                    info.role === Role.STAFF && (
                        <List>
                            <ListItem button selected={window.location.pathname === '/home/submission'} onClick={() => {
                                scroll.current.scrollTop = 0;
                                history.push('/home/submission')
                            }}>
                                <ListItemIcon><img width={24} height={24}
                                                   src={require('../../assets/images/book.png')}/></ListItemIcon>
                                <ListItemText primary={'Submission'}/>
                            </ListItem>
                        </List>
                    )
                }

                {
                    info.role === Role.CONTRACTOR && (
                        <List>
                            <ListItem button selected={window.location.pathname === '/home/submission'} onClick={() => {
                                scroll.current.scrollTop = 0;
                                history.push('/home/submission')
                            }}>
                                <ListItemIcon><img width={24} height={24}
                                                   src={require('../../assets/images/book.png')}/></ListItemIcon>
                                <ListItemText primary={'Submission'}/>
                            </ListItem>
                        </List>
                    )
                }

                {
                    info.role === Role.AIC && (
                        <List>
                            <ListItem button selected={window.location.pathname === '/home/approval'} onClick={() => {
                                scroll.current.scrollTop = 0;
                                history.push('/home/approval')
                            }}>
                                <ListItemIcon><img width={24} height={24}
                                                   src={require('../../assets/images/audit.png')}/></ListItemIcon>
                                <ListItemText primary={'Approval'}/>
                            </ListItem>
                        </List>
                    )
                }

                {
                    (info.role === Role.TEAM_LEADER && hasApproval) &&  (
                        <List>
                            <ListItem button selected={window.location.pathname === '/home/approval'} onClick={() => {
                                scroll.current.scrollTop = 0;
                                history.push('/home/approval')
                            }}>
                                <ListItemIcon><img width={24} height={24}
                                                   src={require('../../assets/images/audit.png')}/></ListItemIcon>
                                <ListItemText primary={'Approval'}/>
                            </ListItem>
                        </List>
                    )
                }

                <Divider/>
                
            </Drawer>
            <div ref={scroll}
                 className={classes.content}
                 style={{
                     paddingTop: '70px',
                     width: `calc( 100vw - ${open ? 240 : 73}px )`,
                     overflow: 'hidden',
                     minWidth: '1280px'
                 }}>
                <Switch>
                    {
                        routes.map((item: any, index: number) => (
                            <Route key={index} exact={item.exact} path={item.path} component={item.component}/>
                        ))
                    }
                </Switch>
            </div>
        </div>
    );
}

export default Layout
