export enum UserType{
    'ADMIN',
    'AIC',
    'TEAM',
    'STAFF',
    'CONTRACTOR'
}

export enum Role {
    AIC = 1,
    TEAM_LEADER,
    STAFF,
    CONTRACTOR,
    SUPER_ADMIN = 999
}

export enum EAccountStatus {
    Accepted = 1,
    Waiting,
    Delete
}

export enum ESbmTimeSheetStatus {
    NORMAL=1,    // 正常
    CANCEL,     // 取消     在获取提交记录列表可以使用
    SUBMITTED,   // 已提交    在获取提交记录列表可以使用
    REJECTED,    // 已拒绝    在获取提交记录列表可以使用
    APPROVED,    // 已通过    在获取提交记录列表可以使用
    INVALID    // 失效    无账号提交使用
}

export enum ETaskType {
    TIME_SHEET=1,
    EXPENSE,
    INVOICE,
    NO_ACCOUNT_INVOICE
}

export enum WorkStatus {
    'full time' = 1,
    'part time'
}
