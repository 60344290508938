import React, {useEffect, useState} from "react";
import {
    Button,
    Fab,
    FormControl,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {useForm} from "react-hook-form";
import {useStartStore} from "../../../store";
import {useStyles} from "../../../utils/thmem";
import {observer} from "mobx-react";
import "./index.less"
import {addExpense, getExpense} from "./api";
// @ts-ignore
import {DeleteTeam} from "../editTeamMember/api";
import moment from "moment";
import { getMoment } from '../../../utils/tool';

interface ImgList {
    path:string;
    name:string;
}

interface projectList {
    company_id?: number;
    desc?: string;
    gmt_create?: string;
    gmt_modified?: string | null
    id?: number;
    name?:string;
    status?:string;
}

const EditExpense = ()=>{

    const {handleSubmit,errors,register} = useForm();
    const {success,error,showLoad,hideLoad} = useStartStore();

    const classes = useStyles();

    const [edit,setEdit] = useState(false);
    const [addInfo,setAddInfo] = useState(false);
    const [modify,setModify] = useState(false);
    const [projectList,setProjectList]:any = useState([]);
    const [info,setInfo]:any = useState({name:'',manager:''});

    useEffect(()=>{
        loadData();
    },[])

    const loadData = ()=>{
        getlist();
    }

    const getlist = async ()=>{
        showLoad();
        let result:any = await getExpense();
        if (result.code === 0){
            setProjectList(result.data);
        }else{
            error(result.message);
        }
        hideLoad();
    }

    const editInfo = (data:projectList)=>{
        setModify(true);
        setEdit(true);
        setAddInfo(false);
        setInfo(data);
    }

    const deleteInfo = async (data:projectList)=>{
        let result:any = await DeleteTeam({cate:'expense',id:data.id});
        if (result.code === 0){
            success(result.message);
            getlist();
        }
    }

    const submit = async (e:projectList)=>{
        e.id = info.id;
        let result:any = await addExpense(e);
        if (result.code === 0){
            success(result.message);
            setEdit(false);
            getlist();
        }else{
            error(result.message);
        }
    }

    const add = ()=>{
        setEdit(true);
        setModify(false);
        setAddInfo(true);
        setInfo({});
    }

    const submitAdd = async (e:any)=>{
        let result:any = await addExpense(e);
        if (result.code === 0){
            success(result.message);
            setEdit(false);
            getlist();
        }else{
            error(result.message);
        }
    }

    return (
        <div className='edit-expense'>
            <div className='edit-expense-add'>
                <Button variant="contained" color="primary" onClick={add}>Add Expense</Button>
            </div>
            <div className='edit-expense-box'>
            <div className='edit-project-left' style={{borderRight:edit ? '1px solid #ededed':'none'}}>
                <TableContainer component={Paper}>
                    <Table size={"small"} style={{background:'none'}}>
                        <TableHead style={{background:'none'}}>
                            <TableRow>
                                <TableCell align="left">Project Name</TableCell>
                                <TableCell align="left">desc</TableCell>
                                <TableCell align="left">gmt create</TableCell>
                                <TableCell align="left">operating</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{background:'none'}}>
                            {projectList.map((item:projectList) => (
                                <TableRow key={item.id}>
                                    <TableCell component="th" align="left" scope="row">{item.name}</TableCell>
                                    <TableCell component="th" align="left" scope="row">{item.desc}</TableCell>
                                    <TableCell component="th" align="left" scope="row">{getMoment(item.gmt_create).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                    <TableCell className='operating' align="left">
                                        <Button onClick={()=>editInfo(item)} color="primary">Edit</Button>
                                        <Button onClick={()=>deleteInfo(item)} color="secondary">Delete</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {projectList.length === 0 && <div style={{flex:1,textAlign:'center',marginTop:'20px'}}>No data</div>}
            </div>

            <div className='edit-project-right' style={{width:edit ? '50%' : 0}}>
                {
                    modify && (
                        <form onSubmit={handleSubmit(submit)} className='edit-project-form'>
                            <FormControl size={"small"} variant="outlined" className={classes.formControl}>
                                <TextField inputRef={register({
                                    required: {value: true, message: 'Please type in your name'},
                                })}
                                           error={Boolean(errors.name)}
                                           variant="outlined"
                                           margin="normal"
                                           fullWidth
                                           id="name"
                                           defaultValue={info.name}
                                           size={"small"}
                                           label="New Expense Name"
                                           name="name"
                                           helperText={
                                               errors.name && errors.name.message
                                           }
                                           />

                                <TextField inputRef={register({
                                    required: {value: true, message: 'Please type in your name'},
                                })}
                                           error={Boolean(errors.desc)}
                                           variant="outlined"
                                           margin="normal"
                                           fullWidth
                                           size={"small"}
                                           id="name"
                                           label="Description"
                                           defaultValue={info.desc}
                                           name="desc"
                                           helperText={
                                               errors.desc && errors.desc.message
                                           }
                                           />

                            </FormControl>

                            <div className='edit-project-submit'>
                                <Button variant="contained" style={{marginRight:'10px'}} onClick={()=>setEdit(false)}>cancel</Button>
                                <Button type={"submit"} variant="contained" color="primary">Svae</Button>
                            </div>
                        </form>
                    )
                }
                {
                    addInfo && (
                        <form onSubmit={handleSubmit(submitAdd)} className='edit-project-form'>
                            <FormControl size={"small"} variant="outlined" className={classes.formControl}>
                                <TextField inputRef={register({
                                    required: {value: true, message: 'Please type in your name'},
                                })}
                                           error={Boolean(errors.name)}
                                           variant="outlined"
                                           margin="normal"
                                           fullWidth
                                           id="name"
                                           defaultValue={info.name}
                                           size={"small"}
                                           label="New Expense Name"
                                           name="name"
                                           helperText={
                                               errors.name && errors.name.message
                                           }
                                           />

                                <TextField inputRef={register({
                                    required: {value: true, message: 'Please type in your name'},
                                })}
                                           error={Boolean(errors.desc)}
                                           variant="outlined"
                                           margin="normal"
                                           fullWidth
                                           size={"small"}
                                           id="name"
                                           label="Description"
                                           defaultValue={info.desc}
                                           name="desc"
                                           helperText={
                                               errors.desc && errors.desc.message
                                           }
                                           />

                            </FormControl>

                            <div className='edit-project-submit'>
                                <Button variant="contained" style={{marginRight:'10px'}} onClick={()=>setEdit(false)}>cancel</Button>
                                <Button type={"submit"} variant="contained" color="primary">Add</Button>
                            </div>
                        </form>
                    )
                }

            </div>
            </div>
        </div>
    )
}

export default observer(EditExpense);
