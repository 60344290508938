import React, {useEffect, useState} from "react";
import {Card, Divider} from "@material-ui/core";
import {WorkStatus} from "../../enum/userType";
import moment from 'moment'
import { getMoment } from '../../utils/tool';

const Employer = () => {

    let userInfo = localStorage.getItem('userInfo')
    let [info, setInfo] = useState<any>({
        detail_info: {
            w4_on_file:[]
        },
        team_info:{}
    });

    useEffect(() => {
        if (typeof userInfo === "string") {
            setInfo(JSON.parse(userInfo))
        }
    }, [])


    return (
        <Card className='employer-view'>
            <div className='employer-title'>Employer Input</div>
            <Divider/>
            <div className='employer-content'>
                <div className='form'>
                    <div className='form-title'>Name:</div>
                    <div className='form-value'>{info.name}</div>
                </div>
                <div className='form'>
                    <div className='form-title'>Job Title:</div>
                    <div className='form-value'>{info.detail_info.job_title}</div>
                </div>
                <div className='form'>
                    <div className='form-title'>Team:</div>
                    <div className='form-value'>{info.team_info.name}</div>
                </div>
                <div className='form'>
                    <div className='form-title'>Supervisor:</div>
                    <div className='form-value'>{info.detail_info.supervisor}</div>
                </div>
                <div className='form'>
                    <div className='form-title'>Employee ID:</div>
                    <div className='form-value'>{info.employee_num}</div>
                </div>
                <div className='form'>
                    <div className='form-title'>Start Date:</div>
                    <div className='form-value'>{getMoment(info.detail_info.start_date).format('MMMM DD, YYYY')}</div>
                </div>
                <div className='form'>
                    <div className='form-title'>Birthday:</div>
                    <div className='form-value'>{info.detail_info.birthday}</div>
                </div>
                <div className='form'>
                    <div className='form-title'>Status:</div>
                    <div className='form-value'>{WorkStatus[info.detail_info.work_status]}</div>
                </div>
                <div className='form'>
                    <div className='form-title'>Work Email:</div>
                    <div className='form-value'>{info.email}</div>
                </div>
                <div className='form'>
                    <div className='form-title'>Phone:</div>
                    <div className='form-value'>{info.detail_info.phone}</div>
                </div>
                <div className='form'>
                    <div className='form-title'>W4 on File:</div>
                    <div className='form-value'>
                        {
                            info.detail_info.w4_on_file && info.detail_info.w4_on_file.map((item: any, index: string | number | undefined)=>{
                                return <a href={item.path} key={index}>{item.name}</a>
                            })
                        }
                    </div>
                </div>
                <div className='form'>
                    <div className='form-title'>Legal Status:</div>
                    <div className='form-value'>{info.detail_info.legal_status}</div>
                </div>
                <div className='form'>
                    <div className='form-title'>Visa Expiration:</div>
                    <div className='form-value'>{info.detail_info.visa_expiration}</div>
                </div>

                <div className='form'>
                    <div className='form-title'>Medical:</div>
                    <div className='form-value'>{info.detail_info.insurance}</div>
                </div>
                <div className='form'>
                    <div className='form-title'>Other:</div>
                    <div className='form-value'>{info.detail_info.additional_insured}</div>
                </div>
                <div className='form'>
                    <div className='form-title'>Dental:</div>
                    <div className='form-value'>{info.detail_info.dental}</div>
                </div>
                <div className='form'>
                    <div className='form-title'>Vision:</div>
                    <div className='form-value'>{info.detail_info.vision}</div>
                </div>
            </div>
        </Card>
    )
}

export default Employer;
