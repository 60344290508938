import React, { useEffect, useState } from 'react';
import './index.less';
import Contractor from './contractor';
import Payment from './payment';
import Passwrod from './password';
import Employer from './employer';
import Optional from './optional';
import { Role } from '../../enum/userType';
import { changePassword, createOptional, editAic, UpdateContractor, UpdatePayment } from './api';
import { useStartStore } from '../../store';
import { observer } from 'mobx-react';
import AicEmployer from './AicEmployer';
import { getUserInfo } from '../activtion/api';

const Profile = () => {
    
    const { success, error, showLoad, hideLoad } = useStartStore();
    const [ userInfo, setUserInfo ] = useState({ role: 0 });
    
    
    useEffect(() => {
        getUserInfos();
    }, []);
    
    const getUserInfos = async () => {
        let result: any = await getUserInfo();
        
        if (result.code === 0) {
            localStorage.setItem('userInfo', JSON.stringify(result.data));
            setUserInfo(result.data);
        } else {
            error(result.message);
        }
    };
    
    let upDate = async (value: string) => {
        showLoad();
        
        let result: any = await UpdateContractor({ contract_expire_date: value });
        
        if (result.code === 0) {
            success(result.message);
            getUserInfos();
        } else {
            error(result.message);
        }
        hideLoad();
    };
    
    let setPassword = async (e: any, cb: Function) => {
        showLoad();
        let result: any = await changePassword(e);
        
        if (result.code === 0) {
            cb && cb(true);
            success(result.message);
            getUserInfos();
        } else {
            error(result.message);
        }
        hideLoad();
    };
    
    let setPayment = async (e: any) => {
        showLoad();
        let result: any = await UpdatePayment(e);
        
        if (result.code === 0) {
            success(result.message);
            getUserInfos();
        } else {
            error(result.message);
        }
        hideLoad();
    };
    
    const setOptional = async (data: any) => {
        showLoad();
        let result: any = await createOptional(data);
        
        if (result.code === 0) {
            success(result.message);
            getUserInfos();
        } else {
            error(result.message);
        }
        hideLoad();
    };
    
    const setAic = async (data: any, cb:any) => {
        showLoad();
        let result: any = await editAic(data);
        
        if (result.code === 0) {
            success(result.message);
            cb && cb();
            getUserInfos();
        } else {
            error(result.message);
        }
        hideLoad();
    };
    
    return (
        <div className='profile-view'>
            
            { userInfo.role === Role.STAFF && <Employer/> }
            { userInfo.role === Role.AIC && <AicEmployer onSubmit={ setAic }/> }
            { userInfo.role === Role.TEAM_LEADER && <Employer/> }
            
            { userInfo.role === Role.CONTRACTOR && <Contractor onSubmit={ upDate }/> }
            
            <Passwrod onSubmit={ setPassword }/>
            
            { userInfo.role === Role.CONTRACTOR && <Payment onSubmit={ setPayment }/> }
            
            { userInfo.role === Role.STAFF && <Optional onSubmit={ setOptional }/> }
            { userInfo.role === Role.TEAM_LEADER && <Optional onSubmit={ setOptional }/> }
    
        </div>
    );
};

export default observer(Profile);
