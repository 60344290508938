import Http from "../../utils/axios"
import {Role} from "../../enum/userType";

interface IProps {
    start_time?:any;
    end_time?:any;
    user_id?:number | null;
    team_id?:number | null;
    company_id?:number | null;
    export_file?:number;
}

export const timeSheet = (parameter:IProps)=>{

    let data = {
        start_time: parameter.start_time,//
        end_time:parameter.end_time,//
        user_id:parameter.user_id, //aci / li
        team_id:parameter.team_id, // aci
        company_id:parameter.company_id,
        export_file:parameter.export_file //1 download
    }

    return Http.get('/api/v1/report/time/sheet/list',data)
}

export const expenseList = (parameter:IProps)=>{
    let data = {
        start_time: parameter.start_time,//
        end_time:parameter.end_time,//
        user_id:parameter.user_id, //aci / li
        team_id:parameter.team_id, // aci
        company_id:parameter.company_id,
        export_file:parameter.export_file //1 download
    }
    return Http.get('/api/v1/report/expense/list',data)
}

export const invoiceList = (parameter:IProps)=>{
    let data = {
        start_time: parameter.start_time,//
        end_time:parameter.end_time,//
        user_id:parameter.user_id, //aci / li
        team_id:parameter.team_id, // aci
        company_id:parameter.company_id,
        export_file:parameter.export_file //1 download
    }
    return Http.get('/api/v1/report/invoice/list',data)
}

export const invoiceNoAccountList = (parameter:IProps)=>{
    let data = {
        start_time: parameter.start_time,//
        end_time:parameter.end_time,//
        export_file:parameter.export_file //1 download
    }
    return Http.get('/api/v1/report/no/account/invoice/list',data)
}

export const getProjectListApi = (parameter:IProps)=>{
    let data = {
        start_time: parameter.start_time,//
        end_time:parameter.end_time,//
        user_id:parameter.user_id, //aci / li
        team_id:parameter.team_id, // aci
        company_id:parameter.company_id,
        export_file:parameter.export_file //1 download
    }
    return Http.get('/api/v1/report/project/list',data)
}

export const timeSheetDown = (parameter:IProps)=>{

    let data = {
        start_time: parameter.start_time,
        end_time:parameter.end_time,
        user_id:parameter.user_id,
        team_id:parameter.team_id,
        company_id:parameter.company_id,
        export_file:parameter.export_file
    }

    return Http.get('/api/v1/report/time/sheet/list',data)
}


export const expenseListDown = (parameter:IProps)=>{
    let data = {
        start_time: parameter.start_time,
        end_time:parameter.end_time,
        user_id:parameter.user_id,
        team_id:parameter.team_id,
        company_id:parameter.company_id,
        export_file:parameter.export_file
    }
    return Http.get('/api/v1/report/expense/list',data)
}

