import React from "react";
import {Tabs,Tab,Box} from "@material-ui/core"
import IndivdualInvite from "./indivdualInvite";
import EditTeamMember from "./editTeamMember";
import EditProject from "./editProject";
import EditExpense from "./editExpense";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const SetUp = ()=>{

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div style={{width:'100%',height:'100%',background:'#f9f9f9'}}>
            <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                style={{background:'#FFF'}}
                onChange={handleChange}
                aria-label="disabled tabs example"
            >
                <Tab label="Invite Team Menber" />
                <Tab label="Edit Team Member" />
                <Tab label="Edit Project" />
                <Tab label="Edit Expense" />
            </Tabs>

            <TabPanel value={value} index={0}>
                <IndivdualInvite/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <EditTeamMember/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <EditProject/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <EditExpense/>
            </TabPanel>
        </div>
    )
}

export default SetUp
