import Http from "../../utils/axios"
import { Role } from "../../enum/userType";

interface IProps {
    user_id?: string,
    team_id?: string,
    company_id?: string,
    type?: string,
    page_size?: string,
    page_index?: string,
    isSaveHistory?: number | string,
    record?: any,
    id?: any;
    attach_invoice_files?: any,
    month?: Date | any,
    userId?: any;
    invoice_number?: Array<any>;
    total_amount_submitted?: any;
    total_days_in_the_invoice?: any;
    attach_your_detail_files?: Array<any>;
    isApproval?: number;
    project_id?: number;
    nature_of_services_provided?: string
}

export const getHistoryList = (parameter: IProps) => {
    let userStr: string | null = localStorage.getItem('userInfo');
    let user = null;
    if (typeof userStr === "string") {
        user = JSON.parse(userStr);
    }


    let data = {
        type: parameter.type,
        page_size: 10,
        page_index: parameter.page_index,
        user_id: null,
        team_id: null,
        company_id: null,
        isApproval: parameter.isApproval
    }

    // @ts-ignore
    if (user.role === Role.STAFF) {
        data.user_id = user.id
    }
    // @ts-ignore
    if (user.role === Role.CONTRACTOR) {
        data.user_id = user.id
    }

    // @ts-ignore
    if (user.role === Role.TEAM_LEADER) {
        data.team_id = user.detail_info.team_id
    }

    // @ts-ignore
    if (user.role === Role.AIC) {
        data.company_id = user.company_id
    }

    if (parameter.user_id) {
        // @ts-ignore
        data.user_id = parameter.user_id;
    }

    return Http.get('/api/v1/tasks/sbm/select', data);
}

export const submitDuration = (parameter: IProps) => {

    let data = {
        id: parameter.id,
        isSaveHistory: parameter.isSaveHistory,
        record: parameter.record
    }

    return Http.post('/api/v1/tasks/time/sheet/submit', data);
}

export const getHistory = (parameter: IProps) => {

    let data = {
        type: parameter.type
    }

    return Http.get('/api/v1/tasks/prev/history/submit', data)
}

export const submitExpense = (parameter: IProps) => {
    let data = {
        id: parameter.id,
        isSaveHistory: parameter.isSaveHistory,
        record: parameter.record,
        attach_invoice_files: parameter.attach_invoice_files
    }

    return Http.post('/api/v1/tasks/expense/submit', data);
}

export const submitInvoice = (parameter: IProps) => {
    let data = {
        month: parameter.month,
        user_id: parameter.userId,
        id: parameter.id,
        project_id: parameter.project_id,
        invoice_number: parameter.invoice_number,
        total_amount_submitted: parseInt(parameter.total_amount_submitted),
        total_days_in_the_invoice: parseInt(parameter.total_days_in_the_invoice),
        attach_your_detail_files: parameter.attach_your_detail_files,
        nature_of_services_provided: parameter.nature_of_services_provided
    }

    return Http.post('/api/v1/tasks/invoice/submit', data)
}
