import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    Button,
    Card,
    TextField,
    TableContainer,
    TableHead,
    TableRow,
    Divider
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { getProject } from '../setup/editProject/api';
import Updata from '../../componetns/update';
import { getExpense } from '../setup/editExpense/api';
import moment from 'moment'

import { getMoment } from '../../utils/tool';

const ExpenseApprovalTable = ({ date, data, msg, onSubmit, imgs, isEdit }: any) => {
    
    const [projects, setProjects] = useState([]);
    const [sum, setSum] = useState([0, 0, 0, 0, 0, 0, 0])
    const [message, setMessage] = useState('')
    const [expenses, setExpenses] = useState([]);
    const [imglist, setImgList]: any = useState([]);
    const [datas, setDatas] = useState([])
    
    useEffect(() => {
        loadData();
    }, [])
    
    useEffect(() => {
        deconstructionData();
        setImgList(imgs);
        setMessage(msg || '')
    }, [data])
    
    const deconstructionData = () => {
        let list: any = [];
        data.forEach((item: any, index: number) => {
            
            list.push({
                project_name: item.project_name,
                expense_name: item.expense_name,
                desc: item.desc
            })
            item.data.forEach((_d: any, _i: any) => {
                /*date.forEach((_item: any, _index: any)=>{
                    if (_d.date === _item){
                        list[index][_index] = _d.fee
                    }else{
                        list[index][_index] = 0
                    }
                })*/
                
                for (let i = 0; i < date.length; i++) {
                    if (_d.date === date[ i ]) {
                        list[ index ][ i ] = _d.fee;
                        break
                    }
                }
            })
            
            
            // list[index].desc = item.desc
            
            calculate(list);
            setDatas(list);
        })
    }
    
    const loadData = () => {
        getProjects();
    }
    
    const getProjects = async () => {
        let result: any = await getProject();
        
        if (result.code === 0) {
            setProjects(result.data);
        } else {
            //TODO 错误提示
        }
    }
    
    const calculate = (d: any) => {
        let arr = [0, 0, 0, 0, 0, 0, 0];
        d.forEach((item: any, index: number) => {
            new Array(7).fill('').forEach((_d: any, _i: any) => {
                arr[ _i ] += item[ _i ] ? item[ _i ] : 0;
            })
        })
        setSum(arr);
    }
    
    const submit = (type: boolean) => {
        onSubmit(type, message)
    }
    
    // @ts-ignore
    return (
        <Card className="approval-view-box">
            <div className="table-title">Expense Approval
                <span style={ {
                    paddingLeft: 20,
                    color: '#d315b3'
                } }>[ { getMoment(date[ 0 ]).format('MMMM YYYY') } ]</span>
            </div>
            <Divider />
            <TableContainer style={ { width: '100%' } } component={ Paper }>
                <Table aria-label="simple table">
                    <TableHead style={ { width: '100%' } }>
                        <TableRow>
                            <TableCell align="left">input Your Project</TableCell>
                            <TableCell align="left">input Your Expense</TableCell>
                            <TableCell align="left">Desc</TableCell>
                            {
                                date.map((item: string, index: string | number | undefined) => {
                                    return <TableCell key={ index }
                                                      align="left">{ moment(item).format('MM/DD') }</TableCell>
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { datas.map((row: any, index: number) => (
                            <TableRow key={ index }>
                                <TableCell align="left">{ row.project_name }</TableCell>
                                <TableCell align="left">{ row.expense_name }</TableCell>
                                <TableCell align="left">{ row.desc }</TableCell>
                                <TableCell align="left">{ row[ '0' ] ? row[ '0' ] : '' }</TableCell>
                                <TableCell align="left">{ row[ '1' ] ? row[ '1' ] : '' }</TableCell>
                                <TableCell align="left">{ row[ '2' ] ? row[ '2' ] : '' }</TableCell>
                                <TableCell align="left">{ row[ '3' ] ? row[ '3' ] : '' }</TableCell>
                                <TableCell align="left">{ row[ '4' ] ? row[ '4' ] : '' }</TableCell>
                                <TableCell align="left">{ row[ '5' ] ? row[ '5' ] : '' }</TableCell>
                                <TableCell align="left">{ row[ '6' ] ? row[ '6' ] : '' }</TableCell>
                                <TableCell align="left">{ row[ '7' ] ? row[ '7' ] : '' }</TableCell>
                            </TableRow>
                        )) }
                        <TableRow>
                            <TableCell style={ { width: '220px' } } size={ 'small' } rowSpan={ 1 } />
                            <TableCell size={ 'small' } rowSpan={ 1 } />
                            <TableCell style={ { width: '200px' } } align="left">Total Hours</TableCell>
                            { sum.map((item, index) => {
                                return <TableCell key={ index } align="left">{ item ? item : '' }</TableCell>
                            }) }
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            
            <div style={ { marginTop: '10px' } }>
                <Updata lists={ imglist } disabled={ true } />
            </div>
            
            {
                <div className="return-message">
                    <div>Return Message:</div>
                    { !isEdit ? (
                        <TextField value={ message } onChange={ (e: any) => setMessage(e.target.value) } />
                    ) : (
                        <span style={ { paddingLeft: 20 } }>{ message }</span>
                    ) }
                </div>
            }
            {
                !isEdit && (
                    <div className="submit">
                        <Button variant="contained" onClick={ () => submit(true) } color="primary">Approve</Button>
                        <Button variant="contained" onClick={ () => submit(false) }>Return</Button>
                    </div>
                )
            }
        </Card>
    )
}

export default ExpenseApprovalTable
