import Http from "../../utils/axios"

interface IProps {
    contract_expire_date?: string;
    old_password?: string;
    account?: string;
    password?: string;
    retype_password?: string;
    method?: number;
    address?:string;
    address_two?: string;
    city?: string;
    state?:string;
    zip?:string;
    rate_information?: string;
    language?:string;
    highest_degree?:string;
    graduation?:string;
    certification?:string;
    name?: string,
    email?:string;
    phone?:string;
    company_name?:string
    company_code?:string
}

export const UpdateContractor = (parameter: IProps) => {
    let data = {
        contract_expire_date: parameter.contract_expire_date
    }
    return Http.post('/api/v1/account/contractor/update/profile', data)
}

export const changePassword = (parameter: IProps) => {
    let data = {
        old_password: parameter.old_password,
        account: parameter.account,
        password: parameter.password,
        retype_password: parameter.retype_password
    }
    return Http.post('/api/v1/account/reset/passwrod/by/old',data)
}

export const UpdatePayment = (parameter: IProps) => {
    let data = {
        method: parameter.method,
        address: parameter.address,
        address_two: parameter.address_two,
        city: parameter.city,
        state: parameter.state,
        zip: parameter.zip,
        rate_information: parameter.rate_information,
    }
    return Http.post('/api/v1/account/contractor/update/profile', data)
}

export const createOptional = (parameter:IProps)=>{
    let data = {
        language:parameter.language,
        highest_degree:parameter.highest_degree,
        graduation:parameter.graduation,
        certification:parameter.certification
    }

    return Http.post('/api/v1/account/staff/update/profile',data)
}

export const editAic = (parameter:IProps)=>{
    let data = {
        name: parameter.name,
        email: parameter.email,
        phone: parameter.phone,
        company_name: parameter.company_name,
        company_code: parameter.company_code,
    }

    return Http.post('/api/v1/account/aic/update/profile',data)
}
