import React from "react";
import {Backdrop, CircularProgress} from "@material-ui/core";
import {observer} from "mobx-react";
import {useStartStore} from "../../store";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: 9999999,
            color: '#fff',
        },
    }),
);


const Loading = ()=>{
    const classes = useStyles();
    const {load} = useStartStore()
    return (
        <Backdrop open={load} className={classes.backdrop}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default observer(Loading)
