import React, { useEffect, useState } from 'react';
import {
    Divider,
    TableContainer,
    Card,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tab,
    Badge,
    Tabs, Button
} from '@material-ui/core';
import './index.less';
import Paper from '@material-ui/core/Paper';
import TimeApprovalTable from './TimeApprovalTable';
import ExpenseApprovalTable from './expenseApprovalTable';
import ExpenseApprovalInput from './ExpenseTablFullInput';
import InvoiceApprovalTable from './invoiceApprovalTable';
import TimeApprovalInput from './TimeTableFullInput';
import Pagination from '@material-ui/lab/Pagination';
import { ESbmTimeSheetStatus, ETaskType, Role } from '../../enum/userType';
import { getHistoryList, submitDuration, submitExpense } from '../submission/api';
import { useStartStore } from '../../store';
import { observer } from 'mobx-react';
import { AuditRecord, GetUnfinished } from './api';
import IProps from '../../interface/iprops';
import { getMoment } from '../../utils/tool';
import { getProject } from '../setup/editProject/api';
import { getExpense } from '../setup/editExpense/api';
import { selectEmployee, sendEmail } from '../setup/editTeamMember/api';
import moment from 'moment'

const Approval = ({ history }: IProps) => {
    // @ts-ignore
    let role: any = JSON.parse(localStorage.getItem('userInfo'));
    
    const { success, error, warning, showLoad, hideLoad } = useStartStore();
    
    const [date, setDate]: any = useState([]);
    const [pageIndex, setPageIndex]: any = useState(1);
    const [total, setTotal] = useState(1);
    const [valueTable, setValueTable] = useState(1);
    const [historys, setHistorys] = useState([]);
    const [submitId, setSubmitId] = useState(0);
    const [times, setTimes] = useState([]);
    const [isTime, setIsTime] = useState(false);
    const [expense, setExpense] = useState([]);
    const [isExpense, setIsExpense] = useState(false);
    const [imgs, setImgs] = useState([]);
    const [invoice, setInvoice] = useState([]);
    const [isInvoice, setIsInvoice] = useState(false);
    const [edit, setEdit]: any = useState(false);
    const [timeSheet, setTimeSheet] = useState(false);
    const [expenses, setExpenses] = useState(false);
    const [invoices, setInvoices] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [projects, setProjects] = useState([]);
    const [message, setMessage] = useState('');
    const [type, setType] = useState(-1);
    const [timeTaskCount, setTimeTaskCount] = useState(0);
    const [expenseTaskCount, setExpenseTaskCount] = useState(0);
    const [invoiceTaskCount, setInvoiceTaskCount] = useState(0);
    const [invoiceNoAccountTaskCount, setInvoiceNoAccountTaskCount] = useState(0);
    const [expenseList, setExpenseList] = useState([]);
    const [employee, setEmployee] = useState([]);
    
    
    useEffect(() => {
        getFiveDays(new Date());
        getEmployee();
        getProjects();
        getExpenseList();
        
        let list: any = role.detail_info.ability_to_approve ? role.detail_info.ability_to_approve.split(',') : [];
        
        list.forEach((item: string) => {
            
            switch (item) {
                case '1':
                    setTimeSheet(true);
                    break;
                case '2':
                    setExpenses(true);
                    break;
                case '3':
                    setInvoices(true);
                    break;
            }
        });
        
        if (list[ 0 ] && role.role === Role.TEAM_LEADER) {
            historyList(list[ 0 ]);
        }
        
        if (role.role === Role.AIC) {
            historyList(ETaskType.TIME_SHEET);
        }
        
        switch (role.role) {
            case Role.STAFF:
                history.goBack();
                break;
        }
        
    }, []);
    
    const getEmployee = async () => {
        showLoad();
        
        const userInfo = JSON.parse(localStorage.getItem('userInfo') as string);
        let team_id = '';
        
        if (userInfo.role === Role.TEAM_LEADER) {
            team_id = userInfo.team_info.id;
        }
        
        let data: any = await selectEmployee(team_id);
        
        if (data.code === 0) {
            setEmployee(data.data.data);
        }
        hideLoad();
    };
    
    const getNextDate = (date: string | number | Date, day: number) => {//data基准时间的时间戳，day:第几天
        var dd = new Date(date);
        dd.setDate(dd.getDate() + day);
        var y = dd.getFullYear();
        var m = dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1;
        var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
        return y + '-' + m + '-' + d;
    };
    
    const getExpenseList = async () => {
        let result: any = await getExpense();
        if (result.code === 0) {
            setExpenseList(result.data);
        }
    };
    
    const getProjects = async () => {
        
        
        let result: any = await getProject();
        
        if (result.code === 0) {
            setProjects(result.data);
        } else {
            error(result.message);
        }
        
    };
    
    const getFiveDays = (e: string | number | Date) => {
        let days: string[] = [];
        
        
        var startDay = getMoment(e).startOf('isoWeek');//计算指定日期是这周第几天
        
        days[ 0 ] = getMoment(startDay).format('YYYY-MM-DD');
        
        //截取前后5天的时间
        new Array(6).fill('').map((item, index) => {
            days[ index + 1 ] = getMoment(startDay).add(index + 1, 'day').format('YYYY-MM-DD');
        });
        
        
        setDate(days);
        //设置列表的时间段
        
    };
    
    const getUnfinishedCount = async () => {
        const result: any = await GetUnfinished();
        
        if (result.code === 0) {
            setTimeTaskCount(result.data.timeSheet || 0);
            setExpenseTaskCount(result.data.expense || 0);
            setInvoiceTaskCount(result.data.invoice || 0);
            setInvoiceNoAccountTaskCount(result.data.noAccountInvoice || 0);
        }
    };
    
    const historyList = async (type?: string | number, index?: any) => {
        showLoad();
        let data = {
            isApproval: 1,
            team_id: '1',
            page_index: index ? index : String(pageIndex),
            type: type ? String(type) : '1'
        };
        let result: any = await getHistoryList(data);
        
        hideLoad();
        if (result.code === 0) {
            setHistorys(result.data.data || []);
            
            setTotal(Math.ceil(result.data.total / 10));
            // setHistoryTotal(result.data.total / 10);
        } else {
        }
        
        getUnfinishedCount();
    };
    
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setHistorys([]);
        setValueTable(newValue);
        historyList(newValue, 1);
        setPageIndex(1);
    };
    
    const editTime = (data: any, isEdit?: boolean) => {
        setIsShow(!!isEdit);
        
        switch (valueTable) {
            case 1:
                setSubmitId(data.id);
                setTimes(isEdit ? data.record : data);
                setMessage(data.approve_message || '');
                setIsTime(true);
                setIsExpense(false);
                setIsInvoice(false);
                setType(1);
                
                setEdit(isEdit);
                
                getFiveDays(data.record[ 0 ].data[ 0 ].date);
                break;
            case 2:
                setSubmitId(data.id);
                setExpense(data.record);
                setImgs(data.attach_invoice_files);
                setIsExpense(true);
                setIsTime(false);
                setIsInvoice(false);
                setMessage(data.approve_message || '');
                setEdit(isEdit);
                setType(2);
                getFiveDays(getMoment(data.record[ 0 ].data[ 0 ].date).format('YYYY-MM-DD'));
                break;
            case 3:
                setSubmitId(data.id);
                setInvoice(data);
                setIsExpense(false);
                setIsTime(false);
                setEdit(isEdit);
                setIsInvoice(true);
                setType(3);
                break;
            case 4:
                setSubmitId(data.id);
                setInvoice(data);
                setIsExpense(false);
                setIsTime(false);
                setEdit(isEdit);
                setIsInvoice(true);
                setType(4);
                break;
        }
    };
    
    const setPage = (e: any, page: any) => {
        setPageIndex(page);
        historyList(valueTable, page);
    };
    
    const Audit = async (status: boolean, message?: string | null) => {
        showLoad();
        if (status) {
            let data = {
                id: submitId,
                type: valueTable,
                return_message: message || '',
                status: ESbmTimeSheetStatus.APPROVED
            };
            
            let result: any = await AuditRecord(data);
            if (result.code === 0) {
                success(result.message);
                historyList(valueTable, pageIndex);
                close();
            } else {
                error(result.message);
            }
        } else {
            if (type !== 4 && !message) {
                warning('Please enter rejection information');
                hideLoad();
                return;
            }
            let data: any = {
                id: submitId,
                type: valueTable,
                status: type === 4 ? ESbmTimeSheetStatus.INVALID : ESbmTimeSheetStatus.REJECTED,
                return_message: message
            };
            
            let result: any = await AuditRecord(data);
            if (result.code === 0) {
                
                success(result.message);
                historyList(valueTable, pageIndex);
                close();
            } else {
                error(result.message);
            }
        }
        hideLoad();
    };
    
    const close = () => {
        setIsExpense(false);
        setIsInvoice(false);
        setIsTime(false);
    };
    
    const getTab = (str: string, value = 5) => {
        return (
            <Badge badgeContent={ value } color="secondary">
                <span>{ str }</span>
            </Badge>
        );
    };
    const onSendEmail = async (team: any) => {
        showLoad();
        
        const result: any = await sendEmail(team.user_id);
        
        hideLoad();
        
        if (result.code === 0) {
            success('Successfully send');
            return;
        }
        error('Failed to send');
    };
    
    const sumbitTime = async (e: Array<any> | any, clearCb?: Function) => {
        showLoad();
        let data = {
            // @ts-ignore
            id: submitId,
            isSaveHistory: 0,
            record: e
        };
        
        let result: any = await submitDuration(data);
        
        if (result.code === 0) {
            success(result.message);
            historyList();
            clearCb && clearCb();
            
            
            setSubmitId(0);
            setTimes([]);
            setMessage('');
            setIsTime(false);
            setIsExpense(false);
            setIsInvoice(false);
        } else {
            error(result.message);
        }
        
        hideLoad();
    };
    
    const sumbitExoense = async (e: Array<any> | any, imgs: Array<any>, clearCb?: Function) => {
        showLoad();
        let data = {
            id: submitId,
            isSaveHistory: 0,
            record: e,
            attach_invoice_files: imgs
        };
        
        let result: any = await submitExpense(data);
        
        if (result.code === 0) {
            success(result.message);
            
            
            setSubmitId(0);
            setTimes([]);
            setMessage('');
            setIsTime(false);
            setIsExpense(false);
            setIsInvoice(false);
            
            historyList(valueTable);
            setTimes([]);
            clearCb && clearCb();
        } else {
            error(result.message);
        }
        
        hideLoad();
    };
    return (
        <div className="approval-view">
            <div className="title">Approval</div>
            <Divider />
            <div className="approval-content">
                <Card className="approval-content-left">
                    <div className="table-title">Record Approval</div>
                    <Divider />
                    
                    <Tabs
                        value={ valueTable }
                        indicatorColor="primary"
                        textColor="primary"
                        style={ { background: '#FFF' } }
                        onChange={ handleChange }
                        aria-label="disabled tabs example"
                    >
                        
                        { role.role === Role.TEAM_LEADER && timeSheet && (
                            <Tab key={ ETaskType.TIME_SHEET }
                                 value={ ETaskType.TIME_SHEET }
                                 label={ getTab('Time sheet', timeTaskCount) } />) }
                        { role.role === Role.TEAM_LEADER && expenses && (
                            <Tab key={ ETaskType.EXPENSE }
                                 value={ ETaskType.EXPENSE }
                                 label={ getTab('Expense', expenseTaskCount) } />) }
                        { role.role === Role.TEAM_LEADER && invoices && (
                            <Tab key={ ETaskType.INVOICE }
                                 value={ ETaskType.INVOICE }
                                 label={ getTab('Invoice', invoiceTaskCount) } />) }
                        
                        {/*{role.role === Role.STAFF && (
                            <Tab key={ETaskType.TIME_SHEET} value={ETaskType.TIME_SHEET} label="Time sheet"/>)}
                        {role.role === Role.CONTRACTOR && (
                            <Tab key={ETaskType.TIME_SHEET} value={ETaskType.TIME_SHEET} label="Time sheet"/>)}
                        {role.role === Role.STAFF && (
                            <Tab key={ETaskType.EXPENSE} value={ETaskType.EXPENSE} label="Expense"/>)}
                        {role.role === Role.CONTRACTOR && (
                            <Tab key={ETaskType.INVOICE} value={ETaskType.INVOICE} label="Invoice"/>)}*/ }
                        
                        { role.role === Role.AIC && (
                            <Tab key={ ETaskType.TIME_SHEET }
                                 value={ ETaskType.TIME_SHEET }
                                 label={ getTab('Time sheet', timeTaskCount) } />) }/>
                        )}
                        { role.role === Role.AIC && (
                            <Tab key={ ETaskType.EXPENSE } value={ ETaskType.EXPENSE }
                                 label={ getTab('Expense', expenseTaskCount) } />) }
                        />
                        )}
                        { role.role === Role.AIC && (
                            <Tab key={ ETaskType.INVOICE }
                                 value={ ETaskType.INVOICE }
                                 label={ getTab('Invoice', invoiceTaskCount) } />) }/>)}
                        
                        { role.role === Role.AIC && (
                            <Tab key={ ETaskType.NO_ACCOUNT_INVOICE }
                                 value={ ETaskType.NO_ACCOUNT_INVOICE }
                                 label={ getTab('No Account Invoice', invoiceNoAccountTaskCount) } />) }/>)}
                        
                        <Tab key={ 'Reminder' }
                             value={ 9999 }
                             label={ 'Reminder' } />
                    </Tabs>
                    
                    <TableContainer style={ { width: '560px' } } component={ Paper }>
                        <Table aria-label="simple table">
                            <TableHead style={ { width: '100%' } }>
                                <TableRow>
                                    <TableCell align="left">{ valueTable === 999 ? 'User Name' : valueTable === 4 ? 'Start Week / User Name / Email ' : 'Start Week / User Name' }</TableCell>
                                    { valueTable !== 9999 ?
                                        <TableCell align="left">{ 'Submit Time' }</TableCell> : null }
                                    <TableCell align="left">{ valueTable === 999 ? 'Operaction' : 'Status' }</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { valueTable === 9999 ?
                                    employee.map((item: any, index: number) => {
                                        return (
                                            <TableRow key={ index }>
                                                <TableCell align="left">
                                                    <span style={ { color: '#029589' } }>{ item.name } - { item.employee_num }</span>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Button color="default" onClick={ () => {
                                                        onSendEmail(item);
                                                    } }>SEND EMAIL</Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                    :
                                    historys.map((row: any, index) => {
                                        let mmt = '';
                                        switch (valueTable) {
                                            case ETaskType.INVOICE:
                                            case ETaskType.NO_ACCOUNT_INVOICE:
                                                mmt = getMoment(row.month).format('MMMM YYYY');
                                                break;
                                            case ETaskType.TIME_SHEET:
                                            case ETaskType.EXPENSE:
                                                mmt = getMoment(row.date || row.record[ 0 ].date || row.record[ 0 ].data[ 0 ].date).startOf('isoWeek').format('MMMM DD, YYYY');
                                                break;
                                        }
                                        
                                        return (
                                            <TableRow key={ index } selected={ row.id === submitId }>
                                                <TableCell align="left">{ mmt } / <span
                                                    style={ { color: '#029589' } }>{ row.user_name || row.name }<span style={{color:'#06308e'}}>{ valueTable === 4 ? ` / ${ row.address }` : '' }</span></span></TableCell>
                                                <TableCell align="left"><span
                                                    style={ { color: '#505050' } }>{ moment(row.gmt_create).format('YYYY-MM-DD HH:mm:ss') }</span></TableCell>
                                                <TableCell align="left">
                                                    { row.status === 3 ? //Subitted
                                                        <Button onClick={ () => editTime(row) }
                                                                color="primary">{ ESbmTimeSheetStatus[ row.status ] }</Button> :
                                                        (row.status === 4 || row.status === 6) ? // REJECTED
                                                            <Button style={ { color: '#ec4b57' } }
                                                                    onClick={ () => editTime(row, true) }>{ ESbmTimeSheetStatus[ row.status ] }</Button> :
                                                            <Button onClick={ () => editTime(row, true) }
                                                                    style={ { color: '#1ba95b' } }>{ ESbmTimeSheetStatus[ row.status ] }</Button>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="table-page">
                        <Pagination page={ pageIndex } onChange={ setPage } count={ total } size="small" />
                    </div>
                </Card>
                <div style={ { flex: 1 } }>
                    {
                        isTime ? (
                            isShow || role.role !== Role.AIC ? (
                                <TimeApprovalTable data={ times }
                                                   isEdit={ edit }
                                                   msg={ message }
                                                   onSubmit={ Audit }
                                                   date={ date } />
                            ) : (
                                <TimeApprovalInput projects={ projects }
                                                   data={ times }
                                                   isAdd={ edit }
                                                   onSumbit={ Audit }
                                                   onSaveSubmit={ sumbitTime }
                                                   date={ date } />
                            )
                        ) : null
                    }
                    
                    <div style={ { marginTop: '10px' } }></div>
                    {
                        isExpense ? (
                            isShow || role.role !== Role.AIC ? (
                                <ExpenseApprovalTable data={ expense }
                                                      isEdit={ edit }
                                                      onSubmit={ Audit }
                                                      imgs={ imgs }
                                                      msg={ message }
                                                      date={ date } />) : (
                                <ExpenseApprovalInput data={ expense }
                                                      projects={ projects }
                                                      isEdit={ edit }
                                                      expenses={ expenseList }
                                                      onSumbit={ Audit }
                                                      onSaveSubmit={ sumbitExoense }
                                                      imgs={ imgs }
                                                      date={ date } />
                            )
                        ) : null
                    }
                    
                    <div style={ { marginTop: '10px' } }></div>
                    { isInvoice && (
                        <InvoiceApprovalTable data={ invoice } isEdit={ edit } type={ type } onSubmit={ Audit } />) }
                </div>
            </div>
        </div>
    );
};

export default observer(Approval);
