import React, { useEffect, useState } from 'react';
import './index.less'
import {
    AddTeam,
    DeleteTeam,
    saveEmployeeInfo,
    sendEmail,
    selectEmployee,
    TeamList,
    uploadImg,
    deleteAccount
} from './api';
import Tooltip from '@material-ui/core/Tooltip';
import { useForm } from 'react-hook-form';
import Switch from '@material-ui/core/Switch';
import {
    FormControl,
    InputLabel,
    Select,
    Button,
    Fab,
    Checkbox,
    Divider,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    
    Chip,
    Avatar, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import LineStyleIcon from '@material-ui/icons/LineStyle';
import { AlertForm } from '../../../componetns/alert';
import Input from '../../../componetns/input';
import { observer } from 'mobx-react';
import { useStartStore } from '../../../store';
import Updata from '../../../componetns/update';
import { Role } from '../../../enum/userType';
import { getMoment } from '../../../utils/tool';


interface Team {
    company_id: number;
    gmt_create: string;
    gmt_modified: null;
    id: number;
    name: string;
    status: number;
}

interface ImgList {
    path: string;
    name: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            width: '100%',
            marginTop: 14
        },
        selectEmpty: {
            marginTop: theme.spacing(20)
        }
    })
);

const EditTeamMemberForm = ({ team, getEmployee, setUserInfo, user }: any) => {
    
    const { register, handleSubmit, setValue, errors } = useForm();
    const { success, error, showLoad, hideLoad } = useStartStore();
    
    const classes = useStyles();
    const [checkedHeight, setCheckedHeight] = useState(0);
    const [uploadList, setUploadList]: any = useState([])
    const [uploadLoading, setUploadLoading]: any = useState(false)
    const [imgArr, setImgArr]: any = useState([]);
    const [roleSelect, setRoleSelect]: any = useState(3);
    const [workStatus, setWorkStatus]: any = useState(0);
    const [timeSheets, setTimeSheets] = useState(false);
    const [expense, setExpense] = useState(false);
    const [invoice, setInvoice] = useState(false);
    const [visaExpirationCheck, setVisaExpirationCheck] = useState(false);
    const [uploadI9List, setUploadI9List]: any = useState([])
    const [uploadOtherList, setUploadOtherList]: any = useState([])
    const [uploadEmpList, setUploadEmpList]: any = useState([])
    const [accountType, setAccountType]: any = useState('')
    const [personalOrBusiness, setPersonalOrBusiness]: any = useState('')
    
    
    useEffect(() => {
        user.role === 2 ? setCheckedHeight(340) : setCheckedHeight(0)
        setRoleSelect(user.role)
        setWorkStatus(user.work_status ? user.work_status : 0)
        setTimeSheets(false);
        setExpense(false);
        setInvoice(false);
        let list = user.ability_to_approve && user.ability_to_approve.split(',');
        setUploadList(user.w4_on_file || []);
        setUploadI9List(user.i9_on_file || []);
        setUploadOtherList(user.other_on_file || []);
        setUploadEmpList(user.emp_on_file || []);
        setAccountType(user.account_type || '');
        setPersonalOrBusiness(user.personal_or_business || '');
        setVisaExpiration(!!user.visa_expiration);
        
        
        list && list.forEach((item: string) => {
            switch (item) {
                case '1':
                    setTimeSheets(true);
                    break;
                case '2':
                    setExpense(true);
                    break;
                case '3':
                    setInvoice(true);
                    break;
            }
        })
    }, [user])
    
    const setVisaExpiration = (check: boolean) => {
        if (check) {
            if (user.visa_expiration) {
                setValue('visa_expiration', getMoment(user.visa_expiration).format('YYYY-MM-DD'))
            } else {
                setValue('visa_expiration', getMoment().format('YYYY-MM-DD'))
            }
        } else {
            setValue('visa_expiration', getMoment().format('YYYY-MM-DD'));
        }
        
        setVisaExpirationCheck(check);
    }
    
    const submit = async (e: any) => {
        showLoad();
        let data = { ...e };
        data.role = roleSelect + '';
        data.ability_to_approve = `${ timeSheets ? '1,' : '' }${ expense ? '2,' : '' }${ invoice ? '3' : '' }`//这个字段需要修改
        data.w4_on_file = uploadList;
        data.i9_on_file = uploadI9List;
        data.other_on_file = uploadOtherList;
        data.emp_on_file = uploadEmpList;
        data.user_id = user.user_id;
        data.work_email = user.work_email ? user.work_email : '';
        data.account_type = accountType;
        data.personal_or_business = personalOrBusiness;
        
        if (visaExpirationCheck) {
            data.visa_expiration = '';
        }
        
        let result: any = await saveEmployeeInfo(data);
        if (result.code === 0) {
            success(result.message);
            getEmployee();
        } else {
            error(result.message);
        }
        hideLoad();
    }
    
    const select = (e: any) => {
        setRoleSelect(e.target.value)
        if (e.target.value === '2') {
            setCheckedHeight(340)
        } else {
            setCheckedHeight(0)
        }
    }
    
    const drop = async (event: any) => {
        let list: Array<string> = [...uploadList];
        list.unshift(event.target.files[ 0 ].name);
        setUploadList(list);
        setUploadLoading(true);
        let data: any = await uploadImg(event.target.files[ 0 ]);
        if (data.code === 0) {
            setUploadLoading(false);
            let img: Array<ImgList> = [...imgArr];
            img.push({ path: data.data.path, name: data.data.name })
            setImgArr(img);
        }
    }
    
    const deleteImg = (index: number) => {
        let lists: any = [...uploadList];
        lists.splice(index, 1);
        setUploadList(lists);
    }
    
    return (
        <form onSubmit={ handleSubmit(submit) }>
            <div className="form">
                <div className="form-left">
                    <div className="title">
                        <span className="title-box"> <span>{ user.name } ID:{ user.employee_num }</span></span>
                    </div>
                    
                    <FormControl size={ 'small' } variant="outlined" className={ classes.formControl }>
                        <InputLabel htmlFor="outlined-age-native-simple"
                                    style={ { background: '#fafafa', paddingRight: '10px' } }>Team</InputLabel>
                        <Select
                            native
                            inputRef={ register() }
                            label="Team"
                            inputProps={ {
                                name: 'team_id',
                                id: 'outlined-age-native-simple'
                            } }
                            value={ user.team_id }
                            onChange={ (e) => setUserInfo({ team_id: e.target.value }) }
                        >
                            { team.map((item: Team, index: number) => {
                                return (
                                    <option key={ index } value={ item.id }>{ item.name }</option>
                                )
                            }) }
                        </Select>
                    </FormControl>
                    
                    <TextField inputRef={ register({
                        required: { value: false, message: 'Please type in your name' }
                    }) }
                               error={ Boolean(errors.job_title) }
                               variant="outlined"
                               margin="normal"
                               fullWidth
                               defaultValue={ user.job_title }
                               size={ 'small' }
                               id="name"
                               label="Job Title"
                               name="job_title"
                               helperText={
                                   errors.job_title && errors.job_title.message
                               }
                    />
                    
                    <TextField inputRef={ register({
                        required: { value: false, message: 'Please type in your name' }
                    }) }
                               error={ Boolean(errors.supervisor) }
                               variant="outlined"
                               margin="normal"
                               fullWidth
                               defaultValue={ user.supervisor }
                               size={ 'small' }
                               id="name"
                               label="Supervisor Name"
                               name="supervisor"
                               helperText={
                                   errors.supervisor && errors.supervisor.message
                               }
                    />
                    
                    <TextField inputRef={ register({
                        required: { value: false, message: 'Please type in your name' }
                    }) }
                               error={ Boolean(errors.start_date) }
                               variant="outlined"
                               margin="normal"
                               fullWidth
                               size={ 'small' }
                               id="name"
                               type="date"
                               defaultValue={ getMoment(user.start_date ? user.start_date : new Date()).format('YYYY-MM-DD') }
                               label="Start Date"
                               name="start_date"
                               helperText={
                                   errors.start_date && errors.start_date.message
                               }
                    />
                    
                    <TextField inputRef={ register({
                        required: { value: false, message: 'Please type in your name' }
                    }) }
                               error={ Boolean(errors.birthday) }
                               variant="outlined"
                               margin="normal"
                               fullWidth
                               size={ 'small' }
                               id="name"
                               type="date"
                               defaultValue={ getMoment(user.birthday ? user.birthday : new Date()).format('YYYY-MM-DD') }
                               label="Birthday Date"
                               name="birthday"
                               helperText={
                                   errors.birthday && errors.birthday.message
                               }
                    />
                    
                    <FormControl size={ 'small' } variant="outlined" className={ classes.formControl }>
                        <InputLabel htmlFor="outlined-age-native-simple"
                                    style={ { background: '#fafafa', paddingRight: '10px' } }>Status</InputLabel>
                        <Select
                            native
                            inputRef={ register() }
                            label={ `Status` }
                            onChange={ (e: any) => setWorkStatus(e.target.value) }
                            inputProps={ {
                                name: 'work_status',
                                id: 'outlined-age-native-simple'
                            } }
                            value={ workStatus }
                        >
                            <option style={ { color: '#f9f9f9' } } value={ 0 }>Please select status</option>
                            <option value={ 1 }>Full time</option>
                            <option value={ 2 }>Part time</option>
                        </Select>
                    </FormControl>
                    <TextField disabled={ true } inputRef={ register({
                        required: { value: false, message: 'Please enter work email' }
                    }) }
                               error={ Boolean(errors.work_email) }
                               variant="outlined"
                               margin="normal"
                               fullWidth
                               size={ 'small' }
                               id="name"
                               defaultValue={ user.work_email }
                               label="Work Email"
                               name="work_email"
                               helperText={
                                   errors.work_email && errors.work_email.message
                               }
                    />
                    
                    <TextField inputRef={ register({
                        required: { value: false, message: 'Please type in your name' }
                    }) }
                               error={ Boolean(errors.hourly_rate) }
                               variant="outlined"
                               margin="normal"
                               fullWidth
                               size={ 'small' }
                               id="name"
                               defaultValue={ user.hourly_rate }
                               label="Hourly rate"
                               name="hourly_rate"
                               helperText={
                                   errors.hourly_rate && errors.hourly_rate.message
                               }
                    />
                    
                    <TextField inputRef={ register({
                        required: { value: false, message: 'Please type in your name' }
                    }) }
                               error={ Boolean(errors.supervisor) }
                               variant="outlined"
                               margin="normal"
                               fullWidth
                               size={ 'small' }
                               id="name"
                               label="Phone"
                               defaultValue={ user.phone }
                               name="phone"
                               helperText={
                                   errors.supervisor && errors.supervisor.message
                               }
                    />
                    <div className="title" style={ { margin: '20px 0' } }>
                        <span className="title-box">Bank Information</span>
                    </div>
                    <div className="m-col-radio">
                        <span>Account Type：</span>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio"
                            name="row-radio-buttons-group"
                            onChange={ ev => setAccountType(ev.target.value) }
                            value={ accountType }
                        >
                            <FormControlLabel value="1" control={ <Radio /> } label="Checking" />
                            <FormControlLabel value="2" control={ <Radio /> } label="Savings" />
                        </RadioGroup>
                    </div>
                    <TextField
                        inputRef={ register() }
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        size={ 'small' }
                        id="routing_number"
                        label="Routing Number"
                        defaultValue={ user.routing_number }
                        name="routing_number"
                    />
                    <TextField
                        inputRef={ register() }
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        size={ 'small' }
                        id="account_number"
                        label="Account Number"
                        defaultValue={ user.account_number }
                        name="account_number"
                    />
                    <div className="m-col-radio">
                        <span>Personal Or Business：</span>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio"
                            name="row-radio-buttons-group"
                            onChange={ ev => setPersonalOrBusiness(ev.target.value) }
                            value={ personalOrBusiness }
                        >
                            <FormControlLabel value="1" control={ <Radio /> } label="Personal" />
                            <FormControlLabel value="2" control={ <Radio /> } label="Business" />
                        </RadioGroup>
                    </div>
                </div>
                <div className="form-right">
                    <div>
                        <div className="title" style={ { marginBottom: '20px' } }><span className="title-box">Insurance and Benefit</span>
                        </div>
                        <TextField inputRef={ register({
                            required: { value: false, message: 'Please type in your name' }
                        }) }
                                   error={ Boolean(errors.insurance) }
                                   variant="outlined"
                                   margin="normal"
                                   fullWidth
                                   size={ 'small' }
                                   id="name"
                                   defaultValue={ user.insurance }
                                   label="Medical"
                                   name="insurance"
                                   helperText={
                                       errors.insurance && errors.insurance.message
                                   }
                        />
                        
                        <TextField inputRef={ register({
                            required: { value: false, message: 'Please type in your name' }
                        }) }
                                   error={ Boolean(errors.additional_insured) }
                                   variant="outlined"
                                   margin="normal"
                                   fullWidth
                                   size={ 'small' }
                                   id="name"
                                   defaultValue={ user.additional_insured }
                                   label="Other"
                                   name="additional_insured"
                                   helperText={
                                       errors.additional_insured && errors.additional_insured.message
                                   }
                        />
                        
                        <TextField inputRef={ register({
                            required: { value: false, message: 'Please type in your name' }
                        }) }
                                   error={ Boolean(errors.dental) }
                                   variant="outlined"
                                   margin="normal"
                                   fullWidth
                                   size={ 'small' }
                                   id="name"
                                   defaultValue={ user.dental }
                                   label="Dental"
                                   name="dental"
                                   helperText={
                                       errors.dental && errors.dental.message
                                   }
                        />
                        
                        <TextField inputRef={ register({
                            required: { value: false, message: 'Please type in your name' }
                        }) }
                                   error={ Boolean(errors.vision) }
                                   variant="outlined"
                                   margin="normal"
                                   fullWidth
                                   size={ 'small' }
                                   id="name"
                                   label="Vision"
                                   defaultValue={ user.vision }
                                   name="vision"
                                   helperText={
                                       errors.vision && errors.vision.message
                                   }
                        />
                        
                        <div className="cutover">
                            <FormControl size={ 'small' } style={ { flex: 1 } } variant="outlined">
                                <InputLabel htmlFor="outlined-age-native-simple"
                                            style={ {
                                                background: '#fafafa',
                                                paddingRight: '10px'
                                            } }>Employee Role Type</InputLabel>
                                <Select
                                    native
                                    inputRef={ register() }
                                    onChange={ select }
                                    inputProps={ {
                                        name: 'role',
                                        id: 'outlined-age-native-simple'
                                    } }
                                    value={ roleSelect }
                                >
                                    <option value={ 2 }>{ Role[ 2 ] }</option>
                                    <option value={ 3 }>{ Role[ 3 ] }</option>
                                    <option value={ 4 }>{ Role[ 4 ] }</option>
                                </Select>
                            </FormControl>
                            
                            <div className="cutover-box" style={ { width: `${ checkedHeight }px` } }>
                                <div className="cutover-checkbox"><Checkbox inputRef={ register() }
                                                                            color="primary"
                                                                            checked={ timeSheets }
                                                                            onChange={ (e: any) => setTimeSheets(e.target.checked) }
                                                                            name="Time_Sheet" />Time Sheet
                                </div>
                                <div className="cutover-checkbox"><Checkbox inputRef={ register() }
                                                                            color="primary"
                                                                            checked={ expense }
                                                                            onChange={ (e: any) => setExpense(e.target.checked) }
                                                                            name="Invoice" />Expense
                                </div>
                                <div className="cutover-checkbox"><Checkbox inputRef={ register() }
                                                                            color="primary"
                                                                            checked={ invoice }
                                                                            onChange={ (e: any) => setInvoice(e.target.checked) }
                                                                            name="Invoice" />Invoice
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div style={ { marginTop: '30px' } }>
                        <div className="title"><span className="title-box">Legal Status</span></div>
                        <TextField inputRef={ register({
                            required: { value: false, message: 'Please type in your name' }
                        }) }
                                   error={ Boolean(errors.legal_status) }
                                   variant="outlined"
                                   margin="normal"
                                   fullWidth
                                   size={ 'small' }
                                   id="name"
                                   label="Legal Status"
                                   defaultValue={ user.legal_status }
                                   name="legal_status"
                                   helperText={
                                       errors.legal_status && errors.legal_status.message
                                   }
                        />
                        
                        <div className="m-date-clear-box">
                            <span style={ { display: 'inline-block', width: 100 } }>Not Applicable:</span>
                            <Switch
                                checked={ visaExpirationCheck }
                                onChange={ e => setVisaExpiration(e.target.checked) }
                                color="primary"
                            />
                            <TextField style={ { flex: 1 } } inputRef={ register({
                                required: { value: false, message: 'Please type in your name' }
                            }) }
                                       disabled={ visaExpirationCheck }
                                       error={ Boolean(errors.visa_expiration) }
                                       variant="outlined"
                                       margin="normal"
                                       size={ 'small' }
                                       id="visa_expiration"
                                       type="date"
                                       defaultValue={ user.visa_expiration }
                                       label="Visa Expiration"
                                       name="visa_expiration"
                                       helperText={
                                           errors.visa_expiration && errors.visa_expiration.message
                                       }
                            />
                        </div>
                    
                    </div>
                    
                    <div className="upload-box" style={ { width: '500px', marginTop: 10, marginBottom: 25 } }>
                        <div style={ { marginRight: '10px', lineHeight: '40px' } }>W4 On File：</div>
                        <Updata onChange={ (e: any) => {
                            setUploadList(e)
                        } } lists={ uploadList } />
                    </div>
                    <div className="upload-box" style={ { width: '500px', marginTop: 10, marginBottom: 25 } }>
                        <div style={ { marginRight: '10px', lineHeight: '40px', width: 150 } }>Employment Contract:
                        </div>
                        <Updata onChange={ setUploadEmpList } lists={ uploadEmpList } />
                    </div>
                    <div className="upload-box" style={ { width: '500px', marginTop: 10, marginBottom: 25 } }>
                        <div style={ { marginRight: '10px', lineHeight: '40px' } }>I9 on file:</div>
                        <Updata onChange={ setUploadI9List } lists={ uploadI9List } />
                    </div>
                    <div className="upload-box" style={ { width: '500px', marginTop: 10 } }>
                        <div style={ { marginRight: '10px', lineHeight: '40px' } }>Other on file:</div>
                        <Updata onChange={ setUploadOtherList } lists={ uploadOtherList } />
                    </div>
                </div>
            </div>
            <div className="from-btn">
                <Button variant="contained" color="primary" type={ 'submit' }>Save</Button>
            </div>
        </form>
    )
}

const EditTeamContractorForm = ({ team, setUserInfo, getEmployee, user }: any) => {
    const { register, handleSubmit, errors } = useForm();
    const { success, error, showLoad, hideLoad } = useStartStore();
    
    const classes = useStyles();
    const [uploadList, setUploadList]: any = useState([])
    const [uploadtwoList, setUploadtwoList]: any = useState([])
    
    const [uploadLoading, setUploadLoading]: any = useState(false)
    const [uploadtwoLoading, setUploadtwoLoading]: any = useState(false)
    const [imgArr, setImgArr]: any = useState([]);
    const [workStatus, setWorkStatus]: any = useState(0);
    const [timeSheets, setTimeSheets] = useState(false);
    const [invoice, setInvoice] = useState(false);
    const [role, setRole] = useState('');
    const [accountType, setAccountType] = useState('');
    const [personalOrBusiness, setPersonalOrBusiness] = useState('');
    
    useEffect(() => {
        setTimeSheets(false);
        setInvoice(false);
        let list = user.method ? user.method.split(',') : [];
        setUploadList(user.contract_on_file || []);
        setUploadtwoList(user.w9_on_file || []);
        setRole(user.role || []);
        setPersonalOrBusiness(user.personal_or_business || '');
        setAccountType(user.account_type || '');
        list.forEach((item: string) => {
            switch (item) {
                case '1':
                    setTimeSheets(true);
                    break;
                case '2':
                    setInvoice(true);
                    break;
                default:
                    setTimeSheets(false);
                    setInvoice(false);
                    break;
            }
        })
        
    }, [user])
    
    const submit = async (e: any) => {
        showLoad();
        let data = { ...e };
        data.role = user.role.toString();
        data.method = `${ timeSheets ? '1,' : '' }${ invoice ? '2' : '' }`//这个字段需要修改
        data.w9_on_file = uploadtwoList;
        data.contract_on_file = uploadList;
        data.user_id = user.user_id;
        data.work_email = user.work_email;
        data.role = role;
        data.personal_or_business = personalOrBusiness;
        data.account_type = accountType;
        
        let result: any = await saveEmployeeInfo(data);
        if (result.code === 0) {
            success(result.message);
            getEmployee();
        } else {
            error(result.message);
        }
        hideLoad();
    }
    
    return (
        <form onSubmit={ handleSubmit(submit) }>
            <div className="form">
                <div className="form-left">
                    <div className="title">
                        <span className="title-box"> <span>{ user.name } ID:{ user.employee_num }</span></span>
                    </div>
                    
                    <FormControl size={ 'small' } variant="outlined" className={ classes.formControl }>
                        <InputLabel htmlFor="outlined-age-native-simple"
                                    style={ { background: '#fafafa', paddingRight: '10px' } }>Team</InputLabel>
                        <Select
                            native
                            inputRef={ register() }
                            label="Team"
                            inputProps={ {
                                name: 'team_id',
                                id: 'outlined-age-native-simple'
                            } }
                            value={ user.team_id }
                            onChange={ (e) => setUserInfo({ team_id: e.target.value }) }
                        >
                            { team.map((item: Team, index: number) => {
                                return (
                                    <option key={ index } value={ item.id }>{ item.name }</option>
                                )
                            }) }
                        </Select>
                    </FormControl>
                    
                    <TextField inputRef={ register({
                        required: { value: false, message: 'Please type in job Title' }
                    }) }
                               error={ Boolean(errors.job_title) }
                               variant="outlined"
                               margin="normal"
                               fullWidth
                               defaultValue={ user.job_title }
                               size={ 'small' }
                               id="name"
                               label="Job Title"
                               name="job_title"
                               helperText={
                                   errors.job_title && errors.job_title.message
                               }
                    />
                    
                    <TextField inputRef={ register({
                        required: { value: false, message: 'Please enter Supervisor' }
                    }) }
                               error={ Boolean(errors.supervisor) }
                               variant="outlined"
                               margin="normal"
                               fullWidth
                               defaultValue={ user.supervisor }
                               size={ 'small' }
                               id="name"
                               label="Supervisor Name"
                               name="supervisor"
                               helperText={
                                   errors.supervisor && errors.supervisor.message
                               }
                    />
                    
                    <TextField inputRef={ register({
                        required: { value: false, message: 'Please type Start Date' }
                    }) }
                               error={ Boolean(errors.start_date) }
                               variant="outlined"
                               margin="normal"
                               fullWidth
                               size={ 'small' }
                               id="name"
                               type="date"
                               defaultValue={ getMoment(user.start_date ? user.start_date : new Date()).format('YYYY-MM-DD') }
                               label="Start Date"
                               name="start_date"
                               helperText={
                                   errors.start_date && errors.start_date.message
                               }
                    />
                    
                    <FormControl size={ 'small' } variant="outlined" className={ classes.formControl }>
                        <InputLabel htmlFor="outlined-age-native-simple"
                                    style={ { background: '#fafafa', paddingRight: '10px' } }>Status</InputLabel>
                        <Select
                            native
                            inputRef={ register() }
                            label="Status"
                            inputProps={ {
                                name: 'work_status',
                                id: 'outlined-age-native-simple'
                            } }
                            defaultValue={ user.work_status || 0 }
                        >
                            <option style={ { color: '#f9f9f9' } } value={ 0 }>Please select status</option>
                            <option value={ 1 }>Full time</option>
                            <option value={ 2 }>Part time</option>
                            <option value={ 3 }>Contractor</option>
                        </Select>
                    </FormControl>
                    <TextField inputRef={ register({
                        required: { value: false, message: 'Please type in your name' }
                    }) }
                               error={ Boolean(errors.hourly_rate) }
                               variant="outlined"
                               margin="normal"
                               fullWidth
                               size={ 'small' }
                               id="name"
                               defaultValue={ user.hourly_rate }
                               label="Hourly rate"
                               name="hourly_rate"
                               helperText={
                                   errors.hourly_rate && errors.hourly_rate.message
                               }
                    />
                    <TextField disabled={ true } inputRef={ register({
                        required: { value: false, message: 'Please enter work email' }
                    }) }
                               error={ Boolean(errors.work_email) }
                               variant="outlined"
                               margin="normal"
                               fullWidth
                               size={ 'small' }
                               id="name"
                               defaultValue={ user.work_email }
                               label="Work Email"
                               name="work_email"
                               helperText={
                                   errors.work_email && errors.work_email.message
                               }
                    />
                    
                    <TextField inputRef={ register({
                        required: { value: false, message: 'Please enter phone' }
                    }) }
                               error={ Boolean(errors.phone) }
                               variant="outlined"
                               margin="normal"
                               fullWidth
                               size={ 'small' }
                               id="name"
                               label="Phone"
                               defaultValue={ user.phone }
                               name="phone"
                               helperText={
                                   errors.phone && errors.phone.message
                               }
                    />
                    <FormControl size={ 'small' } variant="outlined" className={ classes.formControl }>
                        <InputLabel htmlFor="outlined-age-native-simple"
                                    style={ { background: '#fafafa', paddingRight: '10px' } }>Employee Role
                            Type</InputLabel>
                        <Select
                            native
                            inputRef={ register() }
                            label={ `Employee Role Type` }
                            onChange={ (e: any) => setRole(e.target.value) }
                            inputProps={ {
                                name: 'role',
                                id: 'outlined-age-native-simple'
                            } }
                            value={ role }
                        >
                            <option value={ 3 }>Staff</option>
                            <option value={ 4 }>Contractor</option>
                        </Select>
                    </FormControl>
                    <div className="title">
                        <span className="title-box">Contract Agreement</span>
                    </div>
                    
                    {/*<div className='upload-box'>
                        <div>Contract on file:</div>
                        <Button className='upload'>
                            upload
                            <input style={{opacity: 0, position: "absolute"}} type={'file'} onChange={drop}/>
                        </Button>
                    </div>*/ }
                    
                    <div className="upload-box" style={ { width: '420px', marginTop: 10 } }>
                        <div style={ { marginRight: '10px', lineHeight: '40px' } }>Contract on file:</div>
                        <Updata onChange={ setUploadList } lists={ uploadList } />
                    </div>
                    
                    {/*{
                        uploadList.map((item: string, index: number) => {
                            return (
                                <div key={index} className='upload-list' style={{background:'#f5f5f5',padding:'10px 0'}}>
                                    <div className='upload-box'>
                                    {index === 0 && uploadLoading &&
                                    <CircularProgress size={16} style={{marginRight: '10px'}}/>}
                                    {item}
                                    </div>
                                    <div onClick={()=>deleteImg(index,"W9")} style={{color:'red'}}>delete</div>
                                </div>
                            )
                        })
                    }*/ }
                
                </div>
                <div className="form-right">
                    <div>
                        <div className="title"><span className="title-box">Legal Status</span></div>
                        
                        {/*<div className='upload-box'>
                            <div>W9 On file:</div>
                            <Button className='upload'>
                                upload
                                <input style={{opacity: 0, position: "absolute"}} type={'file'} onChange={droptwo}/>
                            </Button>
                        </div>*/ }
                        <div className="upload-box" style={ { width: '420px', marginTop: 10 } }>
                            <div style={ { marginRight: '10px', lineHeight: '40px' } }>W9 On file:</div>
                            <Updata onChange={ setUploadtwoList } lists={ uploadtwoList } />
                        </div>
                        
                        {/*{
                            uploadtwoList.map((item: string, index: number) => {
                                return (
                                    <div key={index}
                                         className='upload-list'
                                         style={{background: '#f5f5f5', padding: '10px 0'}}>
                                        <div className='upload-box'>
                                            {index === 0 && uploadtwoLoading &&
                                            <CircularProgress size={16} style={{marginRight: '10px'}}/>}
                                            {item}
                                        </div>
                                        <div onClick={() => deleteImg(index, "Contract")}
                                             style={{color: 'red'}}>delete
                                        </div>
                                    </div>
                                )
                            })
                        }*/ }
                        
                        <TextField inputRef={ register({
                            required: { value: false, message: 'Please Contract Expire Date' }
                        }) }
                                   error={ Boolean(errors.contract_expire_date) }
                                   variant="outlined"
                                   margin="normal"
                                   fullWidth
                                   size={ 'small' }
                                   id="name"
                                   type="date"
                                   defaultValue={ getMoment(user.contract_expire_date ? user.contract_expire_date : new Date()).format('YYYY-MM-DD') }
                                   label="Contract Expire Date"
                                   name="contract_expire_date"
                                   helperText={
                                       errors.contract_expire_date && errors.contract_expire_date.message
                                   }
                        />
                    </div>
                    <div style={ { marginTop: '26px' } }>
                        <div className="title" style={ { marginBottom: '20px' } }><span className="title-box">Payment Information</span>
                        </div>
                        
                        <div className="cutover-box" style={ { width: `100%` } }>
                            <div>Method:</div>
                            <div className="cutover-checkbox"><Checkbox inputRef={ register() }
                                                                        color="primary"
                                                                        checked={ timeSheets }
                                                                        onChange={ (e: any) => setTimeSheets((e.target.checked)) } />Time
                                Sheet
                            </div>
                            <div className="cutover-checkbox"><Checkbox inputRef={ register() }
                                                                        color="primary"
                                                                        checked={ invoice }
                                                                        onChange={ (e: any) => setInvoice((e.target.checked)) } />Invoice
                            </div>
                        </div>
                        
                        <TextField inputRef={ register({
                            required: { value: false, message: 'Please enter Address' }
                        }) }
                                   error={ Boolean(errors.address) }
                                   variant="outlined"
                                   margin="normal"
                                   fullWidth
                                   size={ 'small' }
                                   id="name"
                                   defaultValue={ user.address }
                                   label="Address"
                                   name="address"
                                   helperText={
                                       errors.address && errors.address.message
                                   }
                        />
                        
                        <TextField inputRef={ register({
                            required: { value: false, message: 'Please enter address_two' }
                        }) }
                                   error={ Boolean(errors.address_two) }
                                   variant="outlined"
                                   margin="normal"
                                   fullWidth
                                   size={ 'small' }
                                   id="name"
                                   defaultValue={ user.address_two }
                                   label="Address Two"
                                   name="address_two"
                                   helperText={
                                       errors.address_two && errors.address_two.message
                                   }
                        />
                        
                        <TextField inputRef={ register({
                            required: { value: false, message: 'Please enter city' }
                        }) }
                                   error={ Boolean(errors.city) }
                                   variant="outlined"
                                   margin="normal"
                                   fullWidth
                                   size={ 'small' }
                                   id="name"
                                   defaultValue={ user.city }
                                   label="City"
                                   name="city"
                                   helperText={
                                       errors.city && errors.city.message
                                   }
                        />
                        
                        <div className="upload-box">
                            <TextField inputRef={ register({
                                required: { value: false, message: 'Please enter state' }
                            }) }
                                       error={ Boolean(errors.state) }
                                       variant="outlined"
                                       margin="normal"
                                       fullWidth
                                       size={ 'small' }
                                       id="name"
                                       defaultValue={ user.state }
                                       label="State"
                                       name="state"
                                       helperText={
                                           errors.state && errors.state.message
                                       }
                            />
                            
                            <div style={ { width: '40px' } } />
                            
                            <TextField inputRef={ register({
                                required: { value: false, message: 'Please enter zip' }
                            }) }
                                       error={ Boolean(errors.zip) }
                                       variant="outlined"
                                       margin="normal"
                                       fullWidth
                                       size={ 'small' }
                                       id="name"
                                       defaultValue={ user.zip }
                                       label="Zip"
                                       name="zip"
                                       helperText={
                                           errors.zip && errors.zip.message
                                       }
                            />
                        </div>
                        
                        <TextField inputRef={ register({
                            required: { value: false, message: 'Please enter Rate Information' }
                        }) }
                                   error={ Boolean(errors.rate_information) }
                                   variant="outlined"
                                   margin="normal"
                                   fullWidth
                                   size={ 'small' }
                                   id="name"
                                   label="Rate Information"
                                   defaultValue={ user.rate_information }
                                   name="rate_information"
                                   helperText={
                                       errors.rate_information && errors.rate_information.message
                                   }
                        />
                        <div className="title" style={ { margin: '20px 0' } }><span className="title-box">Bank Information</span>
                        </div>
                        <div className="m-col-radio">
                            <span>Account Type：</span>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio"
                                name="row-radio-buttons-group"
                                onChange={ ev => setAccountType(ev.target.value) }
                                value={ accountType }
                            >
                                <FormControlLabel value="1" control={ <Radio /> } label="Checking" />
                                <FormControlLabel value="2" control={ <Radio /> } label="Savings" />
                            </RadioGroup>
                        </div>
                        <TextField
                            inputRef={ register() }
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            size={ 'small' }
                            id="routing_number"
                            label="Routing Number"
                            defaultValue={ user.routing_number }
                            name="routing_number"
                        />
                        <TextField
                            inputRef={ register() }
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            size={ 'small' }
                            id="account_number"
                            label="Account Number"
                            defaultValue={ user.account_number }
                            name="account_number"
                        />
                        <div className="m-col-radio">
                            <span>Personal Or Business：</span>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio"
                                name="row-radio-buttons-group"
                                onChange={ ev => setPersonalOrBusiness(ev.target.value) }
                                value={ personalOrBusiness }
                            >
                                <FormControlLabel value="1" control={ <Radio /> } label="Personal" />
                                <FormControlLabel value="2" control={ <Radio /> } label="Business" />
                            </RadioGroup>
                        </div>
                    
                    </div>
                </div>
            </div>
            <div className="from-btn">
                <Button variant="contained" color="primary" type={ 'submit' }>Save</Button>
            </div>
        </form>
    )
}

const EditTeamMember = () => {
    const { success, error, showLoad, hideLoad } = useStartStore();
    const [team, setTeam]: any = useState([]);
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [isDelete, setIsDelete] = useState(false);
    const [employee, setEmployee]: any = useState({ total: 0, data: [] });
    const [employeeIndex, setEmployeeIndex]: any = useState(null);
    const [employeeEdit, setEmployeeEdit]: any = useState(false);
    const [user, setUser]: any = useState(null);
    const [openVisible, setOpenVisible]: any = useState(false);
    const [deleteItem, setDeleteItem]: any = useState({});
    
    useEffect(() => {
        loadData();
    }, [])
    
    const loadData = async () => {
        
        getTeamList();
        getEmployee();
        
    }
    
    const getTeamList = async () => {
        let data: any = await TeamList();
        if (data.code === 0) {
            setTeam(data.data);
        }
    }
    const getTeamLeaderName = (teamId: number) => {
        for (const emp of employee.data) {
            if (emp.team_id === teamId && emp.role === 2) {
                return emp.name.substring(0, 1).toLocaleUpperCase()
            }
        }
        
        return ''
    }
    const getEmployee = async () => {
        showLoad();
        let data: any = await selectEmployee();
        if (data.code === 0) {
            setEmployee(data.data);
            setEmployeeEdit(false);
            setUser({ ...user, role: -1 });
            if (employeeIndex !== null) {
                setTimeout(() => {
                    setUser(data.data.data[ employeeIndex ]);
                    setEmployeeEdit(true);
                })
            }
        }
        hideLoad();
    }
    
    const addTeamName = async () => {
        if (!name) {
            error('Please input team name! ');
            
            return;
        }
        
        showLoad();
        let data: any = await AddTeam({ name: name });
        if (data.code === 0) {
            success(data.message)
            loadData();
            setOpen(false)
        } else {
            error(data.message);
        }
        
        // setOpen(false);
        hideLoad();
    }
    
    const add = () => {
        if (isDelete) {
            setIsDelete(false);
        } else {
            setOpen(true);
        }
    }
    
    const deleteTeam = async (index: number) => {
        let teams = [...team];
        teams.splice(index, 1);
        
        
        let data: any = await DeleteTeam({ cate: 'team', id: team[ index ].id });
        
        if (data.code === 0) {
            setTeam(teams);
            success(data.message);
        } else {
            error(data.message);
        }
        
    }
    
    const setEmployeeInfo = (index: number) => {
        setEmployeeIndex(index)
        setUser({ ...user, role: -1 });
        setTimeout(() => {
            setUser(employee.data[ index ]);
        })
        setEmployeeEdit(true);
    }
    
    const onDeleteEmployee = async () => {
        showLoad();
        
        const result: any = await deleteAccount(deleteItem.user_id);
        
        if (result.code === 0) {
            success('successfully deleted');
            getEmployee();
            setOpenVisible(false)
            return
        }
        
        hideLoad();
        error('Failed to delete')
    }
    
    const setUserInfo = (options: any) => {
        setUser({
            ...user,
            ...options
        })
    }
    
    const onSendEmail = async (team: any) => {
        showLoad();
        
        const result: any = await sendEmail(team.user_id);
        
        hideLoad();
        
        if (result.code === 0) {
            success('Successfully send');
            return
        }
        error('Failed to send')
    }
    return (
        <div className="edit-project-view">
            <div className="tbas">
                <div className="tbas-box">
                    <div style={ { color: '#2d7dd4', fontWeight: 500, width: '140px' } }>Create Team Names :</div>
                    <div className="tabs-left">
                        { team.map((item: Team, index: number) => {
                            return (
                                <div className="tabs-item" key={ index }>
                                    
                                    {
                                        isDelete ? (
                                            <Chip avatar={ <Avatar>{ item.name[ 0 ] }</Avatar> }
                                                  color="primary"
                                                  label={ item.name }
                                                  variant="outlined"
                                                  deleteIcon={ <DeleteIcon /> }
                                                  onDelete={ () => deleteTeam(index) } />
                                        ) : <Chip avatar={ <Avatar>{ item.name[ 0 ] }</Avatar> }
                                                  color="primary"
                                                  label={ item.name }
                                                  variant="outlined" />
                                    }
                                </div>
                            )
                        }) }
                    </div>
                    <div className="tabs-right">
                        <Tooltip title={ !isDelete ? 'Add Team' : 'Cancel Edit' }>
                            <Fab size={ 'small' }
                                 style={ { marginRight: '20px', background: !isDelete ? '#2d7dd4' : '#9a9a9a' } }
                                 onClick={ add }
                                 aria-label="add">
                                <AddIcon className="transition-icon"
                                         style={ { transform: `rotate(${ isDelete ? 45 : 0 }deg)` } } />
                            </Fab>
                        </Tooltip>
                        <Tooltip title={ !isDelete ? 'Edit Team' : 'Save Edit' }>
                            <Fab
                                style={ { background: isDelete ? '#08a521' : '#ff9206' } }
                                onClick={ () => setIsDelete(!isDelete) }
                                size={ 'small' }
                                aria-label="edit">
                                {
                                    isDelete ? <CheckIcon style={ { color: '#FFF' } } /> :
                                        <EditIcon style={ { color: '#FFF' } } />
                                }
                            </Fab>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <Divider />
            <div className="team">
                <div className="team-left">
                    <div className="title"><span className="title-box">Name of Employee</span></div>
                    <div className="team-left-box">
                        {
                            employee.data.length >= 1 && (
                                <div className="upload-list"
                                     style={ {
                                         padding: '10px 10px',
                                         boxSizing: 'border-box',
                                         background: '#ededed',
                                         marginRight: '10px'
                                     } }>
                                    <div>Name - [ Team Name ] TL</div>
                                    <div>Operation</div>
                                </div>
                            )
                        }
                        <div></div>
                        {
                            employee.data.map((item: any, index: number) => {
                                return <div className="upload-list"
                                            key={ index }
                                            style={ {
                                                background: employeeIndex === index ? '#f5f5f5' : '#f9f9f9',
                                                color: employeeIndex === index ? '#2d7dd4' : '#333'
                                            } }>
                                    <div className="team-list"
                                         key={ index }>{ item.name } - <span style={ { color: '#079ec4' } }>[ { team.find((t: any) => t.id === item.team_id)?.name } ] <span style={{color:'#f65801'}}>{getTeamLeaderName(item.team_id) || 'Not'} TL</span></span>
                                    </div>
                                    <div>
                                        <Button color="secondary" onClick={ () => {
                                            setOpenVisible(true);
                                            setDeleteItem(item);
                                        } }>DELETE</Button>
                                        
                                        <Button color="primary" onClick={ () => {
                                            setEmployeeInfo(index)
                                        } }>EDIT</Button>
                                        
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                
                {
                    employeeEdit ? (
                        <div className="team-right">
                            {
                                user.role === -1 ? null :
                                    (
                                        user.role === 4 ?
                                            <EditTeamContractorForm
                                                setUserInfo={ setUserInfo }
                                                getEmployee={ getEmployee }
                                                user={ user }
                                                team={ team } /> :
                                            
                                            <EditTeamMemberForm
                                                setUserInfo={ setUserInfo }
                                                getEmployee={ getEmployee } user={ user } team={ team } />
                                    )
                            }
                        </div>
                    ) : (
                        <div style={ {
                            flex: 1,
                            width: '100%',
                            height: '600px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        } }>
                            <div>
                                <LineStyleIcon style={ {
                                    width: '90px',
                                    height: '90px',
                                    display: 'block',
                                    margin: '0 auto',
                                    color: '#2d7dd4'
                                } } />
                                <div style={ { color: '#999' } }>Please select users to edit</div>
                            </div>
                        </div>
                    )
                }
            </div>
            
            <AlertForm
                open={ open }
                title={ 'Please enter team name' }
                center={ null }
                cancelTitle={ 'cancel' }
                cancelConfig={ {
                    onClick: () => setOpen(false)
                } }
                determinetitle={ 'submit' }
                determineConfig={ {
                    color: 'primary',
                    onClick: addTeamName
                } }
            >
                <div key={ '123' } style={ { width: '400px' } }>
                    <Input
                        errorName={ 'name' }
                        type={ 'text' }
                        name={ 'name' }
                        label={ 'name' }
                        value={ name }
                        onChange={ (e: { target: { value: React.SetStateAction<string>; }; }) => setName(e.target.value) }
                    />
                </div>
            </AlertForm>
            
            <Dialog
                open={ openVisible }
                onClose={ () => setOpenVisible(false) }
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={ { cursor: 'move' } } id="draggable-dialog-title">
                    Confirm Delete
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the employee
                        named <span style={ { color: '#04a2b8' } }>{ deleteItem.name }</span><br />
                        
                        <span style={ { color: '#f00', fontWeight: 'bold' } }>This operation cannot be undone. Are you sure you want to continue?</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={ () => setOpenVisible(false) } color="primary">
                        Cancel
                    </Button>
                    <Button onClick={ onDeleteEmployee } color="secondary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default observer(EditTeamMember)
