import React, {useEffect, useState} from "react";
import {Card, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button} from "@material-ui/core";
import "./index.less"

const EmployeeExpenseReport = ({list,downLoad}:any)=>{

    const [total,setTotal] = useState(0)

    useEffect(()=>{
        let sum = 0;
        list.forEach((item: { fee: number; })=>{
            sum += item.fee
        })
        setTotal(sum)
    },[list])


    return (
        <Card className='employee-expense-report-view'>
            <div className='title'>Employee Expense Report</div>
            <div className='worked'>
                <div>Total Amount Spent:</div>
                <div className='worked-box'>{total.toFixed(2)}</div>
            </div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Project</TableCell>
                            <TableCell align="left">Expense</TableCell>
                            <TableCell align="left">Total Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            list.map((item: any, index: string | number | undefined)=>{
                                return (
                                    <TableRow key={index}>
                                        <TableCell align='left'>{item.project_name}</TableCell>
                                        <TableCell align="left">{item.expense_name}</TableCell>
                                        <TableCell align="left">{item.fee.toFixed(2)}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                list.length >= 1 && <Button onClick={downLoad} variant="contained" color="primary" className='submit'>Export to Excel</Button>
            }
        </Card>
    )
}

export default EmployeeExpenseReport
