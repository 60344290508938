import React, { useEffect, useState } from "react";
import { Card, Divider, Button, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";

interface IProps {
    onSubmit?: any
}

const Optional = ({onSubmit}: IProps) => {

    const {register, handleSubmit, setValue, errors} = useForm();
    let userInfo:any = localStorage.getItem('userInfo')

    useEffect(() => {
        if (typeof userInfo === "string") {
            userInfo = JSON.parse(userInfo);
        }

        setTimeout(() => {
            setValue('language', userInfo.detail_info.language);
            setValue('highest_degree', userInfo.detail_info.highest_degree);
            setValue('graduation', userInfo.detail_info.graduation);
            setValue('certification', userInfo.detail_info.certification);
        })

    }, [])

    const submit = (e: any) => {
        onSubmit(e);
    }

    return (
        <Card className='optional-view'>
            <div className='optional-title'>Optional for Employee Self Input</div>
            <Divider/>
            <form onSubmit={handleSubmit(submit)}>
                <TextField inputRef={register()}
                           error={Boolean(errors.language)}
                           variant="outlined"
                           margin="normal"
                           size={"small"}
                           fullWidth
                           label="Language"
                           name="language"
                           helperText={
                               errors.language && errors.language.message
                           }
                />

                <TextField inputRef={register()}
                           error={Boolean(errors.highest_degree)}
                           variant="outlined"
                           margin="normal"
                           size={"small"}
                           fullWidth
                           label="Highest Degree"
                           name="highest_degree"
                           helperText={
                               errors.highest_degree && errors.highest_degree.message
                           }
                />

                <TextField inputRef={register()}
                           error={Boolean(errors.graduation)}
                           variant="outlined"
                           margin="normal"
                           size={"small"}
                           fullWidth
                           label="Graduation"
                           name="graduation"
                           helperText={
                               errors.graduation && errors.graduation.message
                           }
                />

                <TextField inputRef={register()}
                           error={Boolean(errors.certification)}
                           variant="outlined"
                           margin="normal"
                           size={"small"}
                           fullWidth
                           label="Certification"
                           name="certification"
                           helperText={
                               errors.certification && errors.certification.message
                           }
                />

                <div className='submit'>
                    <Button variant="contained" type={"submit"} color="primary">Update</Button>
                </div>
            </form>
        </Card>
    )
}

export default Optional
