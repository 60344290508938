import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Divider,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    IconButton,
    Tooltip,
} from "@material-ui/core";
import { useStyles } from "../../utils/thmem";
import "./index.less"
import { useStartStore } from "../../store";
import { observer } from "mobx-react";
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { getMoment } from '../../utils/tool';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import Input from '../../componetns/input';
import { AlertForm } from '../../componetns/alert';

function NumberFormatCustom(props: any) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values: any) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
}

// data={times} isEdit={edit} onSubmit={Audit} date={date}
const TimeTable = ({date, projects, data, onSumbit, clearTime, onSaveSubmit, editClose}: any) => {

    const classes = useStyles();

    const {success, error, warning, showLoad, hideLoad} = useStartStore();
    const [row, setRow]: any = useState([]);
    const [desc, setDesc] = useState('');
    const [isEdit, setEdit] = useState(false);
    const [isEditContent, setEditContent] = useState(false);
    const [isFull, setIsFull] = useState(false);
    const [sum, setSum]: any = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (data.record) {
            initTimeSheet(data.record);
        }
        setEdit(false);
        calcSum(false, data.record);
    }, [data, date])

    const initTimeSheet = (record: any[], isUpdate = false) => {
        const getSheetArr = (cur: any) => {
            const timeSheetArr: any[] = [];
            date.forEach((d: any, index: number) => {

                timeSheetArr[index] = {date: d};

                for (const item of cur.data) {
                    if (d === item.date) {
                        timeSheetArr[index].duration = item.duration || 0;
                        break;
                    }
                }
            });

            return timeSheetArr;
        }

        if (isUpdate) {
            return getSheetArr(record);
        }


        for (let i = 0; i < record.length; i++) {
            const cur = record[i];
            record[i] && (record[i].timeSheetArr = getSheetArr(cur));
        }

        setRow(record);
    };

    const calcSum = (isValidate?: boolean, defaultRow?: any[]) => {
        let list: any[] = [];

        const rows = defaultRow ? defaultRow : (isEdit ? data.record : row) || [];

        for (const rowItem of rows) {
            rowItem.timeSheetArr && rowItem.timeSheetArr.forEach((item: any, index: number) => {
                if (list[index]) {
                    list[index].duration += Number(item.duration) || 0;
                } else {
                    list[index] = {
                        date: item.date,
                        duration: item.duration ? Number(item.duration) : 0
                    };
                }
            })
        }

        if (isValidate) {
            for (const cur of list) {
                if (cur.duration > 24) {
                    return true;
                }
            }

            return false;
        }

        setSum(list);
    }

    const addTime = (row: any[]) => {
        setDesc('');
        let list = [...row];

        list.forEach((item) => {
            item.state = false;
        });


        const record: any = {project_id: 0, desc: "", data: [], state: true};

        record['timeSheetArr'] = initTimeSheet(record, true)

        // list.push(record);
        if (row.length === 0) {
            setRow([record]);
        } else {
            list.push(record)
            setRow(list);
        }
    }

    const editRow = (index: number) => {
        let list = [...row]
        list.forEach((_item, _index) => {
            if (_index === index) {
                _item.state = true;
                //设置反填信息
                setDesc(_item.desc);

            } else {
                _item.state = false;
            }
        })

        setRow(list);
    }

    const delList = (index: number) => {
        let list = [...row];
        list.splice(index, 1);
        setRow(list);
        calcSum();
    }

    const offRow = () => {
        let list = [...row]
        list.forEach((_item, _index) => {
            _item.state = false;
        })
        setRow(list);
    }

    const saveRow = (index: number) => {
        let list = [...row];
        list.forEach((_item, _index) => {
            _item.state = false;

            if (_index === index) {
                list[index].desc = desc;
            }
        })

        setRow(list);
        setDesc('');
        calcSum();
    }

    const handleChange = (value: any, index: number) => {
        let list = [...row];
        list[index].project_id = value;

        setRow(list);
    }

    const submit = () => {
        let _data: any = JSON.parse(JSON.stringify(row));

        if (_data.length === 0) {
            error('Submission cannot be empty')
            return;
        }

        for (const item of _data) {
            if (item.state) {
                return error('Please save');
            }
            if (item.project_id === 0) {
                return error('Please choose project')
            }
            if (item.desc === '') {
                return error('Please enter desc')
            }

            let isEmptyDuration = true;
            for (const time of item.timeSheetArr) {
                if (time.duration && time.duration > 0) {
                    isEmptyDuration = false;
                }
            }

            if (isEmptyDuration) {
                return error('Please enter duration')
            }
        }

        if (calcSum(true)) {
            return error('No more than 24 hours');
        }

        _data.forEach((item: any, index: string | number) => {
            const record = _data[index].timeSheetArr.filter((tiem: any) => {
                return !(!tiem.duration || tiem.duration <= 0);
            });


            // for (const key in record) {
            //     if (record.hasOwnProperty(key)) {

            //     }
            // }

            // data[index].date = item.date;
            _data[index].desc = item.desc;
            // data[index].duration = parseInt(item.duration);
            _data[index].id = item.id;
            _data[index].project_id = item.project_id
            _data[index].data = record;

            delete _data[index].gmt_create
            delete _data[index].gmt_modified
            delete _data[index].state
            delete _data[index].timeSheetArr
            delete _data[index].user_id
            delete _data[index].record
            delete _data[index].status
        })

        onSaveSubmit(_data, cancelEdit);
    }

    const submitApproval = (type: boolean) => {
        onSumbit(type, message);
    }

    const cancelEdit = () => {
        setRow([]);
        setEdit(false);
        setDesc('');
        // setSum([0, 0, 0, 0, 0, 0, 0])
        addTime([]);
    }

    const setDateValue = (val: string, date: string) => {
        const rows = [...row];

        for (let i = 0; i < rows.length; i++) {
            const cur = rows[i];
            if (cur.state) {
                for (let j = 0; j < cur.timeSheetArr.length; j++) {
                    if (cur.timeSheetArr[j].date === date) {
                        rows[i].timeSheetArr[j].duration = val ? Number(val) : '';

                        return setRow(rows);
                    }
                }
            }
        }
    }

    return (
        <div>
            <Card className={`table ${isFull ? 'm-full-screen' : ''}`} style={{width: '100%'}}>
                <div className='table-title-approval'>
                    <div style={{display: "flex"}}>Time Sheet Approval
                        <span style={{
                            paddingLeft: 20,
                            color: '#d315b3'
                        }}>[ {getMoment(date[0]).format('MMMM YYYY')} ]</span>
                    </div>
                    <div>
                        {/*{
                            !isEdit &&
                            <Button variant="contained"
                                    color="primary"
                                    onClick={() => addTime(row)}
                                    size={"small"}>add</Button>
                        }*/}

                        {/*<Tooltip title="Max screen" style={{marginLeft:'10px'}}>*/}

                        {!isEditContent ? (
                            <Button variant="contained"
                                    color="primary"
                                    onClick={() => setEditContent(true)}
                                    size={"small"}>Edit</Button>
                        ) : (
                            <>
                                <Button variant="contained"
                                        onClick={() => setEditContent(false)}
                                        size={"small"} style={{marginRight: 20}}>Cancel</Button>
                                <Button variant="contained"
                                        color="secondary"
                                        onClick={submit}
                                        size={"small"} style={{color: '#fff'}}>Submit</Button>
                            </>
                        )}

                        <Button
                            size={"small"}
                            style={{marginLeft: '10px'}}
                            variant="contained"
                            onClick={() => setIsFull(!isFull)}
                            startIcon={isFull ? <FullscreenExitIcon/> : <FullscreenIcon/>}
                        >
                            {isFull ? 'Full Exit' : 'Full Screen'}
                        </Button>
                        {/*</Tooltip>*/}
                    </div>

                </div>

                <Divider/>

                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: '220px'}} align="left">input Your Project</TableCell>
                                <TableCell style={{width: '200px'}}>Desc</TableCell>
                                {date.map((item: string, index: number) => {
                                    return <TableCell key={index}
                                                      style={{width: '100px'}}>{moment(item).format('MM/DD')}</TableCell>
                                })
                                }
                                {
                                    isEditContent && <TableCell style={{width: '200px'}}>Operation</TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                row.map((item: any, index: number) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell style={{width: '220px'}} align="left">
                                                {
                                                    item.state ? (
                                                        <Select
                                                            style={{width: '100%'}}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={item.project_id}

                                                            onChange={(e) => handleChange(e.target.value, index)}
                                                        >
                                                            <MenuItem value={0}>Select project</MenuItem>
                                                            {projects.map((item: any) => {
                                                                return <MenuItem key={item.id}
                                                                                 value={item.id}>{item.name}</MenuItem>
                                                            })}
                                                        </Select>
                                                    ) : item.project_id === 0 ? (
                                                        <div>please choose</div>
                                                    ) : projects.map((_item: any, index: number) => {
                                                        if (_item.id === item.project_id) {
                                                            return <div key={index}>{_item.name}</div>
                                                        }

                                                        return null
                                                    })
                                                }
                                            </TableCell>
                                            <TableCell style={{width: '200px'}}>
                                                {
                                                    item.state ? (
                                                        <TextField defaultValue={item.desc}
                                                                   value={desc}
                                                                   onClick={() => {
                                                                       setOpen(true)
                                                                   }}
                                                                   onChange={(e) => setDesc(e.target.value)}
                                                                   style={{width: '100%'}}/>
                                                    ) : (
                                                        <div>{item.desc}</div>
                                                    )
                                                }

                                            </TableCell>
                                            {item.timeSheetArr && item.timeSheetArr.map((timeItem: any, index: number) => {
                                                return (
                                                    <TableCell key={index} style={{width: '100px'}}>
                                                        {
                                                            item.state ? (
                                                                <TextField
                                                                    value={timeItem.duration ? timeItem.duration : ''}
                                                                    onChange={(e) => {
                                                                        setDateValue(e.target.value, timeItem.date)
                                                                    }}
                                                                    style={{width: '100%'}}
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatCustom,
                                                                    }}
                                                                />
                                                            ) : (
                                                                <div>{timeItem.duration}</div>
                                                            )
                                                        }
                                                    </TableCell>
                                                )
                                            })}
                                            {
                                                isEditContent && (
                                                    <TableCell style={{width: '200px'}}>

                                                        <div>
                                                            {/*编辑*/}
                                                            {!item.state && <Button color="primary"
                                                                                    onClick={() => editRow(index)}>Edit</Button>}
                                                            {!item.state && !isEdit && <Button color="secondary"
                                                                                               onClick={() => delList(index)}>Delete</Button>}
                                                            {/*编辑状态*/}
                                                            {item.state && <Button color="primary"
                                                                                   onClick={() => saveRow(index)}>Save</Button>}
                                                            {item.state && <Button onClick={offRow}>cancel</Button>}
                                                        </div>
                                                    </TableCell>
                                                )
                                            }
                                        </TableRow>
                                    )
                                })
                            }
                            {sum && sum.length > 0 ? (
                                <TableRow>
                                    <TableCell style={{width: '220px'}} size={"small"} rowSpan={1}/>
                                    <TableCell style={{width: '200px'}} align="left">Total Hours</TableCell>
                                    {sum.map((item: any) => {
                                        return <TableCell key={item.date}
                                                          style={{
                                                              width: '100px',
                                                              color: item.duration > 24 ? 'red' : '#333'
                                                          }}
                                                          align="left">{item.duration ? item.duration : ''}</TableCell>
                                    })}
                                    {
                                        isEditContent && <TableCell size={"small"} rowSpan={1}/>
                                    }
                                </TableRow>
                            ) : null}

                        </TableBody>
                    </Table>
                </TableContainer>

                {
                    !isEditContent && (
                        <div className='return-message-input'>
                            <div>Return Message:</div>
                            <TextField value={message} onChange={(e: any) => setMessage(e.target.value)}/>
                        </div>
                    )
                }
                <div className='table-sumbit'>
                    {/*{isEdit && <Button variant="contained"
                                       color="secondary"
                                       onClick={cancelEdit}
                                       style={{color: '#fff', marginRight: 20}}
                                       size={"small"}>cancel</Button>}
                    {
                        editClose === 0 && <Button variant="contained" onClick={submit} color="primary">sumbit</Button>
                    }*/}

                    {
                        !isEditContent && (
                            <div className='submit'>
                                <Button variant="contained"
                                        onClick={() => submitApproval(true)}
                                        color="primary" style={{marginRight: 15}}>Approve</Button>
                                <Button variant="contained" onClick={() => submitApproval(false)}>Return</Button>
                            </div>
                        )
                    }
                </div>
            </Card>

            <AlertForm
                open={open}
                title={'Please enter description'}
                center={null}
                cancelTitle={'cancel'}
                cancelConfig={{
                    onClick: () => setOpen(false)
                }}
                determinetitle={'submit'}
                determineConfig={{
                    color: "primary",
                    onClick: () => {
                        console.log(111);
                        setOpen(false)
                    }
                }}
            >
                <div style={{width: 600, paddingRight: 50}}>
                    <TextField
                        label="Desc"
                        multiline
                        rows={5}
                        defaultValue="Default Value"
                        variant="outlined"
                        value={desc}
                        fullWidth={true}
                        autoFocus={true}
                        onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setDesc(e.target.value)}
                    />
                </div>
            </AlertForm>
        </div>
    )
}

export default observer(TimeTable);
