import Http from '../../../utils/axios'

type ImgData = {
    name?: string;
    url?: string;
}

interface IProps {
    id?: number | null;
    name?: string;
    manager?: string;
    total_budget?: string;
    start_date?: string;
    files?: Array<ImgData>
}

export const getProject = (code?: string, na?: any) => {
    return Http.get(`/api/v1/setup/project/select?code=${ code }&noaccount=${ na }`)
}

export const upProject = (parameter: IProps) => {
    
    let data = {
        // @ts-ignore
        id: parseInt(parameter.id),
        name: parameter.name,
        manager: parameter.manager,
        start_date: parameter.start_date,
        total_budget: parameter.total_budget,
        files: parameter.files
    }
    
    return Http.post('/api/v1/setup/project/update', data)
}


export const selectTeamLeaderEmployee = () => {
    return Http.get('/api/v1/setup/account/details/select', { isTeamLeader: 1 })
}
