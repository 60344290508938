import Login from "../pages/login";
import NullPage from "../pages/nullPage";
import Layout from "../componetns/layout";
// import Welcome from "../pages/welcome";
import SetUp from "../pages/setup";
import Forget from "../pages/forget";
import Signup from "../pages/signup";
import Activtion from "../pages/activtion";
import Reset from "../pages/reset";
import submission from "../pages/submission";
import Welcome from "../pages/welcome";
import Approval from "../pages/approval";
import Profile from "../pages/profile";
import TimeAndExpense from "../pages/timeAndExpense";
import NoAccountSubmit from "../pages/noAccountSubmit";


export default [
    {
        path: "/login",
        exact:true,
        component: Login
    },
    {
        path: "/forget",
        component: Forget
    },
    {
        path: "/signup",
        component: Signup
    },
    {
        path: "/reset",
        component: Reset
    },
    {
        path: "/activtion",
        component: Activtion
    },
    {
        path: "/not/account/submit",
        component: NoAccountSubmit
    },

    {
        path: '/home',
        component:Layout,
        routes:[
            {
                path: "/home/overview",
                exact:true,
                component: Welcome
            },
            {
                path: "/home/setup",
                exact:true,
                component: SetUp
            },
            {
                path: "/home/submission",
                component: submission
            },
            {
                path: "/home/approval",
                component: Approval
            },
            {
                path: "/home/profile",
                component: Profile
            },
            {
                path: "/home/timeAndExpense",
                component: TimeAndExpense
            },
        ]
    },
    {
        path: '*',
        exact: false,
        component:NullPage
    }
];
