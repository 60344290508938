import Http from "../../utils/axios"

interface IProps {
    company_name?: string
    company_url?: string
    email?: string
    is_remember?: boolean
    name?: string
    passwrod?: string
    retype_password?: string
    title?: string
    user_name?: string
    type?:number
}

export const requestSignup = (e:IProps)=>{
    const data = {
        name:e.name,
        email:e.email,
        title:e.title,
        company_name:e.company_name,
        username:'-',
        password:e.passwrod,
        retype_password:e.retype_password,
        company_url:e.company_url
    }
    return Http.post('/api/v1/account/register',data);
}

export const resendEmail = (e:IProps)=>{
    const data = {
        email:e.email,
        type:e.type
    }
    return Http.post('/api/v1/account/resend/mail',data);
}
