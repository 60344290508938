import React from "react";

const NullPage = ()=>{
    return (
        <div>
            <p>404</p>
        </div>
    )
}

export default NullPage
