import Http from '../../../utils/axios'
import axios from '../../../utils/axios';

type W4Img = {
    name: string;
    url: string;
}

interface IProps {
    name?: string;
    cate?: string;
    id?: string;
    team_id?: string;
    legal_status?: string;
    ability_to_approve?: string;
    additional_insured?: string;
    birthday?: string;
    dental?: string;
    hourly_rate?: string;
    insurance?: string;
    job_title?: string;
    phone?: string;
    role?: string;
    start_date?: string;
    supervisor?: string;
    vision?: string;
    w4_on_file?: Array<W4Img>;
    w9_on_file?: Array<W4Img>;
    contract_on_file?: Array<W4Img>;
    work_email?: string;
    work_status?: string;
    user_id?: string;
    method?: string;
    address?: string;
    address_two?: string;
    city?: string;
    zip?: string;
    rate_information?: string;
    visa_expiration?: string;
    state?: string;
    i9_on_file?: Array<W4Img>;
    other_on_file?: Array<W4Img>;
    emp_on_file?: Array<W4Img>;
    account_type?: string;
    personal_or_business?: string;
    account_number?: string;
    routing_number?: string;
}

export const TeamList = () => {
    return Http.get('/api/v1/setup/team/select')
}


export const uploadImg = (parameter: any) => {
    const formdata = new FormData();
    
    formdata.append('file', parameter)
    
    //TODO修改接口
    // return Http.post('/api/v1/common/upload/file',formdata)
    return Http.post('/api/v1/common/upload/file', formdata)
}

export const AddTeam = (parameter: IProps) => {
    
    let data = {
        name: parameter.name
    }
    
    return Http.post('/api/v1/setup/team/create', data);
}

export const DeleteTeam = (parameter: { cate: string; id: number | undefined }) => {
    let data = {
        cate: parameter.cate,
        id: parameter.id
    }
    return Http.post('/api/v1/setup/aic/delete', data)
}

export const selectEmployee = (id?: number | string) => {
    let data;
    
    if (id) {
        data = {
            team_id: id
        }
    }
    
    return Http.get('/api/v1/setup/account/details/select', data)
}


export const deleteAccount = (user_id: string) => {
    return Http.post('/api/v1/setup/account/delete', { user_id });
}

export const sendEmail = (user_id: string) => {
    return Http.post('/api/v1/tasks/sendSubmissionEmail', { user_id });
}
export const saveEmployeeInfo = (parameter: IProps) => {
    
    let data = {
        // @ts-ignore
        team_id: parseInt(parameter.team_id),
        ability_to_approve: parameter.ability_to_approve,
        additional_insured: parameter.additional_insured,
        birthday: parameter.birthday,
        dental: parameter.dental,
        // @ts-ignore
        hourly_rate: parseInt(parameter.hourly_rate),
        insurance: parameter.insurance,
        job_title: parameter.job_title,
        phone: parameter.phone,
        role: parameter.role+'',
        start_date: parameter.start_date,
        supervisor: parameter.supervisor,
        vision: parameter.vision,
        w4_on_file: parameter.w4_on_file,
        work_email: parameter.work_email,
        work_status: parameter.work_status,
        user_id: parameter.user_id,
        legal_status: parameter.legal_status,
        w9_on_file: parameter.w9_on_file,
        contract_on_file: parameter.contract_on_file,
        method: parameter.method,
        address: parameter.address,
        rate_information: parameter.rate_information,
        state: parameter.state,
        address_two: parameter.address_two,
        city: parameter.city,
        zip: parameter.zip,
        i9_on_file: parameter.i9_on_file,
        other_on_file: parameter.other_on_file,
        emp_on_file: parameter.emp_on_file,
        visa_expiration: parameter.visa_expiration || '',
        account_type: parameter.account_type || '',
        personal_or_business: parameter.personal_or_business || '',
        account_number: parameter.account_number || '',
        routing_number: parameter.routing_number || ''
    }
    return Http.post('/api/v1/setup/account/details/save', data)
}
