/**
 * Created by Chuans on 2020/8/26
 * Author: Chuans
 * Github: https://github.com/chuans
 * Time: 4:53 下午
 * Desc:
 */
import moment from 'moment';

export const getMoment = (d: any = moment()) => {
    return moment(d, moment.ISO_8601);
}