import React from "react";
import { Card, Divider, TextField, Button } from "@material-ui/core";
import { useForm } from "react-hook-form";

interface IProps {
    onSubmit?: any
}

const Passwrod = ({onSubmit}: IProps) => {

    let userInfo: any = localStorage.getItem('userInfo')

    const {register, handleSubmit, errors, reset} = useForm();

    const submit = (e: any) => {

        let data = {
            ...e,
            account: JSON.parse(userInfo).id.toString()
        }
        onSubmit(data, (isOK: boolean) => {
            if (isOK) {
                reset();
            }
        });
    }

    return (
        <Card className='passwrod-view'>
            <div className='passwrod-title'>Password Reset</div>
            <Divider/>
            <form onSubmit={handleSubmit(submit)}>
                <TextField inputRef={register({
                    required: 'Please enter the old password',
                })}
                           error={Boolean(errors.old_password)}
                           variant="outlined"
                           size={"small"}
                           margin="normal"
                           fullWidth
                           id="email"
                           type={'password'}
                           label="Current Password"
                           name="old_password"
                           placeholder='Please enter the old password'
                           helperText={
                               errors.old_password && errors.old_password.message
                           }
                           />
                <TextField inputRef={register({
                    required: 'Please enter the New password',
                })}
                           error={Boolean(errors.password)}
                           variant="outlined"
                           margin="normal"
                           size={"small"}
                           fullWidth
                           id="email"
                           type={'password'}
                           label="New Password"
                           name="password"
                           placeholder='Please enter the New password'
                           helperText={
                               errors.password && errors.password.message
                           }
                           />
                <TextField inputRef={register({
                    required: 'Please enter the Retype New Password',
                })}
                           error={Boolean(errors.retype_password)}
                           variant="outlined"
                           margin="normal"
                           size={"small"}
                           fullWidth
                           id="email"
                           type={'password'}
                           label="Retype New Password"
                           name="retype_password"
                           placeholder='Please enter the Retype New Password'
                           helperText={
                               errors.retype_password && errors.retype_password.message
                           }
                           />

                <div className='submit'>
                    <Button variant="contained" type={"submit"} color="primary">Enter</Button>
                </div>
            </form>
        </Card>
    )
}

export default Passwrod
