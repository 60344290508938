import React, { useEffect, useState } from 'react';
import { TextField, Button, Checkbox } from '@material-ui/core';
import './index.less'
import { useForm } from 'react-hook-form';
import IProps from '../../interface/iprops';
import { login } from './api';
import { observer } from 'mobx-react'
import { useStartStore } from '../../store/index'


const Login = ({ history, setAlert }: IProps) => {
    const { register, handleSubmit, errors } = useForm();
    
    const { success, error, showLoad, hideLoad } = useStartStore();
    
    
    const [checked, setChecked] = useState(false);
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    
    useEffect(() => {
        const data = localStorage.getItem('user');
        if (data) {
            let josnData = JSON.parse(data);
            setPassword(josnData.password)
            setEmail(josnData.email)
            setChecked(true)
        }
    }, [])
    
    const onSubmit = async (e: any) => {
        showLoad()
        
        const data: any = await login({
            ...e,
            email,
            password
        });
        
        if (data.code === 0) {
            success('login success');
            
            localStorage.setItem('userInfo', JSON.stringify(data.data))
            
            //Remember password
            if (e.is_remember) {
                localStorage.setItem('user', JSON.stringify({ email: e.email, password: e.password }))
            } else {
                localStorage.removeItem('user')
            }
            
            history.push('/home/overview');
        } else {
            error(data.message)
        }
        hideLoad();
    }
    
    return (
        <div className="login-view">
            <div className="login-bg">
                <img src={ require('../../assets/images/login_bg.jpg') } alt={ 'bg' } />
            </div>
            <div className="login-form">
                <div className="login-form-box">
                    <div className="form-tips">
                        <p className="p1">Log in</p>
                        <p className="p2">Welcome to records management system</p>
                    </div>
                    
                    <form className="form-style" onSubmit={ handleSubmit(onSubmit) } noValidate autoComplete="off">
                        <div>
                            <TextField
                                error={ Boolean(errors.email) }
                                variant="outlined"
                                margin="normal"
                                value={ email }
                                onChange={ (e) => {
                                    setEmail(e.target.value)
                                } }
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                helperText={
                                    errors.email && errors.email.message
                                }
                            />
                        </div>
                        <div>
                            <TextField
                                error={ Boolean(errors.password) }
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={ password }
                                onChange={ (e) => setPassword(e.target.value) }
                                id="password"
                                type={ 'password' }
                                label="Password"
                                name="password"
                                helperText={
                                    errors.password && errors.password.message
                                }
                            />
                        </div>
                        
                        <div className="operation">
                            <div className="operation-checkbox">
                                <Checkbox
                                    checked={ checked }
                                    name="is_remember"
                                    color={ 'primary' }
                                    inputRef={ register() }
                                    onChange={ (e) => setChecked(e.target.checked) }
                                />
                                <div style={ { color: '#999' } }>Remember me</div>
                            </div>
                            <div className="operation-forget" onClick={ () => history.push('/forget') }>Forget
                                password?
                            </div>
                        </div>
                        <Button fullWidth={ true }
                                size={ 'large' }
                                type={ 'submit' }
                                variant="contained"
                                color="primary">login</Button>
                    </form>
                </div>
                <div className="login-sigup" onClick={ () => history.push('/signup') }>new user? <span>Signup</span>
                </div>
                <Button variant="outlined"
                        color="primary"
                        className="no-account-submit"
                        onClick={ () => history.push('/not/account/submit') }>Submit without account</Button>
            </div>
        </div>
    )
}

export default observer(Login);
