import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CircularProgress,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import { useStyles } from "../../utils/thmem";
import { useForm } from "react-hook-form";
import Updata from "../../componetns/update";
import Moment from "moment";
import { ESbmTimeSheetStatus } from '../../enum/userType'
import { useStartStore } from "../../store";
import moment from "moment";
import { ETaskType } from '../../enum/userType';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getMoment } from '../../utils/tool';

const Invoice = ({isEdit, data, cancelEdit, editClose, projects, onSubmit}: any) => {

    const classes = useStyles();
    const {error} = useStartStore();
    const {handleSubmit, errors, register} = useForm();
    const [info, setInfo]: any = useState({
        month: '',
        invoice_number: '',
        nature_of_services_provided: '',
        total_days_in_the_invoice: '',
        total_amount_submitted: ''
    });
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [totalInvoice, setTotalInvoice] = useState('');
    const [totalSubmitted, settotalSubmitted] = useState('');
    const [natureProvided, setNatureProvided] = useState('');
    const [date, setDate] = useState(null)
    const [imgs, setImgs] = useState([])
    const [currentProject, setCurrentProject] = useState('')

    useEffect(() => {
        if (data) {
            setInfo(data);
            setInvoiceNumber(data.invoice_number)
            setTotalInvoice(data.total_days_in_the_invoice)
            settotalSubmitted(data.total_amount_submitted)
            setNatureProvided(data.nature_of_services_provided)
            setCurrentProject(data.project_id)
            setImgs(data.attach_your_detail_files);
            setDate(data.month || null)
        }
    }, [data])

    const submit = (e: any) => {
        let data = {...e};
        data.attach_your_detail_files = imgs;
        data.month = getMoment(date).format('YYYY-MM');
        data.invoice_number = e.invoice_number.split(',')

        if (!currentProject) {
            return error('Please select project');
        }

        data.project_id = +currentProject;

        onSubmit(data, cancel);
    }

    const cancel = () => {
        setInfo([]);
        setInvoiceNumber('')
        setTotalInvoice('')
        settotalSubmitted('')
        setNatureProvided('')
        setImgs([]);
        setDate(null)
        setCurrentProject('')
        cancelEdit()
    }
    return (
        <Card className='table' style={{marginTop: '20px'}}>
            <div className='table-title'>
                <div style={{display: 'flex'}}>Invoice Input
                    <div style={{width: '10px'}}/>
                    <span style={{color: '#3383d5'}}>
                        {editClose === 0 && isEdit && '(Edit...)'}
                        </span>
                    <span style={{color: '#ec5560'}}>
                            {editClose === 1 && isEdit && '(Rejectted...)'}
                        </span>
                    <span style={{color: '#25ad64'}}>
                            {editClose === 2 && isEdit && '(Approved...)'}
                        </span>
                </div>

            </div>
            {info.status === ESbmTimeSheetStatus.REJECTED ? (
                <div className="return-message">
                    Return Message： <span>{info.approve_message}</span>
                </div>
            ) : null}

            <Divider/>
            <div>
                <form onSubmit={handleSubmit(submit)} className='edit-project-form'>

                    <FormControl size={"small"} variant="outlined" className={classes.formControl}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    style={{width: 300}}
                                    variant="inline"
                                    format="MMMM, yyyy"
                                    margin="normal"
                                    label="Month"
                                    name="month"
                                    value={date}
                                    onChange={(e: any) => {
                                        setDate(e)
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                        <FormControl size={"small"} variant="outlined" className={classes.formControl}>
                            <InputLabel id="select-outlined-label-add">Project</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={currentProject}
                                onChange={e => setCurrentProject(e.target.value + '')}
                                label="Project"

                            >
                                {projects.map((item: any) => {
                                    return <MenuItem key={item.id}
                                                     value={item.id}>{item.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <TextField inputRef={register({
                            required: {value: true, message: 'Please input in your invoice number'},
                        })}
                                   error={Boolean(errors.invoice_number)}
                                   variant="outlined"
                                   margin="normal"
                                   fullWidth
                                   size={"small"}
                                   id="name"
                                   label="Invoice Number:"
                                   value={invoiceNumber}
                                   onChange={(e) => setInvoiceNumber(e.target.value)}
                                   defaultValue={info.invoice_number}
                                   name="invoice_number"
                                   helperText={
                                       errors.invoice_number && errors.invoice_number.message
                                   }
                        />
                        <div style={{paddingBottom: 15, color: '#f5a20f'}}>Multiple bills are separated by commas ( ,
                            )
                        </div>

                        <TextField inputRef={register({
                            required: {value: true, message: 'Please input in your total amount submitted'},
                        })}
                                   error={Boolean(errors.total_amount_submitted)}
                                   variant="outlined"
                                   margin="normal"
                                   fullWidth
                                   size={"small"}
                                   id="name"
                                   value={totalSubmitted}
                                   onChange={(e) => settotalSubmitted(e.target.value)}
                                   label="Total Amount Submitted"
                                   defaultValue={info.total_amount_submitted}
                                   name="total_amount_submitted"
                                   helperText={
                                       errors.total_amount_submitted && errors.total_amount_submitted.message
                                   }
                        />

                        <TextField inputRef={register({
                            required: {value: true, message: 'Please input in your nature of services provided'},
                        })}
                                   error={Boolean(errors.nature_of_services_provided)}
                                   variant="outlined"
                                   margin="normal"
                                   fullWidth
                                   size={"small"}
                                   id="name"
                                   value={natureProvided}
                                   onChange={(e) => setNatureProvided(e.target.value)}
                                   label="Nature of Services Provided:"
                                   defaultValue={info.nature_of_services_provided}
                                   name="nature_of_services_provided"
                                   helperText={
                                       errors.nature_of_services_provided && errors.nature_of_services_provided.message
                                   }
                        />
                    </FormControl>

                    <div className="m-select-file">
                        <span className="label">Attach Your  Detail：</span>
                        <Updata lists={imgs} onChange={(e: any) => {
                            console.log(e)
                            setImgs(e);
                        }}/>
                    </div>

                    <div className='invoice-submit'>
                        {isEdit && <Button variant="contained"
                                           color="secondary"
                                           style={{color: '#fff', marginRight: 20}}
                                           onClick={cancel}
                                           size={"small"}>cancel</Button>}
                        {
                            editClose === 0 &&
                            <Button type={"submit"} variant="contained" color="primary">submit</Button>
                        }
                    </div>
                </form>
            </div>
        </Card>
    )
}

export default Invoice
