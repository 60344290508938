import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    TextField,
    Divider
} from '@material-ui/core';
import Updata from '../../componetns/update';

import { getMoment } from '../../utils/tool';


const InvoiceApprovalTable = ({ data, onSubmit, isEdit, type }: any) => {
    
    const [message, setMessage] = useState('')
    
    useEffect(() => {
        setMessage('');
        console.log(data)
    }, [data])
    
    const submit = (status: boolean) => {
        onSubmit(status, message)
    }
    return (
        <Card className="approval-view-box" style={ { width: '600px' } }>
            <div className="table-title">Invoice Approval
                <span style={ {
                    paddingLeft: 20,
                    color: '#d315b3'
                } }>[ { getMoment(data.month).format('MMMM YYYY') } ]</span>
            </div>
            <Divider />
            <div className="table-content">
                <div className="table-content-box">
                    <div className="table-content-title">Month:</div>
                    <div>{ data.month }</div>
                </div>
                <div className="table-content-box">
                    <div className="table-content-title">Invoice Number:</div>
                    <div>{ data.invoice_number }</div>
                </div>
                <div className="table-content-box">
                    <div className="table-content-title">Total Days in the Invoice:</div>
                    <div>{ data.total_days_in_the_invoice }</div>
                </div>
                <div className="table-content-box">
                    <div className="table-content-title">Total Amount Submitted:</div>
                    <div>{ data.total_amount_submitted }</div>
                </div>
                <div className="table-content-box">
                    <div className="table-content-title">Nature of Services Provided:</div>
                    <div>{ data.nature_of_services_provided }</div>
                </div>
            </div>
            <div>
                <Updata lists={ data.attach_your_detail_files ? data.attach_your_detail_files : [] }
                        disabled={ true } />
                {type !== 4 && (
                    <div className='return-message'>
                        <div>Return Message:</div>
                        {!isEdit ? (
                            <TextField value={message}
                                       onChange={(e) => setMessage(e.target.value)}/>
                        ) : (
                            <span style={{color: 'red', paddingLeft: 10, fontSize: 16}}>{data.approve_message}</span>
                        )}
    
                    </div>
                )}
            </div>
            {
                !isEdit && (
                    <div className="submit" style={ { paddingTop: '20px' } }>
                        <Button variant="contained" onClick={ () => submit(true) } color="primary">Approve</Button>
                        <Button variant="contained" onClick={ () => submit(false) }>Return</Button>
                    </div>
                )
            }
            {
                (!isEdit && type === 4) && (
                    <p style={ { color: 'red',textAlign:'right' } }>The return operation will invalidate this invoice.</p>
                )
            }
        </Card>
    )
}

export default InvoiceApprovalTable
