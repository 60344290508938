import axios from 'axios';

export const isDev = process.env.NODE_ENV === 'development';

export const HOST = isDev ? 'http://115.28.176.152:8080' : '';

// export const BASE_URL = isDev ? 'http://115.28.176.152:8080' : 'http://115.28.176.152:8080';
export const BASE_URL = isDev ? 'http://127.0.0.1:7001' : '';

const as = axios.create({

        baseURL: BASE_URL,

        withCredentials: true,

        headers: {},

        // 请求之后处理的数据
        transformResponse: [ (data: any) => {
            return data;
        } ],

        // 响应数据格式
        responseType: 'json',

        // 超时处理  开发环境忽略超时
        timeout: isDev ? 15000 : 15000

    }),
    handleRequest = ({ type = 'get', url = '', option = {}, data = {} }) => {
        try {
            console.group('------------API------------')
            console.log('method:', type);
            console.log('path:', url);
            console.log('body:', data);
            console.groupEnd();
            if (type === 'get') {
                return as.get(url, { params: option });
            } else {
                return as.post(url, data, option);
            }

        } catch (err) {
            console.error(err);
        }
    };

// 响应拦截
as.interceptors.response.use((response: any) => {
    // Do something with response data

    return response.data;
}, (error: any) => {
    if(error.toString().indexOf('timeout')>-1){
        // Do something with response error
        return Promise.resolve({
            code:-1,
            message:'Request timed out, please try again later'
        });
    }

    return Promise.resolve({
        code:-1,
        message:'Service is busy, please try again later'
    })

});
export default {
    get(url: string, option = {}) {
        for (const key in option) {
            if (option.hasOwnProperty(key)) {
                // @ts-ignore
                if(!option[key]){
                    // @ts-ignore
                    delete option[key]
                }
            }
        }
        return handleRequest({ url, option });
    },
    post(url: string, data = {}, option = {}) {

        return handleRequest({
            url,
            option,
            data,
            type: 'post'
        });
    }
}
