import Http from "../../../utils/axios";

interface IProps {
    name:string;
    email:string;
    role:number;
    employee_num:string;
    password:string;
}

export const invite = (parameter:IProps)=>{
    const data = {
        name:parameter.name,
        email:parameter.email.trim(),
        role:parameter.role,
        employee_num:parameter.employee_num,
        password:parameter.password
    }

    return Http.post('/api/v1/account/invite',data)
}


export const historyInvite = ()=>{
    return Http.get('/api/v1/account/invite/history')
}
