import React, { useEffect, useState } from 'react';
import EmployeeExpenseReport from './employeeExpenseReport';
import InvoiceReport from './InvoiceReport';
import TimSheetReport from './timSheetReport';
import ProjectReport from './projectReport';
import NoAccountInvoiceReport from './NoAccountInvoiceReport';
import { TextField, Select, Divider, MenuItem, FormControl, InputLabel, Button, Grid } from '@material-ui/core'
import './index.less'
import { getProject } from '../setup/editProject/api';
import { observer } from 'mobx-react';
import { useStartStore } from '../../store';
import { expenseList, getProjectListApi, timeSheet, timeSheetDown, invoiceList, invoiceNoAccountList } from './api';
import { selectEmployee, TeamList } from '../setup/editTeamMember/api';
import Axios from 'axios'
import { BASE_URL } from '../../utils/axios';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import { Role } from '../../enum/userType';
import { getMoment } from '../../utils/tool';

const TimeAndExpense = () => {
    
    const { success, error, showLoad, hideLoad } = useStartStore();
    const userStr: any = localStorage.getItem('userInfo');
    const [startDate, setStartDate]: any = useState(null);
    const [endDate, setEndDate]: any = useState(null);
    const [project, setProject]: any = useState([]);
    const [timeList, setTimeList]: any = useState([]);
    const [invoice, setInvoiceList]: any = useState([]);
    const [noAccountInvoice, setNoAccountInvoice]: any = useState([]);
    const [projectList, setProjectList]: any = useState([]);
    const [expense, setExpense]: any = useState([]);
    const [userList, setUserList]: any = useState([]);
    const [companyId, setCompanyId] = useState(0);
    const [userId, setUserId] = useState(0);
    const [info, setInfo]: any = useState(JSON.parse(userStr));
    
    useEffect(() => {
        
        loadData();
    }, [])
    
    const loadData = async () => {
        showLoad()
        await getList();
        const list = [
            getTimeList(),
            getUserList(),
            getReportData()
        ]
        
        if (info.role === Role.AIC) {
            list.push(getNoAccountInvoice())
        }
        
        
        await Promise.all(list)
        
        
    }
    const getReportData = async () => {
        showLoad()
        await getExpense();
        await getTime();
        await getInvoice();
        if (info.role === Role.AIC) {
            await getProjectList();
        }
        hideLoad()
    }
    
    const getList = async () => {
        let result: any = await TeamList();
        if (result.code === 0) {
            setProject(result.data);
        } else {
            error(result.message);
        }
    }
    
    const getTime = async () => {
        let data = {
            start_time: startDate ? getMoment(startDate).format('YYYY-MM-DD') : null,
            end_time: endDate ? getMoment(endDate).format('YYYY-MM-DD') : null,
            team_id: companyId === 0 ? null : companyId,
            user_id: userId === 0 ? null : userId
        }
        
        let result: any = await timeSheet(data);
        
        if (result.code === 0) {
            setTimeList(result.data);
        } else {
            error(result.message);
        }
    }
    
    const getExpense = async () => {
        
        let data = {
            start_time: !startDate ? null : getMoment(startDate).format('YYYY-MM-DD'),
            end_time: !endDate ? null : getMoment(endDate).format('YYYY-MM-DD'),
            team_id: companyId === 0 ? null : companyId,
            user_id: userId === 0 ? null : userId
        }
        
        let result: any = await expenseList(data);
        
        if (result.code === 0) {
            setExpense(result.data);
        } else {
            error(result.message);
        }
    }
    
    const getInvoice = async () => {
        
        let data = {
            start_time: !startDate ? null : getMoment(startDate).format('YYYY-MM-DD'),
            end_time: !endDate ? null : getMoment(endDate).format('YYYY-MM-DD'),
            team_id: companyId === 0 ? null : companyId,
            user_id: userId === 0 ? null : userId
        }
        
        let result: any = await invoiceList(data);
        
        if (result.code === 0) {
            setInvoiceList(result.data);
        } else {
            error(result.message);
        }
    }
    
    const getNoAccountInvoice = async () => {
        let data = {
            start_time: !startDate ? null : getMoment(startDate).format('YYYY-MM-DD'),
            end_time: !endDate ? null : getMoment(endDate).format('YYYY-MM-DD')
        }
        
        let result: any = await invoiceNoAccountList(data);
        
        if (result.code === 0) {
            setNoAccountInvoice(result.data);
        } else {
            error(result.message);
        }
    }
    
    const getProjectList = async () => {
        
        let data = {
            start_time: !startDate ? null : getMoment(startDate).format('YYYY-MM-DD'),
            end_time: !endDate ? null : getMoment(endDate).format('YYYY-MM-DD'),
            team_id: companyId === 0 ? null : companyId,
            user_id: userId === 0 ? null : userId
        }
        
        let result: any = await getProjectListApi(data);
        
        if (result.code === 0) {
            setProjectList(result.data);
        } else {
            error(result.message);
        }
    }
    
    
    const getTimeList = async () => {
        let result: any = await TeamList();
        
        if (result.code === 0) {
            setProject(result.data)
        } else {
            error(result.message);
        }
    }
    
    const downloadFile = (url: string, data: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                
                
                showLoad();
                
                const res: any = await Axios.get(`${ BASE_URL }${ url }`, {
                    params: data,
                    withCredentials: true,
                    responseType: 'blob'
                });
                
                hideLoad();
                
                let filename = res.headers[ 'file-info' ]; //获取文件名
                
                if (!filename) {
                    return error('Server error')
                }
                
                let blob = res.data;
                const reader = new FileReader();
                reader.readAsDataURL(blob)
                reader.onload = (e: any) => {
                    const a = document.createElement('a');
                    a.download = filename;
                    a.href = e.target.result;
                    document.body.appendChild(a);
                    a.click();
                };
            } catch (e) {
                error('Server error')
                
                return;
            }
        })
    }
    
    const downLoad = async (type: 'time' | 'expense' | 'invoice' | 'project' | 'noAccountInvoice') => {
        let data = {
            start_time: startDate ? getMoment(startDate).format('YYYY-MM-DD') : null,
            end_time: endDate ? getMoment(endDate).format('YYYY-MM-DD') : null,
            company_id: companyId === 0 ? null : companyId,
            user_id: userId === 0 ? null : userId,
            export_file: 1
        }
        switch (type) {
            case 'expense':
                downloadFile('/api/v1/report/expense/list', data);
                break;
            case 'time':
                downloadFile('/api/v1/report/time/sheet/list', data);
                break;
            case 'invoice':
                downloadFile('/api/v1/report/invoice/list', data);
                break;
            case 'noAccountInvoice':
                downloadFile('/api/v1/report/no/account/invoice/list', data);
                break;
            case 'project':
                downloadFile('/api/v1/report/project/list', data);
                break;
        }
        
    }
    
    
    const reset = () => {
        setStartDate(null);
        setEndDate(null);
        setCompanyId(0);
        setUserId(0);
        getUserList();
    }
    
    const getTeamUser = async (id: any) => {
        setCompanyId(id);
        let result: any = await selectEmployee(id ? id : '');
        
        if (result.code === 0) {
            setUserList(result.data.data);
            
        } else {
            error(result.message);
        }
    }
    
    const getUserList = async () => {
        if ([Role.STAFF, Role.CONTRACTOR].includes(Number(info.role))) {
            return;
        }
        let result: any = await selectEmployee();
        if (result.code === 0) {
            setUserList(result.data.data);
            
        } else {
            error(result.message);
        }
    }
    
    
    return (
        <div className="time-expense-view">
            <div className="navigtion-title">Time And Expense</div>
            <Divider style={ { margin: '0 20px 20px 20px' } } />
            <div className="time-expense-filter">
                <div style={ { marginRight: '0' } } className="time-expense-filter-left">
                    <div className="time-expense-filter-box">
                        <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                            <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'space-between' } }>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy-MM-dd"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Start Date"
                                    value={ startDate }
                                    onChange={ setStartDate }
                                    KeyboardButtonProps={ {
                                        'aria-label': 'change date'
                                    } }
                                />
                                <div style={ { width: '50px' } } />
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy-MM-dd"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="End Date"
                                    value={ endDate }
                                    onChange={ setEndDate }
                                    KeyboardButtonProps={ {
                                        'aria-label': 'change date'
                                    } }
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                {
                    info.role === Role.AIC && (
                        <div style={ { marginLeft: '50px' } } className="time-expense-filter-right">
                            <div className="time-expense-filter-box">
                                <FormControl style={ { width: '160px' } }>
                                    <InputLabel id="demo-simple-select-label">Team</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={ companyId }
                                        onChange={ (e: any) => getTeamUser(e.target.value) }
                                    >
                                        <MenuItem key={ -1 } value={ 0 }>All</MenuItem>
                                        {
                                            project.map((item: any) => {
                                                return <MenuItem key={ item.id }
                                                                 value={ item.id }>{ item.name }</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl style={ { width: '160px' } }>
                                    <InputLabel id="demo-simple-select-label">User</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={ userId }
                                        onChange={ (e: any) => setUserId(e.target.value) }
                                    >
                                        <MenuItem key={ -1 } value={ 0 }>All</MenuItem>
                                        { userList.map((item: any) => {
                                            return <MenuItem key={ item.user_id }
                                                             value={ item.user_id }>{ item.name }</MenuItem>
                                        })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    )
                }
                {
                    info.role === Role.TEAM_LEADER && (
                        <div style={ { marginLeft: '50px' } } className="time-expense-filter-right">
                            <div className="time-expense-filter-box">
                                <FormControl style={ { width: '160px' } }>
                                    <InputLabel id="demo-simple-select-label">Team</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={ companyId }
                                        onChange={ (e: any) => getTeamUser(e.target.value) }
                                    >
                                        {
                                            project.map((item: any) => {
                                                return <MenuItem key={ item.id }
                                                                 value={ item.id }>{ item.name }</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl style={ { width: '160px' } }>
                                    <InputLabel id="demo-simple-select-label">User</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={ userId }
                                        onChange={ (e: any) => setUserId(e.target.value) }
                                    >
                                        { userList.map((item: any) => {
                                            return <MenuItem key={ item.id } value={ item.id }>{ item.name }</MenuItem>
                                        })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    )
                }
                
                <div style={ { marginLeft: '20px' } }>
                    <Button variant="contained" color="primary" onClick={ () => {
                        getReportData();
                    } }>search</Button>
                    <Button onClick={ reset } style={ { marginLeft: '20px' } } variant="contained">Reset</Button>
                </div>
            </div>
            <div className="list">
                <TimSheetReport list={ timeList } downLoad={ () => downLoad('time') } />
                <EmployeeExpenseReport list={ expense } downLoad={ () => downLoad('expense') } />
                <InvoiceReport list={ invoice } downLoad={ () => downLoad('invoice') } />
                
                { info.role === Role.AIC && (
                    <ProjectReport list={ projectList } downLoad={ () => downLoad('project') } />) }
                { info.role === Role.AIC && (<NoAccountInvoiceReport list={ noAccountInvoice }
                                                                     downLoad={ () => downLoad('noAccountInvoice') } />) }
            </div>
        
        </div>
    )
}

export default observer(TimeAndExpense);
