import React, { useEffect, useRef, useState } from 'react';
import { uploadImg } from '../../pages/setup/editTeamMember/api';
import { Button, CircularProgress } from '@material-ui/core';
import './index.css';
import { observer } from 'mobx-react';
import { useStartStore } from '../../store';

const Updata = ({ onChange, lists, disabled, isShow }: any) => {
    
    
    const { success, error, warning, showLoad, hideLoad } = useStartStore();
    
    let [ list, setList ] = useState([]);
    let [ uploadLoading, setUploadLoading ] = useState(false);
    let fileRef = useRef(null);
    
    useEffect(() => {
        setList(lists);
        
    }, [ lists ]);
    
    const upFile = async (event: any) => {
        if (!event.target.files[ 0 ]) return;
        
        if (event.target.files[ 0 ].size > 3145728) {
            if (fileRef) {
                // @ts-ignore
                fileRef.current.type = 'text';
                // @ts-ignore
                fileRef.current.type = 'file';
            }
            return warning('The file size exceeds the limit, the maximum is 3M');
        }
        
        let bf_img: any = [...list,{ name: event.target.files[ 0 ].name,loading:true } ];
        setList(bf_img);
        
        let data: any = await uploadImg(event.target.files[ 0 ]);
        
        if (data.code === 0) {
            let img: any = [ ...list,{ path: data.data.path, name: data.data.name,loading:false } ];
    
            setList(img);
            onChange(img);
        } else {
            if (fileRef) {
                // @ts-ignore
                fileRef.current.type = 'text';
                // @ts-ignore
                fileRef.current.type = 'file';
            }
            
            const _list = [...list];
            _list.pop();
            setList(_list);
            error(data.message);
        }
    };
    
    const delFlie = (index: number) => {
        let arr = [ ...list ];
        arr.splice(index, 1);
        setList(arr);
        onChange(arr);
    };
    
    return (
        <div style={ { flex: 1 } }>
            { !isShow ? (
                <Button className='update-flie' variant="outlined" style={ { display: disabled ? 'none' : 'block' } }>
                    Browse File
                    <input ref={ fileRef } type={ 'file' } className='update-flie-box' onChange={ upFile }/>
                </Button>
            ) : null }
            
            {
                disabled ? list.map((item: any, index: number) => {
                    return <div className='update-list' key={ index }>
                        <a target="_blank" className='update-list-left' href={ item.path }>
                            { item.name }
                        </a>
                    </div>;
                }) : list.map((item: any, index: number) => {
                    return <div className='update-list' key={ index }>
                        <div className='update-list-left'>
                            {
                                item.loading && <div style={{display:'inline-block'}}>
                                    <CircularProgress size={ 16 } style={ { marginRight: '10px' } }/>
                                </div>
                            }
                            { item.path ? (
                                <a target="_blank" href={ item.path }>
                                    { item.name }
                                </a>
                            ) : item.name }
                        
                        </div>
                        {
                            !disabled &&
                            <Button onClick={ () => delFlie(index) } size={ 'small' } color="secondary">delete</Button>
                        }
                    
                    </div>;
                })
            }
        </div>
    );
};

export default observer(Updata);
