import { createContext, useContext } from 'react';
import { STORE_START, startStore } from './control';

function createStores() {
    return {
        [STORE_START]: new startStore(),
    };
}

const stores = createStores();

export const StoresContext = createContext(stores);

// hooks 使用笔记看这里 -> https://github.com/olivewind/blog/issues/1
const useStores = () => useContext(StoresContext);

function useStartStore() {
    const { startStore }:any = useStores();
    return startStore;
}

export {
    stores,
    useStartStore
};
