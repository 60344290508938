import React, { useEffect, useState } from "react";
import {
    TableContainer,
    Table,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    InputLabel,
    Button,
    CircularProgress, TextField, FormControl, MenuItem, Select
} from "@material-ui/core"
import "./index.less"
import AddIcon from '@material-ui/icons/Add';
// @ts-ignore
import { DeleteTeam, uploadImg } from "../editTeamMember/api";
import { getProject, upProject, selectTeamLeaderEmployee } from "./api";
import { useForm, Controller } from "react-hook-form";
import { useStartStore } from "../../../store";
import { observer } from "mobx-react";
import { useStyles } from "../../../utils/thmem";
import Updata from "../../../componetns/update";
import { getMoment } from '../../../utils/tool';

interface ImgList {
    path: string;
    name: string;
}

interface IProjectList {
    company_id: number;
    files: Array<ImgList>;
    gmt_create: string;
    gmt_modified: string | null
    id: number
    manager: string;
    name: string;
    start_date: string;
    total_budget: string;
    status: number;
    user_name: number;
}

const EditProject = () => {

    const {handleSubmit, errors, reset, register, setValue} = useForm();
    const {success, error, showLoad, hideLoad} = useStartStore();

    const classes = useStyles();

    const [edit, setEdit] = useState(false);
    const [addInfo, setAddInfo] = useState(false);
    const [modify, setModify] = useState(false);
    const [uploadList, setUploadList]: any = useState([]);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [imgArr, setImgArr]: any = useState([]);
    const [projectList, setProjectList]: any = useState([]);
    const [isAdd, setIsAdd] = useState(false);
    const [info, setInfo]: any = useState({name: '', manager: ''});
    const [leaders, setLeaders]: any = useState([]);
    const [open, setOpen]: any = useState(false);
    const [deleteItem, setDeleteItem]: any = useState(false);

    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {
        await getProjectList();
        getTeamLeader();
    }

    const getTeamLeader = async () => {
        const result: any = await selectTeamLeaderEmployee();

        if (result.code === 0) {
            if (result.data.data.length <= 0 && projectList.length > 0) {
                error('Sorry, you have not set a team leader');
                return
            }
            setLeaders(result.data.data);
        } else {
            error('Failed to acquire team leader, please try again later');
        }
    }
    const getProjectList = async () => {
        showLoad();
        let result: any = await getProject();
        if (result.code === 0) {
            console.log(result.data)
            setProjectList(result.data);
        } else {
            error(result.message);
        }
        hideLoad();
    }

    const drop = async (list: any) => {
        setUploadList(list);
    }

    const add = () => {
        setIsAdd(true);
        setEdit(true);
        setModify(false);
        setAddInfo(true);
        setInfo({});
        setImgArr([]);
    }

    const setOpenVisible = (state: boolean, item?: any) => {
        setOpen(state);

        if (state) {
            setDeleteItem(item);
        } else {
            setDeleteItem({});
        }
    }
    const submit = async (e: any) => {
        e.id = isAdd ? null : info.id;
        e.files = imgArr;

        if (!info.manager) {
            return error('Please select project manager');
        }

        if (!e.total_budget) {
            errors.total_budget = {
                message: 'Please input in your total budget'
            }

            error('Please input in your total budget');
            return
        }

        e['manager'] = info.manager + '';

        let result: any = await upProject(e);
        if (result.code === 0) {
            success(result.message);
            setImgArr([]);
            setUploadList([]);
            setEdit(false);
            reset();
            setModify(false);
            setAddInfo(false);
            getProjectList();
        } else {
            error(result.message);
        }
    }

    const editInfo = (data: IProjectList) => {
        setIsAdd(false);
        setModify(false);
        setEdit(true);
        setAddInfo(false);
        setUploadList(data.files || []);
        setInfo(data);

        setTimeout(() => {
            setModify(true);
        }, 0)
    }

    const deleteInfo = async () => {
        showLoad();
        let result: any = await DeleteTeam({cate: 'project', id: deleteItem.id});
        hideLoad();
        if (result.code === 0) {
            setDeleteItem({});
            setOpenVisible(false);
            success(result.message);
            getProjectList();
        } else {
            error(result.message);
        }
    }

    const delImg = (index: number) => {
        let list = [...uploadList];
        list.splice(index, 1);
        setUploadList(list);
    }

    return (
        <div className='edit-project'>
            <div className='edit-project-add'>
                <Button variant="contained" color="primary" onClick={add}>Add Project</Button>
            </div>
            <div className='edit-project-box'>
                <div className='edit-project-left' style={{borderRight: edit ? '1px solid #ededed' : 'none'}}>
                    <TableContainer component={Paper}>
                        <Table size={"small"} style={{background: 'none'}}>
                            <TableHead style={{background: 'none'}}>
                                <TableRow>
                                    <TableCell align="left">Project Name</TableCell>
                                    <TableCell align="left">Manager</TableCell>
                                    <TableCell align="left">Total Budget</TableCell>
                                    <TableCell align="left">Start Date</TableCell>
                                    <TableCell align="left">Create Time</TableCell>
                                    <TableCell align="left">Operation</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{background: 'none'}}>
                                {projectList.map((item: IProjectList) => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" align="left" scope="row">{item.name}</TableCell>
                                        <TableCell component="th" align="left" scope="row">{item.user_name}</TableCell>
                                        <TableCell component="th"
                                                   align="left"
                                                   scope="row">{item.total_budget}</TableCell>
                                        <TableCell component="th"
                                                   align="left"
                                                   scope="row">{getMoment(item.start_date).format('YYYY-MM-DD')}</TableCell>
                                        <TableCell component="th"
                                                   align="left"
                                                   scope="row">{getMoment(item.gmt_create).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                        <TableCell className='operating' align="left">
                                            <Button onClick={() => editInfo(item)} color="primary">Edit</Button>
                                            <Button onClick={() => setOpenVisible(true, item)}
                                                    color="secondary">Delete</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {projectList.length === 0 &&
                    <div style={{flex: 1, textAlign: 'center', marginTop: '20px'}}>No data</div>}
                </div>

                <div className='edit-project-right' style={{width: edit ? '40%' : 0}}>

                    {
                        modify ? (
                            <form onSubmit={handleSubmit(submit)} className='edit-project-form'>
                                <TextField inputRef={register({
                                    required: {value: true, message: 'Please type in your name'},
                                })}
                                           error={Boolean(errors.name)}
                                           variant="outlined"
                                           margin="normal"
                                           fullWidth
                                           id="name"
                                           defaultValue={info.name}
                                           size={"small"}
                                           label="Name"
                                           name="name"
                                           helperText={
                                               errors.name && errors.name.message
                                           }
                                />
                                <TextField inputRef={register({
                                    required: {value: true, message: 'Please input in your total budget'},
                                })}
                                           error={Boolean(errors.total_budget)}
                                           variant="outlined"
                                           margin="normal"
                                           fullWidth
                                           id="TotalBudget"
                                           defaultValue={info.total_budget}
                                           size={"small"}
                                           label="Total Budget"
                                           name="total_budget"
                                           helperText={
                                               errors.total_budget && errors.total_budget.message
                                           }
                                />

                                <FormControl size={"small"} variant="outlined" className={classes.formControl}>
                                    <InputLabel id="select-outlined-label">Manager</InputLabel>
                                    <Select
                                        error={Boolean(errors.manager)}
                                        labelId="select-outlined-label"
                                        id="demo-simple-select-outlined2"
                                        value={info.manager}
                                        onChange={e => setInfo({...info, manager: e.target.value})}
                                        label="Manager"
                                        inputRef={register({
                                            required: {value: true, message: 'Please select your manager'}
                                        })}

                                    >
                                        {
                                            leaders.map((item: any) => {
                                                return <MenuItem key={item.user_id}
                                                                 value={item.user_id}>{item.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <TextField inputRef={register({
                                    required: {value: true, message: 'Please type in your name'},
                                })}
                                           error={Boolean(errors.start_date)}
                                           variant="outlined"
                                           margin="normal"
                                           fullWidth
                                           size={"small"}
                                           id="name"
                                           type="date"
                                           defaultValue={getMoment(info.start_date ? info.start_date : new Date()).format('YYYY-MM-DD')}
                                           label="Start Date"
                                           name="start_date"
                                           helperText={
                                               errors.start_date && errors.start_date.message
                                           }
                                />

                                <div className='upload-box' style={{width: '500px', marginTop: 10, marginBottom: 25}}>
                                    <div style={{marginRight: '10px', lineHeight: '40px', width: 150}}>Project File:
                                    </div>
                                    <Updata onChange={setUploadList} lists={uploadList}/>
                                </div>

                                <div className='edit-project-submit'>
                                    <Button variant="contained"
                                            style={{marginRight: '10px'}}
                                            onClick={() => setEdit(false)}>cancel</Button>
                                    <Button type={"submit"} variant="contained" color="primary">Svae</Button>
                                </div>
                            </form>
                        ) : null
                    }
                    {
                        addInfo ? (
                            <form onSubmit={handleSubmit(submit)} className='edit-project-form'>
                                <TextField inputRef={register({
                                    required: {value: true, message: 'Please type in your name'},
                                })}
                                           error={Boolean(errors.name)}
                                           variant="outlined"
                                           margin="normal"
                                           fullWidth
                                           id="name"
                                           defaultValue={info.name}
                                           size={"small"}
                                           label="Name"
                                           name="name"
                                           helperText={
                                               errors.name && errors.name.message
                                           }
                                />
                                <TextField inputRef={register({
                                    required: {value: true, message: 'Please input in your total budget'},
                                })}
                                           error={Boolean(errors.total_budget)}
                                           variant="outlined"
                                           margin="normal"
                                           fullWidth
                                           id="TotalBudget"
                                           defaultValue={info.total_budget}
                                           size={"small"}
                                           label="Total Budget"
                                           name="total_budget"
                                           helperText={
                                               errors.total_budget && errors.total_budget.message
                                           }
                                />

                                <FormControl size={"small"} variant="outlined" className={classes.formControl}>
                                    <InputLabel id="select-outlined-label-add">Manager</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={info.manager}
                                        onChange={e => setInfo({...info, manager: e.target.value})}
                                        label="Manager"
                                    >
                                        {
                                            leaders.map((item: any) => {
                                                return <MenuItem key={item.user_id}
                                                                 value={item.user_id}>{item.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <TextField inputRef={register({
                                    required: {value: true, message: 'Please type in your name'},
                                })}
                                           error={Boolean(errors.start_date)}
                                           variant="outlined"
                                           margin="normal"
                                           fullWidth
                                           size={"small"}
                                           id="name"
                                           type="date"
                                           defaultValue={getMoment(info.start_date ? info.start_date : new Date()).format('YYYY-MM-DD')}
                                           label="Start Date"
                                           name="start_date"
                                           helperText={
                                               errors.start_date && errors.start_date.message
                                           }
                                />

                                <div className='upload-box' style={{width: '500px', marginTop: 10, marginBottom: 25}}>
                                    <div style={{marginRight: '10px', lineHeight: '40px', width: 150}}>Project File:
                                    </div>
                                    <Updata onChange={setImgArr} lists={imgArr}/>
                                </div>

                                <div className='edit-project-submit'>
                                    <Button variant="contained"
                                            style={{marginRight: '10px'}}
                                            onClick={() => setEdit(false)}>cancel</Button>
                                    <Button type={"submit"} variant="contained" color="primary">Add</Button>
                                </div>
                            </form>
                        ) : null
                    }

                </div>
            </div>

            <Dialog
                open={open}
                onClose={() => setOpenVisible(false)}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
                    Confirm Delete
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the record named {deleteItem.name}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setOpenVisible(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={deleteInfo} color="secondary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default observer(EditProject);
