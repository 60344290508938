import React, {useState} from "react";
import {TextField, Button, Slide, Snackbar} from "@material-ui/core"
import "./index.less"
import {useForm} from "react-hook-form";
import {resendEmail} from "./api";
import IProps from "../../interface/iprops";
import {useStartStore} from "../../store";

let timer: NodeJS.Timeout | null = null;

const Forget = ({history}:IProps)=>{

    const {success,error,showLoad,hideLoad} = useStartStore();

    const { register, handleSubmit, errors } = useForm();
    const [alertShow,setAlertShow] = useState(false);
    const [disable,setDisable] = useState(false);
    const [countdown,setCountdown] = useState(60)

    const onSubmit = async (e: any)=>{
        e.type = 2;
        showLoad();
        let data:any = await resendEmail(e);
        hideLoad();
        if (data.code === 0){
            success(data.message);
            setDisable(true);
            let itemrs = 60;
            timer = setInterval(()=>{
                itemrs -= 1;
                setCountdown(itemrs);
                if (itemrs <= 0){
                    // @ts-ignore
                    clearInterval(timer);
                    setDisable(false);
                }
            },1000)
        }else{
            error(data.message);
        }
    }

    const handleClose = ()=>{
        setAlertShow(false);
        history.push('/')
    }

    return (
        <div className='forget'>
            <div className='forget-box'>
                <div className='forget-logo'><img style={{width:100,height:100}} src={require('../../assets/images/logo.png')} alt='logo'/></div>
                <div>Reset Password Entering Your Email Address</div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField inputRef={register({
                        required: {value: true,message:'please input your email'},
                        pattern:{
                            value:/^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/,
                            message:'please enter your vaild email'
                        }
                    })}
                               error={Boolean(errors.email)}
                               variant="outlined"
                               margin="normal"
                               fullWidth
                               id="email"
                               label="Email"
                               name="email"
                               helperText={
                                   errors.email && errors.email.message
                               }
                               />
                    <Button style={{marginTop:'20px'}} disabled={disable} fullWidth={true} size={"large"} type={"submit"} variant="contained" color="primary">Reset {countdown !== 60 && countdown !== 0  && countdown}</Button>
                    <Button style={{marginTop:'20px'}} fullWidth={true} size={"large"} variant="contained" onClick={()=>history.goBack()}>back</Button>
                </form>
            </div>
        </div>
    )
}

export default Forget;
