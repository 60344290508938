import React, { useEffect, useState } from 'react';
import {
    TextField,
    Button,
    Card,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import './index.less'
import { useForm } from 'react-hook-form';
import IProps from '../../interface/iprops';
import { observer } from 'mobx-react'
import { useStartStore } from '../../store/index'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Updata from '../../componetns/update';
import { getProject } from '../setup/editProject/api';
import { getMoment } from '../../utils/tool';
import { fetchSubmit } from './api'


const NoAccountSubmit = ({ history, setAlert }: IProps) => {
    const { success, error, warning, showLoad, hideLoad } = useStartStore();
    const { handleSubmit, errors, register } = useForm();
    const [info, setInfo]: any = useState({
        month: '',
        invoice_number: '',
        nature_of_services_provided: '',
        total_days_in_the_invoice: '',
        total_amount_submitted: ''
    });
    const [projects, setProjects] = useState([]);
    
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [totalSubmitted, settotalSubmitted] = useState('');
    const [natureProvided, setNatureProvided] = useState('');
    const [date, setDate] = useState(null)
    const [imgs, setImgs] = useState([])
    const [currentProject, setCurrentProject] = useState('')
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [companyCode, setCompanyCode] = useState('')
    
    const getProjectFn = async (code: any) => {
        if (!code) return;
        
        let result: any = await getProject(code, '1');
        
        if (result.code === 0) {
            setProjects(result.data);
        } else {
            error(result.message);
        }
    }
    
    const submit = async (e: any) => {
        if (!currentProject) {
            return warning('Please select your project')
        }
        const data = {
            ...e,
            address,
            project_id: +currentProject,
            month: getMoment(date).format('YYYY-MM'),
            attach_your_detail_files: imgs,
            invoice_number: invoiceNumber.split(','),
            total_amount_submitted: Number(e.total_amount_submitted)
        };
        showLoad()
        const result: any = await fetchSubmit(data);
        hideLoad()
        if (result.code === 0) {
            success('Submitted successfully，Return to home page after 3 seconds');
            setTimeout(() => {
                window.location.href = '/'
            }, 3000)
        } else {
            error(result.message);
        }
    }
    return (
        <div className="m-no-account-submit-container">
            <div className="login-bg">
                <img src={ require('../../assets/images/login_bg.jpg') } alt={ 'bg' } />
            </div>
            
            <Card className="table" style={ { marginTop: '20px' } }>
                <div className="main-title">Submit Without Account</div>
                <div className="table-title">
                    <div style={ { display: 'flex' } }>Please enter relevant information to submit</div>
                </div>
                <Divider />
                <div className="m-form-wrapper">
                    <form onSubmit={ handleSubmit(submit) } className="edit-project-form">
                        <FormControl size={ 'small' } variant="outlined" className="m-form-controller">
                            <div className="error-alert">
                                Please fill in the company code first!
                            </div>
                            <TextField inputRef={ register({
                                required: { value: true, message: 'Please input in your company code' }
                            }) }
                                       error={ Boolean(errors.companyCode) }
                                       variant="outlined"
                                       margin="normal"
                                       fullWidth
                                       size={ 'small' }
                                       id="companyCode"
                                       style={ { width: 670 } }
                                       value={ companyCode }
                                       onChange={ (e) => setCompanyCode(e.target.value) }
                                       onBlur={ (e) => getProjectFn(e.target.value) }
                                       label="Company Code"
                                       name="companyCode"
                                       helperText={
                                           errors.companyCode && errors.companyCode.message
                                       }
                            />
                            <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                                <div style={ {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                } }>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        style={ { width: 300 } }
                                        variant="inline"
                                        format="MMMM, yyyy"
                                        margin="normal"
                                        label="Month"
                                        name="month"
                                        value={ date }
                                        onChange={ (e: any) => {
                                            setDate(e)
                                        } }
                                        KeyboardButtonProps={ {
                                            'aria-label': 'change date'
                                        } }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                            <FormControl
                                size={ 'small' }
                                variant="outlined"
                                fullWidth={ false }
                                style={ { width: 320, margin: '24px 0 0 50px' } }
                            >
                                <InputLabel id="select-outlined-label-add">Project</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={ currentProject }
                                    onChange={ e => setCurrentProject(e.target.value + '') }
                                    label="Project"
                                
                                >
                                    { projects.map((item: any) => {
                                        return <MenuItem key={ item.id }
                                                         value={ item.id }>{ item.name }</MenuItem>
                                    }) }
                                </Select>
                            </FormControl>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size={ 'small' }
                                id="name"
                                label="Invoice Number"
                                style={ { width: 320 } }
                                value={ invoiceNumber }
                                onChange={ (e) => setInvoiceNumber(e.target.value) }
                                name="invoice_number"
                            />
                            <div style={ { margin: 24, color: '#db0e4f' } }>Multiple bills are separated by
                                commas (,)
                            </div>
                            <TextField inputRef={ register({
                                required: { value: true, message: 'Please input in your total amount submitted' }
                            }) }
                                       error={ Boolean(errors.total_amount_submitted) }
                                       variant="outlined"
                                       margin="normal"
                                       fullWidth
                                       style={ { width: 320 } }
                                       size={ 'small' }
                                       id="name"
                                       value={ totalSubmitted }
                                       onChange={ (e) => settotalSubmitted(e.target.value) }
                                       label="Total Amount Submitted"
                                       name="total_amount_submitted"
                                       helperText={
                                           errors.total_amount_submitted && errors.total_amount_submitted.message
                                       }
                            />
                            
                            <TextField inputRef={ register({
                                required: { value: true, message: 'Please input in your nature of services provided' }
                            }) }
                                       error={ Boolean(errors.nature_of_services_provided) }
                                       variant="outlined"
                                       margin="normal"
                                       fullWidth
                                       style={ { width: 320, marginLeft: 30 } }
                                       size={ 'small' }
                                       id="nature_of_services_provided"
                                       value={ natureProvided }
                                       onChange={ (e) => setNatureProvided(e.target.value) }
                                       label="Nature of Services Provided"
                                       name="nature_of_services_provided"
                                       helperText={
                                           errors.nature_of_services_provided && errors.nature_of_services_provided.message
                                       }
                            />
                            
                            <TextField inputRef={ register({
                                required: { value: true, message: 'Please input in your name' }
                            }) }
                                       error={ Boolean(errors.name) }
                                       variant="outlined"
                                       margin="normal"
                                       fullWidth
                                       size={ 'small' }
                                       id="name"
                                       value={ name }
                                       style={ { width: 320 } }
                                       onChange={ (e) => setName(e.target.value) }
                                       label="Name"
                                       name="name"
                                       helperText={
                                           errors.name && errors.name.message
                                       }
                            />
                            
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size={ 'small' }
                                id="address"
                                style={ { width: 320, marginLeft: 30 } }
                                value={ address }
                                onChange={ (e) => setAddress(e.target.value) }
                                label="Email Address"
                                name="address"
                            />
                        
                        </FormControl>
                        
                        <div className="m-select-file">
                            <span className="label">Attach Your  Detail：</span>
                            <Updata lists={ imgs } onChange={ (e: any) => {
                                console.log(e)
                                setImgs(e);
                            } } />
                        </div>
                        <div className="invoice-submit">
                            <Button style={ { marginRight: 20 } }
                                    color="secondary"
                                    onClick={ () => window.location.href = '/' }>Back</Button>
                            <Button type={ 'submit' } variant="contained" color="primary">submit</Button>
                        </div>
                    </form>
                </div>
            </Card>
        </div>
    )
}

export default observer(NoAccountSubmit);
