import Http from "../../../utils/axios"

interface IProps {
    id?: number,
    name?: string,
    desc?: string
}

export const addExpense = (parameter: IProps) => {

    let data:any = {
        name: parameter.name,
        desc: parameter.desc
    }

    if (parameter.id) {
        data['id'] = parameter.id;
    }

    return Http.post('/api/v1/setup/expense/update', data);
}

export const getExpense = () => {
    return Http.get('/api/v1/setup/expense/select');
}
