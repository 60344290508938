import React, { useEffect, useState } from "react";
import "./index.less"

const Welcome = ()=>{
    let userInfo = localStorage.getItem('userInfo')
    let [info, setInfo] = useState<any>({
        company_info: {},
    });

    useEffect(() => {
        if (typeof userInfo === "string") {
            setInfo(JSON.parse(userInfo))
        }
    }, [])

    return (
        <div className='welcome-view'>
            <div>
                <img src={require('./welcome.png')}/>
                <p style={{textAlign:"center"}}>Welcome to RMS for <span style={{fontWeight:'bold'}}>{info.company_info.name || ''}</span>. You can now set up projects and expenses for your company, and Invite your team members to join</p>
            </div>
        </div>
    )
}

export default Welcome
