import Http from "../../utils/axios";

interface IProps {
    id:number;
    type: number,
    status: number,
    return_message?: string
}

export const AuditRecord = (parameter:IProps)=>{
    let data = {
        id: parameter.id,
        type: parameter.type,
        status: parameter.status,
        return_message: parameter.return_message
    }
    return Http.post('/api/v1/tasks/record/approval',data)
}

export const GetUnfinished = ()=>{
    return Http.get('/api/v1/tasks/record/unfinished')
}



