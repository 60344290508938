import { action, observable } from 'mobx';

export interface ITodo {
    id: number;
    name: string;
    desc: string;
    done?: boolean;
}


export class startStore {
    @observable open: boolean = false;
    @observable messages: string = '';
    @observable type: 'error' | 'warning' | 'info' | 'success' = 'success';

    @observable load:boolean = false

    @action.bound success = (message:string)=>{
        this.open = true
        this.messages = message;
        this.type = "success"
    }

    @action.bound error(message:string){

        this.open = true
        this.messages = message;
        this.type = "error"
    }

    @action.bound warning(message:string){
        this.open = true
        this.messages = message;
        this.type = "warning"
    }

    @action.bound info(message:string){
        this.open = true
        this.messages = message;
        this.type = "info"
    }

    @action.bound close(){
        this.open = false;
    }

    @action.bound showLoad(){
        this.load = true;
    }

    @action.bound hideLoad(){
        this.load = false;
    }

}

export const STORE_START = 'startStore';

