import React from "react";
import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";

interface IProps {
    errorName: string;
    value?: any;
    onChange?: any;
    type?: 'text' | 'passwrod' | 'date';
    label?: string;
    name?: string;
    size?: 'small' | 'medium';
    defaultValue?: string;
    registerObj?: any
}


const Input = ({errorName, value, onChange, type, label, name, defaultValue, size, registerObj}: IProps) => {
    const {register, errors} = useForm();

    const attr:any = {
        variant: "outlined",
        margin: "normal",
        value: value,
        size: size,
        defaultValue: defaultValue,
        onChange: onChange,
        id: name,
        type: type,
        label: label,
        name: name,
        helperText: errors[errorName] ? errors[errorName].message : '',
        autoFocus: true,
        fullWidth: true,
        error: Boolean(errors[errorName])
    };

    if (registerObj) {
        attr['inputRef'] = register(registerObj);
    }

    return (
        // @ts-ignore
        <TextField {...attr}/>
    )
}

export default Input
