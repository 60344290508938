import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom"
import RouterConfig from "./config"
import Message from "../componetns/message";
import Loading from "../componetns/loading";
import { useStartStore } from "../store";
import { observer } from "mobx-react";
import { getUserInfo } from '../pages/activtion/api';

const noAuth = ['/login', '/forget', '/signup', '/reset', '/activtion','/not/account/submit'];

const RouteWithSubRoutes = (route: any) => {
    return  (
        <Route
            path={route.path}
            exact={route.exact}
            // component={route.component}
            render={(props: any) => {
                const psn = props.location.pathname;
                if (psn === '/') {
                    return props.history.replace('/home/overview');
                }

                if (!noAuth.includes(psn)) {
                    const user = localStorage.getItem('userInfo');

                    if (!user || user.length <= 0) {
                        return props.history.replace('/login');
                    }
                }
                return (
                    <route.component {...props} routes={route.routes}/>
                )
            }
            }
        />
    )
}


const Routers = () => {

    const {messages, open, type, close} = useStartStore();
    const [inited, setInited] = useState(false);
    const {showLoad, hideLoad, error} = useStartStore();
    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        if (!inited) {
            if(noAuth.includes(window.location.pathname)){
                return setInited(true);
            }

            showLoad();

            let result: any = await getUserInfo();

            hideLoad();
            if (result.code === 0) {
                localStorage.setItem('userInfo', JSON.stringify(result.data));
                setInited(true);
            } else {
                error(result.message);
                window.location.replace('/login');
            }

        }
    }


    return inited ?(
        <Router>
            <Loading/>
            <Switch>
                {
                    RouterConfig.map((route: any, index) => (<RouteWithSubRoutes key={index} {...route}/>))
                }
            </Switch>
            {open && <Message/>}

        </Router>
    ):null
}


export default observer(Routers);
