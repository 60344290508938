import React, { useEffect, useState } from 'react';
import './index.less';
import {
    Typography,
    Table,
    TableHead,
    TextField,
    InputLabel,
    TableRow,
    Select,
    Button,
    FormControl,
    TableCell,
    TableBody,
    Divider,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { historyInvite, invite } from './api';
import { EAccountStatus, Role } from '../../../enum/userType';
import { useStartStore } from '../../../store';
import { resendEmail,autoActiveEmail } from '../../forget/api';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            width: '100%',
            marginTop: 14,
        },
        selectEmpty: {
            marginTop: theme.spacing(20),
        },
    }),
);

const IndivdualInvite = () => {
    
    const { register, handleSubmit, errors } = useForm();
    const { success, error, warning, showLoad, hideLoad } = useStartStore();
    const [ total, setTotal ] = useState(0);
    const [ list, setList ] = useState([]);
    
    const classes = useStyles();
    
    useEffect(() => {
        loadData();
    }, []);
    
    const loadData = async () => {
        showLoad();
        let data: any = await historyInvite();
        if (data.code === 0) {
            setTotal(data.data.length);
            setList(data.data);
        } else {
            warning(data.message);
        }
        
        hideLoad();
    };
    
    const onSubmit = async (e: any) => {
        showLoad();
        let data: any = await invite(e);
        if (data.code === 0) {
            success(data.message);
            loadData();
        } else {
            error(data.message);
        }
        hideLoad();
    };
    const onResendEmail = async (row: any) => {
        showLoad();
        const result: any = await resendEmail({ email: row.email, type: 3 });
        hideLoad();
        
        if (result.code === 0) {
            return success(result.message);
        }
        error(result.message);
    };
    
    const onActivationEmail = async (row:any) => {
        showLoad();
        const result: any = await autoActiveEmail(row.user_id);
        hideLoad();
    
        if (result.code === 0) {
            success(result.message);

            return loadData();
        }
        
        error(result.message);
        
    };
    
    return (
        <div className='invite-view'>
            <div className='invite-left'>
                <div>
                    <div className='invite-left-border'>
                        <Typography className='invite-left-border-box'>Individual Invite</Typography>
                    </div>
                    <form onSubmit={ handleSubmit(onSubmit) } className='invite-form'>
                        <div>
                            <TextField inputRef={ register({
                                required: 'Please enter the password',
                            }) }
                                       error={ Boolean(errors.name) }
                                       variant="outlined"
                                       margin="normal"
                                       fullWidth
                                       id="name"
                                       size={ 'small' }
                                       label="name"
                                       name="name"
                                       helperText={
                                           errors.name && errors.name.message
                                       }
                            />
                        </div>
                        <div>
                            <TextField inputRef={ register({
                                required: 'Please enter the password',
                            }) }
                                       error={ Boolean(errors.email) }
                                       variant="outlined"
                                       margin="normal"
                                       fullWidth
                                       size={ 'small' }
                                       id="email"
                                       label="email"
                                       name="email"
                                       helperText={
                                           errors.email && errors.email.message
                                       }
                            />
                        </div>
                        <div>
                            <TextField inputRef={ register({
                                required: 'Please enter the password',
                            }) }
                                       error={ Boolean(errors.password) }
                                       variant="outlined"
                                       margin="normal"
                                       size={ 'small' }
                                       fullWidth
                                       id="password"
                                       label="Temporary Password"
                                       name="password"
                                       helperText={
                                           errors.password && errors.password.message
                                       }
                            />
                        </div>
                        
                        <div>
                            <FormControl size={ 'small' } variant="outlined" className={ classes.formControl }>
                                <InputLabel htmlFor="outlined-age-native-simple"
                                            style={ { background: '#fafafa', paddingRight: '10px' } }>Role</InputLabel>
                                <Select
                                    native
                                    inputRef={ register() }
                                    label="Age"
                                    inputProps={ {
                                        name: 'role',
                                        id: 'outlined-age-native-simple',
                                    } }
                                >
                                    <option value={ 3 }>Employee</option>
                                    <option value={ 4 }>Contractor</option>
                                </Select>
                            </FormControl>
                        </div>
                        
                        <div className='invite-form-total'>
                            <TextField className='invite-form-total-input' inputRef={ register({
                                required: 'Please enter the password',
                            }) }
                                       error={ Boolean(errors.employee_num) }
                                       variant="outlined"
                                       margin="normal"
                                       size={ 'small' }
                                       fullWidth
                                       id="password"
                                       label="Employee ID"
                                       name="employee_num"
                                       helperText={
                                           errors.employee_num && errors.employee_num.message
                                       }
                            />
                            <div className='invite-form-total-tips'>of Total: { total }</div>
                        </div>
                        
                        <Button className='invite-form-submit'
                                fullWidth={ true }
                                size={ 'large' }
                                type={ 'submit' }
                                variant="contained"
                                color="primary">SEND</Button>
                    </form>
                </div>
            </div>
            <Divider className='invite-border' orientation="vertical" flexItem/>
            <div className='invite-right'>
                <Typography variant="h6" color={ 'primary' }>Invitation Status Log</Typography>
                <Table aria-label="simple table" style={ { width: '100%' } }>
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">Name</TableCell>
                            <TableCell align="right">Employee Id</TableCell>
                            <TableCell align="right">Role</TableCell>
                            <TableCell align="right">Email</TableCell>
                            <TableCell align="right">Status</TableCell>
                            <TableCell align="right">Operation</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { list.map((row: any) => (
                            <TableRow key={ row.id }>
                                <TableCell align="right">{ row.name }</TableCell>
                                <TableCell align="right">{ row.employee_id }</TableCell>
                                <TableCell align="right">{ Role[ row.role ] }</TableCell>
                                <TableCell align="right">{ row.email }</TableCell>
                                <TableCell align="right">{ EAccountStatus[ row.status ] }</TableCell>
                                <TableCell align="right">
                                    { row.status === EAccountStatus.Delete ? (
                                        <span style={ { color: '#8b8b8b' } }>Deleted</span>
                                    ) : (
                                        <React.Fragment>
                                            <Button disabled={ row.status === EAccountStatus.Accepted }
                                                    variant="outlined"
                                                    onClick={ () => onResendEmail(row) }
                                                    color="primary">Resend
                                                Email</Button>
                                            
                                            {row.status !== EAccountStatus.Accepted && (
                                                <Button variant="outlined"
                                                        style={{marginLeft:10}}
                                                        onClick={ () => onActivationEmail(row) }
                                                        color="primary">Automatic Activation</Button>
                                            )}
                                        </React.Fragment>
                                    ) }
                                
                                </TableCell>
                            </TableRow>
                        )) }
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

export default IndivdualInvite;
