import {createMuiTheme, createStyles, makeStyles, Theme} from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#2d7dd4',
            main: '#2d7dd4',
            dark: '#2d7dd4',
            contrastText: '#FFF',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            width: '100%',
            marginTop: 14
        },
        selectEmpty: {
            marginTop: theme.spacing(20),
        },
        table: {
            minWidth: 650,
        },
    }),
);

export default theme
