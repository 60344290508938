import React from "react";
import {Card,Button,TableContainer,Table,TableHead,TableRow,TableCell,TableBody,Paper} from "@material-ui/core";
import "./index.less"

const InvoiceReport = ({list,downLoad}:any)=>{
    const getApprovedAmount =()=>{
        for (const item of list) {
            if(item.status === 'APPROVED'){
                return item.total_amount;
            }
        }

        return ''
    }

    return (
        <Card className='employee-expense-report-view' style={{maxWidth:'550px'}}>
            <div className='title'>No Account Invoice Report</div>
            <div className='worked'>
                <div>Total Invoice Amount:</div>
                <div className='worked-box'>{getApprovedAmount()}</div>
            </div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Amount</TableCell>
                            <TableCell align="left">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            list.map((item: any, index: string | number | undefined)=>(
                                <TableRow key={index}>
                                    <TableCell align="left">{item.total_amount}</TableCell>
                                    <TableCell align="left">{item.status}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Button variant="contained" onClick={downLoad}  color="primary" className='submit'>Export to Excel</Button>
        </Card>
    )
}

export default InvoiceReport
