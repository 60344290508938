import Http from "../../utils/axios"

interface IProps {
    gmt:string;
    tokenkey:string,
    account:string;
}

export const activtionEmail = (parameter:IProps)=>{
    let data = {
        gmt:parameter.gmt,
        tokenkey:parameter.tokenkey,
        account:parameter.account
    }

    return Http.post('/api/v1/account/activation',data);
}


export const getUserInfo = async ()=>{
    return Http.get('/api/v1/account/user/get');
}
