import Http from '../../utils/axios';

interface IProps {
    email: string;
    type?: number
}

export const resendEmail = (e: IProps) => {
    let data = {
        email: e.email,
        type: e.type,
    };
    return Http.post('/api/v1/account/resend/mail', data);
};


export const autoActiveEmail = (user_id: string) => {
    let data = {
        user_id,
    };
    return Http.post('/api/v1/account/auto/active/mail', data);
};
