import React, {useEffect, useState} from "react";
import {Button, Card, RadioGroup,FormControl,FormControlLabel,Radio,Divider, TextField} from "@material-ui/core";
import {useForm} from "react-hook-form";

interface IProps {
    onSubmit?:any
}

const Payment = ({onSubmit}:IProps) => {

    let user:any = localStorage.getItem('userInfo')

    const {register, handleSubmit, errors} = useForm();
    const [radio,setRadio] = useState('1')
    const [userInfo,setUserInfo] = useState(JSON.parse(user))
    const [address,setAddress] = useState('')
    const [addressTwo,setAddressTwo] = useState('')
    const [city,setCity] = useState('')
    const [state,setState] = useState('')
    const [zip,setZip] = useState('')
    const [rateInformation,setRateInformation] = useState('')

    useEffect(()=>{

        setRadio(userInfo.detail_info.method)
        setAddress(userInfo.detail_info.address)
        setAddressTwo(userInfo.detail_info.address_two)
        setCity(userInfo.detail_info.city)
        setState(userInfo.detail_info.state)
        setZip(userInfo.detail_info.zip)
        setRateInformation(userInfo.detail_info.rate_information)
    },[])


    const submit = (e: any) => {

        let data = {
            ...e,
            method:parseInt(radio)
        }

        onSubmit(data);
    }

    return (
        <Card className='payment-view'>
            <div className='payment-title'>Payment Address</div>
            <Divider/>
            <form onSubmit={handleSubmit(submit)}>
                <div className='payment-box'>
                    <div style={{marginRight:'20px'}}>Method</div>

                    <RadioGroup aria-label="gender" value={radio} onChange={(e:any)=>setRadio(e.target.value)}  style={{display:'flex',flexDirection:'row',alignItems:'center',flex:1}}>
                        <FormControlLabel value="1" control={<Radio color={'primary'} />} label="Check" />
                        <FormControlLabel value="2" control={<Radio color={'primary'}/>} label="ACH" />
                    </RadioGroup>
                </div>

                <TextField inputRef={register()}
                           error={Boolean(errors.address)}
                           variant="outlined"
                           size={"small"}
                           margin="normal"
                           fullWidth
                           id="email"
                           value={address}
                           onChange={(e)=>setAddress(e.target.value)}
                           label="Address"
                           name="address"
                           helperText={
                               errors.address && errors.address.message
                           }
                           />

                <TextField inputRef={register()}
                           error={Boolean(errors.address_two)}
                           variant="outlined"
                           size={"small"}
                           margin="normal"
                           fullWidth
                           id="email"
                           value={addressTwo}
                           onChange={(e:any)=>setAddressTwo(e.target.value)}
                           label="Address two"
                           name="address_two"
                           helperText={
                               errors.address_two && errors.address_two.message
                           }
                           />

                <TextField inputRef={register()}
                           error={Boolean(errors.city)}
                           variant="outlined"
                           size={"small"}
                           margin="normal"
                           fullWidth
                           id="email"
                           label="City"
                           value={city}
                           onChange={(e:any)=>setCity(e.target.value)}
                           name="city"
                           helperText={
                               errors.city && errors.city.message
                           }
                           />


                <div className='date'>
                    <TextField inputRef={register()}
                               error={Boolean(errors.satte)}
                               variant="outlined"
                               size={"small"}
                               margin="normal"
                               fullWidth
                               id="email"
                               label="State"
                               value={state}
                               onChange={(e:any)=>setState(e.target.value)}
                               name="state"
                               helperText={
                                   errors.state && errors.state.message
                               }
                               />
                    <div style={{margin:'0 10px'}}>—</div>
                    <TextField inputRef={register()}
                               error={Boolean(errors.zip)}
                               variant="outlined"
                               size={"small"}
                               margin="normal"
                               fullWidth
                               id="email"
                               label="Zip"
                               value={zip}
                               onChange={(e:any)=>setZip(e.target.value)}
                               name="zip"
                               helperText={
                                   errors.zip && errors.zip.message
                               }
                               />
                </div>

                <TextField inputRef={register()}
                           error={Boolean(errors.rate_information)}
                           variant="outlined"
                           size={"small"}
                           margin="normal"
                           fullWidth
                           id="email"
                           label="Rate Information"
                           value={rateInformation}
                           onChange={(e:any)=>setRateInformation(e.target.value)}
                           name="rate_information"
                           helperText={
                               errors.rate_information && errors.rate_information.message
                           }
                           />

                <div className='submit'>
                    <Button variant="contained" type={"submit"} color="primary">Enter</Button>
                </div>
            </form>
        </Card>
    )
}

export default Payment
